import { XMarkIcon } from '@heroicons/react/24/outline';
import { Dialog } from '@material-ui/core';
import moment from 'moment-timezone';
import { formatEventName } from './formatEventName';

const TransactionDetailsModal = ({ open, onClose, transaction }) => {
	if (!transaction) return null;

	const formatCurrency = (amount) => {
		if (!amount) return '$0.00';
		return new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
		}).format(amount / 100);
	};

	const getPackageName = (transaction) => {
		const id =
			transaction?.data?.package_id ??
			transaction?.payload?.payload?.data?.object?.metadata?.package_id ??
			transaction?.payload?.payload?.data?.object?.lines?.data?.[0]?.metadata?.package_id ??
			transaction?.payload?.productId;

		if (!id) return 'Unknown';

		const strId = String(id).toLowerCase();

		if (strId === '1' || strId === 'included') return 'Included';
		if (strId === '2' || strId.includes('premium')) return 'Premium';
		if (strId === '3' || strId.includes('ultimate')) return 'Ultimate';

		return 'Unknown';
	};

	return (
		<Dialog
			classes={{
				paper: 'w-full max-w-[] m-8',
			}}
			onClose={onClose}
			open={open}
		>
			<div className="p-24">
				<div className="flex justify-between items-center mb-16">
					<h2 className="text-20 font-bold">Transaction Details</h2>
					<button className="p-8 hover:bg-grey-100 rounded-full" onClick={onClose} type="button">
						<XMarkIcon className="w-24 h-24" />
					</button>
				</div>

				<div className="flex flex-col gap-24">
					{/* Basic Transaction Info */}
					<div>
						<h3 className="text-20 font-bold mb-8">Basic Information</h3>
						<div className="grid grid-cols-2 gap-y-4">
							<div className="font-bold">Event Type</div>
							<div>{formatEventName(transaction.event, transaction.provider)}</div>
							<div className="font-bold">Process Date</div>
							<div>{moment(transaction.date).format('MM/DD/YY HH:mm:ss')}</div>
							<div className="font-bold">Platform</div>
							<div>{transaction.provider === 'ios' ? 'iOS' : transaction.provider}</div>
						</div>
					</div>

					{/* Subscription Details */}
					{transaction.data ? (
						<div>
							<h3 className="text-20 font-bold mb-8">Subscription Details</h3>
							<div className="grid grid-cols-2 gap-y-4">
								<div className="font-bold">Package Name</div>
								<div>{getPackageName(transaction)}</div>
								<div className="font-bold">Status</div>
								<div className="capitalize">{transaction.data.stripe_status || '-'}</div>
								<div className="font-bold">Package Price</div>
								<div>{formatCurrency(transaction.data.package_price * 100)}</div>
								<div className="font-bold">Created At</div>
								<div>{moment(transaction.data.created_at).format('MM/DD/YY HH:mm:ss')}</div>
								{transaction.data.ends_at ? (
									<>
										<div className="font-bold">Ends At</div>
										<div>{moment(transaction.data.ends_at).format('MM/DD/YY HH:mm:ss')}</div>
									</>
								) : null}
							</div>
						</div>
					) : null}

					{/* Payment Details */}
					{transaction.payload?.payload || transaction.provider === 'ios' ? (
						<div>
							<h3 className="text-20 font-bold mb-8">Payment Details</h3>
							<div className="grid grid-cols-2 gap-y-4">
								{transaction.provider === 'ios' ? (
									<>
										<div className="font-bold">Transaction ID</div>
										<div className="font-mono text-12">{transaction.transaction_id}</div>

										<div className="font-bold">Subscription ID</div>
										<div className="font-mono text-12">{transaction.subscription_id}</div>

										{transaction.data.original_transaction_id ? (
											<>
												<div className="font-bold">Original Transaction ID</div>
												<div className="font-mono text-12">
													{transaction.data.original_transaction_id}
												</div>
											</>
										) : null}
									</>
								) : (
									<>
										<div className="font-bold">Invoice Number</div>
										<div>{transaction.payload.payload.data.object.number}</div>

										<div className="font-bold">Amount</div>
										<div>{formatCurrency(transaction.payload.payload.data.object.amount_due)}</div>

										<div className="font-bold">Status</div>
										<div className="capitalize">
											{transaction.payload.payload.data.object.status}
										</div>

										<div className="font-bold">Customer Email</div>
										<div>{transaction.payload.payload.data.object.customer_email}</div>

										<div className="font-bold">Customer Name</div>
										<div>{transaction.payload.payload.data.object.customer_name}</div>

										<div className="font-bold">Customer ID</div>
										<div className="font-mono text-12">
											{transaction.payload.payload.data.object.customer}
										</div>

										<div className="font-bold">Invoice ID</div>
										<div className="font-mono text-12">
											{transaction.payload.payload.data.object.id}
										</div>

										<div className="font-bold">Payment Intent ID</div>
										<div className="font-mono text-12">
											{transaction.payload.payload.data.object.payment_intent}
										</div>

										<div className="font-bold">Subscription ID</div>
										<div className="font-mono text-12">
											{transaction.payload.payload.data.object.subscription}
										</div>

										{transaction.payload.payload.data.object.charge ? (
											<>
												<div className="font-bold">Charge ID</div>
												<div className="font-mono text-12">
													{transaction.payload.payload.data.object.charge}
												</div>
											</>
										) : null}
									</>
								)}
							</div>
						</div>
					) : null}
				</div>
			</div>
		</Dialog>
	);
};

export default TransactionDetailsModal;
