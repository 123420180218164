import history from '@/@history';
import { getFinancialReportData } from '@/app/services/reports/reports';
import * as Actions from '@/app/store/actions';
import { Avatar } from '@material-ui/core';
import html2pdf from 'html2pdf.js';
import moment from 'moment-timezone';
import Pusher from 'pusher-js';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import secureLocalStorage from 'react-secure-storage';
import { useReactToPrint } from 'react-to-print';
import AppliedFiltersBar from '@shared/Reports/AppliedFiltersBar';
import ReportsHeader from '@shared/Reports/ReportsHeader';
import TableTooltip from '@shared/Reports/TableTooltip';
import FinancialFiltersBar from './FinancialFiltersBar';
import FinancialPrintViewTable from './FinancialPrintViewTable';
import FinancialTable from './FinancialTable';

const FinancialReport = () => {
	const [filters, setFilters] = useState({
		page: 1,
		transaction_id: null,
		company_id: [],
		school_id: [],
		parent_id: [],
		start_date: null,
		end_date: null,
		timezone: null,
		export: null,
		sort: 'date',
		dir: 'desc',
	});
	const user = useSelector((state) => state.auth.user);
	const viewAsId = secureLocalStorage.getItem('view_as_id');
	const authToken = secureLocalStorage.getItem('jwt_access_token');
	const [loading, setLoading] = useState(false);
	const [printData, setPrintData] = useState([]);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [printDataLoading, setPrintDataLoading] = useState(false);
	const [pdfDataLoading, setPdfDataLoading] = useState(false);
	const printRef = useRef();
	const [rows, setRows] = useState([]);
	const [totalResults, setTotalResults] = useState(0);
	const dispatch = useDispatch();
	const cache = useRef({}); // Cache object to prevent refetching pages already fetched
	const prevFilters = useRef(filters); // Track previous filters to know when to refetch a set of results
	const [exportType, setExportType] = useState(null);
	const [exportLoading, setExportLoading] = useState(false);
	const [hasFilterApplied, setHasFilterApplied] = useState(false);
	const exportTimeoutRef = useRef(null);

	const currencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

	useEffect(() => {
		const pusher = new Pusher(import.meta.env.VITE_PUSHER_CHANNEL_ID, {
			cluster: import.meta.env.VITE_PUSHER_CLUSTER_ID,
			channelAuthorization: {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
				endpoint: `${import.meta.env.VITE_API_ENDPOINT}broadcasting/auth`,
			},
		});
		const channel = pusher.subscribe(
			`private-App.Models.User.${user.role[0] === 'super_school_admin' ? viewAsId : user.data?.id}`
		);

		channel.bind('report.export.complete', (res) => {
			const exportLink = JSON.parse(window.localStorage.getItem('export_link'));
			if (exportLink && exportLink === res.link) {
				setExportLoading(false);
				const anchor = document.createElement('a');
				anchor.href = res.link;
				document.body.appendChild(anchor);
				anchor.click();
				document.body.removeChild(anchor);
				window.localStorage.removeItem('export_link');
				clearTimeout(exportTimeoutRef.current); // Clear the timeout
			}
		});

		return () => {
			pusher.disconnect();
		};
	}, []);

	const handlePrint = useReactToPrint({
		content: () => printRef.current,
	});

	useEffect(() => {
		setFilters({
			...filters,
			timezone: user.data.school.timezone,
		});
	}, [user]);

	const generateQueryParams = (filters, exportType = null) => {
		return {
			page: filters.page,
			start_date: filters.start_date,
			end_date: filters.end_date,
			timezone: filters.timezone,
			transaction_id: filters.transaction_id,
			export: exportType,
			parent_id: filters.parent_id?.length > 0 ? filters.parent_id.map((item) => item.value) : null,
			school_id: filters.school_id?.length > 0 ? filters.school_id.map((item) => item.value) : null,
			company_id: filters.company_id?.length > 0 ? filters.company_id.map((item) => item.value) : null,
			sort: filters.sort,
			dir: filters.dir,
		};
	};

	// Helper function to update filters and reset page to 1 if any filter other than page changes
	const updateFilters = (newFilters) => {
		const filtersChanged = Object.keys(newFilters).some(
			(key) => key !== 'page' && newFilters[key] !== filters[key]
		);
		if (filtersChanged) {
			setFilters({ ...newFilters, page: 1 });
		} else {
			setFilters(newFilters);
		}
	};
	// checks if any actionable filters have changed
	const hasAtLeastOneFilter = (filters) => {
		return Object.keys(filters).some(
			(key) =>
				key !== 'timezone' &&
				key !== 'page' &&
				key !== 'sort' &&
				key !== 'dir' &&
				key !== 'export' &&
				(Array.isArray(filters[key]) ? filters[key].length > 0 : filters[key])
		);
	};

	useEffect(() => {
		// Clear cache if any filter other than page changes
		const filtersChanged = Object.keys(filters).some(
			(key) => key !== 'page' && filters[key] !== prevFilters.current[key]
		);
		//  create a list of the filters than changed
		const changedFilters = Object.keys(filters).filter((key) => filters[key] !== prevFilters.current[key]);

		// clearing the date should update the results
		const dateCleared = changedFilters.includes('start_date') && changedFilters.includes('end_date');

		if (!dateCleared) {
			// if a date changed, but the other one is not set, we don't need to do anything until the other one is set
			if (changedFilters[0] === 'start_date' && !filters.end_date) {
				return;
			}
			if (changedFilters[0] === 'end_date' && !filters.start_date) {
				return;
			}
		}

		if (filtersChanged) {
			cache.current = {};
		}
		prevFilters.current = filters;

		const filterApplied = hasAtLeastOneFilter(filters);
		// update the state in here so the we can pass the most current hasFilterApplied state to the table
		setHasFilterApplied(filterApplied);

		if (!filters.timezone) {
			// Don't fetch data until timezone is set
			return;
		}

		setLoading(true);
		const queryParams = generateQueryParams(filters);
		const cachekey = JSON.stringify(queryParams);

		// Check if the results for the current page are already in the cache
		if (cache.current[cachekey]) {
			const cachedData = cache.current[cachekey];
			setRows(cachedData.data);
			setTotalResults(cachedData.total);
			setLoading(false);
		} else {
			getFinancialReportData(queryParams)
				.then((res) => {
					setRows(res.data.data);
					setTotalResults(res.data.meta.total);
					// Store the results in the cache
					cache.current[cachekey] = {
						data: res.data.data,
						total: res.data.meta.total,
					};
				})
				.catch(() => {
					dispatch(
						Actions.showMessage({
							message: 'Failed to fetch report',
							variant: 'error',
						})
					);
					setRows([]);
					setTotalResults(0);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	}, [filters]);

	const handleExport = async (type) => {
		if (filters.start_date && filters.end_date) {
			const queryParams = generateQueryParams(filters, type);
			try {
				const res = await getFinancialReportData(queryParams);
				window.localStorage.setItem('export_link', JSON.stringify(res.data.link));
				setExportLoading(true);

				// Set a timeout to reset exportLoading if no response within 20 seconds
				exportTimeoutRef.current = setTimeout(() => {
					setExportLoading(false);
					dispatch(
						Actions.showMessage({
							message: 'Export failed to generate. Please try again.',
							variant: 'error',
						})
					);
				}, 20000);
			} catch (error) {
				dispatch(
					Actions.showMessage({
						message: 'Failed to export report',
						variant: 'error',
					})
				);
			}
		}
	};

	const handlePrintClick = async () => {
		setExportType('print');
		setPrintDataLoading(true);
		setPrintData([]);

		try {
			const queryParams = generateQueryParams(filters, 'print');
			const res = await getFinancialReportData(queryParams);
			setPrintData(res.data.data);
			handlePrint();
		} catch (error) {
			console.error('Error fetching all pages for print:', error);
		} finally {
			setPrintDataLoading(false);
		}
	};

	const handleDownloadPDF = async () => {
		setExportType('pdf');
		setPrintData([]);
		if (!filters.start_date || !filters.end_date) {
			return;
		}
		setPdfDataLoading(true);
		try {
			// we set export to print because it fetches all pages
			// pdf is rendered in app
			const queryParams = generateQueryParams(filters, 'print');

			const res = await getFinancialReportData(queryParams);
			setPrintData(res.data.data);

			const element = printRef.current;
			const opt = {
				margin: 0.5,
				filename: 'FinancialReport.pdf',
				image: { type: 'jpeg', quality: 0.98 },
				html2canvas: { scale: 2 },
				jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
				pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
			};

			const style = document.createElement('style');
			style.innerHTML = `
			@media print {
				.page-break { page-break-before: always; }
				.avoid-page-break { page-break-inside: avoid; }
			}
		`;
			document.head.appendChild(style);

			html2pdf().from(element).set(opt).save();
		} catch (error) {
			console.error('Error fetching all pages for print:', error);
		} finally {
			setPdfDataLoading(false);
		}
	};

	const columns = [
		{
			id: 'email',
			label: 'Email',
			render: (row) => (
				<TableTooltip title={row.parent.email}>
					<div className="flex items-center gap-16 truncate">{row.parent.email}</div>
				</TableTooltip>
			),
			printRender: (row) => (
				<span className="break-all leading-snug" style={{ lineHeight: '.25' }}>
					{row.parent.email}
				</span>
			),
			widthClass: 'min-w-200',
		},
		{
			id: 'date',
			label: 'Transaction Date',
			render: (row) => {
				const date = moment(row.date);

				return (
					<div className="flex flex-col">
						<span>{date.format('MM/DD/YY')}</span>
					</div>
				);
			},
			printRender: (row) => <span className="text-right">{moment(row.date).format('MM/DD/YY')}</span>,
			widthClass: '',
		},
		{
			id: 'transaction_id',
			label: 'Transaction ID',

			render: (row) => (
				<TableTooltip title={row.transaction_id}>
					<div className="flex items-center gap-16 truncate">
						<p className="hover:underline cursor-pointer truncate">{row.transaction_id}</p>
					</div>
				</TableTooltip>
			),
			printRender: (row) => (
				<span className="break-all leading-snug" style={{ lineHeight: '.25' }}>
					{row.transaction_id}
				</span>
			),
			widthClass: 'min-w-200',
		},
		{
			id: 'amount',
			label: 'Amount Charged',
			render: (row) => (
				<div className="flex items-center gap-16">
					<span className="hover:underline">{currencyFormatter.format(row.amount)}</span>
				</div>
			),
			printRender: (row) => currencyFormatter.format(row.amount),
			widthClass: 'min-w-96',
			align: 'right',
		},

		{
			id: 'company',
			label: 'Company',
			render: (row) => (
				<div className="flex items-center gap-8">
					{row.company?.logo ? <Avatar className="w-32 h-32" src={row.company.logo} /> : null}
					<div className="flex flex-col truncate">
						<TableTooltip content={row.company?.name}>
							<span className="hover:underline cursor-pointer font-bold truncate">
								{row.company?.name}
							</span>
						</TableTooltip>
					</div>
				</div>
			),
			printRender: (row) => {
				return <span className="break-all">{row.company?.name}</span>;
			},
			widthClass: 'min-w-200',
		},
		{
			id: 'school',
			label: 'School',
			render: (row) => (
				<div className="flex items-center gap-8">
					{row.school?.logo ? <Avatar className="w-32 h-32" src={row.school.logo} /> : null}
					<div className="flex flex-col truncate">
						<TableTooltip content={row.school?.name}>
							<span className="hover:underline cursor-pointer font-bold truncate">
								{row.school?.name}
							</span>
						</TableTooltip>
					</div>
				</div>
			),
			printRender: (row) => <span className="break-all">{row.school?.name}</span>,
			widthClass: 'min-w-200',
		},
	];

	return (
		<div className="">
			<ReportsHeader
				exportLoading={exportLoading}
				exportsDisabled={!rows?.length || !filters.start_date || !filters.end_date}
				onBack={() => history.goBack()}
				onDownloadPDF={handleDownloadPDF}
				onExport={handleExport}
				onPrint={handlePrintClick}
				pdfLoading={pdfDataLoading}
				printLoading={printDataLoading}
				subtitle="Track key details of transactions."
				title="Financial Report"
			/>
			<FinancialFiltersBar filters={filters} setFilters={updateFilters} />
			{hasFilterApplied ? <AppliedFiltersBar
				filters={filters}
				hideFilters={['start_date', 'end_date', 'timezone', 'sort', 'dir', 'page']}
				reportType="financial"
				setFilters={updateFilters}
			/> : null}
			<div className="bg-white">
				<div>
					<FinancialTable
						columns={columns}
						data={rows}
						dir={filters.dir}
						filters={filters}
						hasFilterApplied={hasFilterApplied}
						loading={loading}
						page={filters.page}
						rowsPerPage={rowsPerPage}
						setDir={(dir) => updateFilters({ ...filters, dir })}
						setFilters={updateFilters}
						setPage={(page) => updateFilters({ ...filters, page })}
						setRowsPerPage={setRowsPerPage}
						setSort={(sort) => updateFilters({ ...filters, sort })}
						sort={filters.sort}
						totalResults={totalResults}
					/>
				</div>
				<div className="h-0 overflow-hidden">
					<FinancialPrintViewTable
						columns={columns}
						data={printData}
						exportType={exportType}
						filters={filters}
						ref={printRef}
						title="Financial Report"
					/>
				</div>
			</div>
		</div>
	);
};

export default FinancialReport;
