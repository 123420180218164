import React, { useMemo } from "react";
import FilterSolidIcon from "../../icons/FilterSolidIcon";
import { Divider } from "@material-ui/core";
import PopoverSelect from "../PopoverSelect";
import PopoverCombobox from "../PopoverCombobox";
import HierarchicalSearchSelect from "../HierarchicalSearchSelect";

const UserFiltersBar = ({
  filters,
  setFilters,
  isOwner,
  schoolsLoading,
  companies,
}) => {
  const handleFilterChange = (newFilters) => {
    setFilters({
      ...newFilters,
      page: 1,
    });
  };

  const handleHierarchicalSelect = (selectedIds, selectedItems) => {
    handleFilterChange({
      ...filters,
      school_id: selectedItems.map((item) => ({
        value: item.id,
        type: item.type,
        label: item.name,
      })),
    });
  };
  const schools = useMemo(() => {
    return (
      companies?.reduce((acc, company) => {
        if (company.schools && Array.isArray(company.schools)) {
          // Only include active schools (status: true)
          const activeSchools = company.schools.filter(
            (school) => school.status
          );
          return [...acc, ...activeSchools];
        }
        return acc;
      }, []) || []
    );
  }, [companies]);

  return (
    <div className="md:py-10 px-24 md:px-32 justify-between flex bg-white text-black">
      <span className="flex items-center gap-8 text-14 text-gray-600">
        <FilterSolidIcon className="size-16" /> Filters:
      </span>
      <div className="flex gap-8 md:gap-16">
        {isOwner ? (
          <HierarchicalSearchSelect
            compact
            companies={companies}
            isFilter={true}
            label="Companies & Schools"
            onChange={handleHierarchicalSelect}
            placeholder="Select companies or schools"
            title="Companies & Schools"
            value={filters.school_id?.map((item) => item.value) || []}
          />
        ) : (
          <PopoverCombobox
            compact
            currentFilter="school_id"
            filters={filters}
            loading={schoolsLoading}
            options={schools?.map((school) => ({
              label: school.name,
              value: school.id,
            }))}
            setFilter={handleFilterChange}
            title="Schools"
          />
        )}
        <Divider className="" flexItem orientation="vertical" />
        <PopoverSelect
          compact
          currentFilter="group"
          filters={filters}
          options={[
            { label: "System Level", value: "system" },
            { label: "School System", value: "school" },
          ]}
          setFilter={handleFilterChange}
          title="User Group"
        />
        <Divider className="" flexItem orientation="vertical" />
        <PopoverSelect
          compact
          currentFilter="status"
          filters={filters}
          options={[
            { label: "Active", value: true },
            { label: "Inactive", value: false },
          ]}
          setFilter={handleFilterChange}
          title="Status"
        />{" "}
      </div>
    </div>
  );
};

export default UserFiltersBar;
