import {
	CircularProgress,
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { getSchools } from 'app/services/reports/reports';
import { Wrapper } from '@googlemaps/react-wrapper';
import FuseAnimate from '@fuse/core/FuseAnimate';
import { getStateListByCountry, getCityList } from 'app/services/Company/companyService';
import CustomDatePicker from '@shared/customComponents/CustomDatePicker/CustomDatePicker';
import CustomButton from 'app/customComponents/CustomButton/CustomButton';
import Map from './GoogleMap';
import { Close } from '@material-ui/icons';

function MapViewOfSchools() {
	const [schoolList, setSchoolList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [stateList, setStateList] = React.useState([]);
	const [allSchools, setAllSchools] = React.useState([]);
	const [cityList, setCityList] = React.useState([]);
	const [firstLoad, setFirstLoad] = React.useState(true);
	const [filters, setFilters] = React.useState({
		name: '',
		type: '',
		school: '',
		state: '',
		city: '',
		startDate: '',
		endDate: '',
	});

	const handleFilterChange = (event) => {
		setFilters({ ...filters, [event.target.name]: event.target.value });
	};

	useEffect(() => {
		let isMounted = true;
		// setLoading(true);
		getStateListByCountry('USA').then((res) => {
			if (!isMounted) return;
			setStateList(res.data);
		});
		getSchools()
			.then((res) => {
				if (!isMounted) return;
				setSchoolList(res.data);
				setAllSchools(res.data);
			})
			.finally(() => {
				if (!isMounted) return;
				// setLoading(false);
				setFirstLoad(false);
			});
		return () => {
			isMounted = false;
		};
	}, []);

	useEffect(() => {
		let isMounted = true;
		setCityList([]);
		setFilters({ ...filters, city: '' });
		if (filters.state) {
			getCityList(filters.state).then((res) => {
				if (!isMounted) return;
				setCityList(res.data);
			});
		}
		return () => {
			isMounted = false;
		};
	}, [filters.state]);

	const applyFilters = () => {
		setLoading(true);
		getSchools(
			filters.name,
			filters.type,
			filters.school === 'all' ? '' : filters.school,
			filters.state,
			filters.city,
			filters.startDate,
			filters.endDate,
			'', // no need for page in case of map
			false, // cannot export this map
			1, // for map true
		)
			.then((res) => {
				setSchoolList(res.data);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<FuseAnimate animation="transition.slideLeftIn" duration={600}>
			<div className="mx-auto">
				<h3 className="text-pd-xl self-end font-bold mt-28">Map View</h3>{' '}
				<div className="flex items-center flex-nowrap justify-end">
					<div className="flex">
						<div className="mx-4">
							<TextField
								value={filters.name}
								onChange={handleFilterChange}
								name="name"
								className="mr-10"
								id="search-input"
								label="Search by Name"
								style={{ width: 150 }}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												id="search-icon"
												onClick={() => {
													document.getElementById('search-input').focus();
												}}
											>
												<img
													alt="search-icon"
													src="assets/images/search-icon.svg"
													height="80%"
													width="80%"
												/>
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
						</div>
						<div className="mx-4">
							<FormControl>
								<InputLabel id="school_id">School</InputLabel>
								<Select
									name="school"
									onChange={handleFilterChange}
									labelId="school_id"
									id="school_id"
									label="School"
									value={filters.school}
									style={{ width: 150 }}
									endAdornment={
										filters.school ? (
											<IconButton
												size="small"
												className="mr-16"
												onClick={() => {
													setFilters({
														...filters,
														school: '',
													});
												}}
											>
												<Close fontSize="small" />
											</IconButton>
										) : (
											''
										)
									}
								>
									{!firstLoad ? (
										allSchools.length > 0 ? (
											allSchools?.map((school) => {
												return (
													<MenuItem key={school.id} value={school.id}>
														<span>{`${school.name}`}</span>
													</MenuItem>
												);
											})
										) : (
											<MenuItem disabled value="">
												<span>No School Found</span>
											</MenuItem>
										)
									) : (
										<MenuItem disabled>Loading...</MenuItem>
									)}
								</Select>
							</FormControl>
						</div>
						<div className="mx-4">
							<FormControl>
								<InputLabel id="state_id">States</InputLabel>
								<Select
									name="state"
									onChange={handleFilterChange}
									labelId="state_id"
									id="state_id"
									label="States"
									value={filters.state}
									style={{ width: 150 }}
									endAdornment={
										filters.state ? (
											<IconButton
												size="small"
												className="mr-16"
												onClick={() => {
													setFilters({
														...filters,
														state: '',
														city: '',
													});
												}}
											>
												<Close fontSize="small" />
											</IconButton>
										) : (
											''
										)
									}
								>
									{!firstLoad ? (
										stateList?.map((state) => {
											return (
												<MenuItem key={state.id} value={state.id}>
													<span>{state.name}</span>
												</MenuItem>
											);
										})
									) : (
										<MenuItem disabled>Loading...</MenuItem>
									)}
								</Select>
							</FormControl>
						</div>
						<div className="mx-4">
							<FormControl>
								<InputLabel id="city">City</InputLabel>
								<Select
									name="city"
									onChange={handleFilterChange}
									labelId="city"
									id="city"
									label="City"
									value={filters.city}
									style={{ width: 150 }}
									endAdornment={
										filters.city ? (
											<IconButton
												size="small"
												className="mr-16"
												onClick={() => {
													setFilters({
														...filters,
														city: '',
													});
												}}
												fontSize="small"
											>
												<Close />
											</IconButton>
										) : (
											''
										)
									}
								>
									{!filters.state ? (
										<MenuItem disabled value="">
											<span>Select State</span>
										</MenuItem>
									) : cityList.length > 0 ? (
										cityList?.map((city) => {
											return (
												<MenuItem key={city.id} value={city.name}>
													<span>{city.name}</span>
												</MenuItem>
											);
										})
									) : (
										<MenuItem disabled value="">
											<span>Loading...</span>
										</MenuItem>
									)}
								</Select>
							</FormControl>
						</div>

						{/* <div className="mx-4"> */}
						<div className="mx-6 student-date-field" style={{ width: 150 }}>
							<CustomDatePicker
								id="date-from"
								label="Date From"
								value={filters.startDate}
								setValue={(Date) => {
									setFilters({ ...filters, startDate: Date?.format('YYYY-MM-DD') || '' });
								}}
								maxDate={filters.endDate || new Date()}
							/>
						</div>
						<div className="mr-20 ml-6 student-date-field" style={{ width: 150 }}>
							<CustomDatePicker
								id="date-to"
								label="Date To"
								value={filters.endDate}
								setValue={(Date) => {
									setFilters({ ...filters, endDate: Date?.format('YYYY-MM-DD') || '' });
								}}
								maxDate={new Date()}
								minDate={filters.startDate}
							/>
						</div>
						{/* </div> */}
					</div>

					<div className="">
						<span>
							<span className="mx-4">
								<CustomButton
									variant="secondary"
									height="43"
									width="100px"
									fontSize="15px"
									onClick={() => {
										applyFilters();
									}}
								>
									Apply
								</CustomButton>
							</span>
						</span>
					</div>
				</div>
				<Wrapper apiKey={import.meta.env.VITE_GOOGLE_API_ENDPOINT} libraries={['marker']}>
					<div className="map-wrapper bg-white p-6 rounded">
						{loading ? (
							<div className="flex justify-center items-center h-full">
								<CircularProgress size={35} />
							</div>
						) : (
							<Map schools={schoolList} zoomLevel={schoolList.length > 0 ? 2 : 2} />
						)}
					</div>
				</Wrapper>
			</div>
		</FuseAnimate>
	);
}

export default MapViewOfSchools;
