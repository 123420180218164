import * as React from 'react';
const DeleteRedGradIcon = (props) => (
	<svg fill="none" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path d="M18 36c9.941 0 18-8.059 18-18S27.941 0 18 0 0 8.059 0 18s8.059 18 18 18Z" fill="url(#delete-red-icon-a)" />
		<path
			d="M24.689 23.266V13.098h.6a.606.606 0 0 0 0-1.211h-3.084v-1.045a1.794 1.794 0 0 0-1.791-1.792h-3.8a1.794 1.794 0 0 0-1.789 1.792v1.045h-3.22a.606.606 0 0 0 0 1.21h.739v10.169a2.8 2.8 0 0 0 2.8 2.8h6.74a2.8 2.8 0 0 0 2.805-2.8Zm-8.652-12.424a.582.582 0 0 1 .58-.581h3.8a.582.582 0 0 1 .581.58v1.046h-4.962v-1.045Zm-2.483 12.424V13.193h9.923v10.073a1.593 1.593 0 0 1-1.591 1.592h-6.74a1.593 1.593 0 0 1-1.593-1.592Z"
			fill="#FF0900"
		/>
		<path
			d="M17.054 15.883a.605.605 0 0 0-.605.605v4.965a.606.606 0 0 0 1.211 0v-4.964a.603.603 0 0 0-.606-.606ZM20.082 15.883a.605.605 0 0 0-.605.605v4.965a.606.606 0 0 0 1.21 0v-4.964a.605.605 0 0 0-.605-.606Z"
			fill="#FF0900"
		/>
		<path
			d="M18 35.5c9.665 0 17.5-7.835 17.5-17.5S27.665.5 18 .5.5 8.335.5 18 8.335 35.5 18 35.5Z"
			stroke="#FF4B4B"
			strokeMiterlimit={10}
		/>
		<defs>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="delete-red-icon-a"
				x1={28.656}
				x2={-3.96}
				y1={3.816}
				y2={49.752}
			>
				<stop stopColor="#fff" />
				<stop offset={1} stopColor="#FFE3C3" />
			</linearGradient>
		</defs>
	</svg>
);
export default DeleteRedGradIcon;
