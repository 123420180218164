import axios from '@shared/axios-shared';

const updateProfile = (data) => {
	return axios.put(`/api/v1/profile`, data);
};
const resetPassword = (data) => {
	return axios.post(`/api/v1/admin/reset-password`, data);
};

export { updateProfile, resetPassword };
