import axios from '@shared/axios-shared';

const getNotifications = (page) => {
	return axios.get(`/api/v1/owner/notification?page=${page}`);
};

const readAllNotifications = () => {
	return axios.patch(`/api/v1/owner/notifications/read-all`);
};

const deleteAllNotifications = () => {
	return axios.delete(`/api/v1/owner/notifications`);
};

// create notification

const getCompanyList = (page) => {
	return axios.get(`/api/v1/owner/company-list`);
};

const getSchoolList = (companies) => {
	return axios.post(`/api/v1/owner/school-list`, { companies });
};

const getParentList = (schools) => {
	return axios.post(`/api/v1/owner/parent-list`, { schools });
};

const sendNotification = (payload) => {
	return axios.post(`/api/v1/owner/send-notice`, payload);
};

const getNotificationmessage = (company, school, parent, message, page) => {
	return axios.get(
		`api/v1/owner/notice-list?company=${company}&school=${school}&parent=${parent}&message=${message}&page=${page}`
	);
};

export {
	deleteAllNotifications,
	getCompanyList,
	getNotificationmessage,
	getNotifications,
	getParentList,
	getSchoolList,
	readAllNotifications,
	sendNotification,
};
