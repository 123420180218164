import DayjsUtils from '@date-io/dayjs';
import { DatePicker as MuiDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import dayjs from 'dayjs';
import React, { useRef } from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import { ReactComponent as CalendarIcon } from './date.svg';

const DatePicker = ({
	value,
	setValue,
	errTxts,
	disabled = false,
	disableFuture = false,
	disablePast = false,
	label,
	minDate = undefined,
	maxDate = undefined,
	width = undefined,
	placeholder,
	id,
	paddingLeft,
	paddingRight,
	dateStyle,
	required = false,
	clearable = true,
	variant,
}) => {
	const ref = useRef(null);

	return (
		<>
			<div
				onClick={() => ref.current.click()}
				onKeyDown={(e) => {
					if (e.key === 'Enter') ref.current.click();
				}}
				role="button"
				style={{ width }}
				tabIndex={0}
			>
				<TextField
					InputLabelProps={{ shrink: true }}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<CalendarIcon />
							</InputAdornment>
						),
					}}
					className={dateStyle || ''}
					disabled={disabled}
					error={!!errTxts?.length}
					helperText={errTxts}
					id={id}
					label={label}
					name="dob"
					onChange={(e) => setValue(dayjs(e.target.value).isValid() ? dayjs(e.target.value) : '')}
					onClick={(e) => e.preventDefault()}
					required={required}
					style={{ width: '100%', paddingLeft: paddingLeft || '', paddingRight: paddingRight || '' }}
					type="text"
					value={value ? dayjs(value).format('MMM DD, YYYY') : placeholder || 'mm / dd / yyyy'}
					variant={variant}
				/>
			</div>
			<MuiPickersUtilsProvider utils={DayjsUtils}>
				<MuiDatePicker
					autoOk
					clearable={clearable}
					disableFuture={disableFuture}
					disablePast={disablePast}
					disabled={disabled}
					format="dd/MM/yyyy"
					inputRef={ref}
					maxDate={maxDate}
					minDate={minDate}
					onChange={setValue}
					orientation="portrait"
					style={{ display: 'none' }}
					value={value}
				/>
			</MuiPickersUtilsProvider>
		</>
	);
};

export default DatePicker;
