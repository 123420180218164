// import { getAllRooms } from '@/app/services/rooms/rooms';
import { Divider } from '@material-ui/core';
import { useState } from 'react';
import FilterIcon from '@shared/icons/FilterIcon';
import PopoverDateRange from '@shared/Reports/PopoverDateRange';
import PopoverSelect from '@shared/Reports/PopoverSelect';
import { getSubscribersList } from '@/app/services/reports/reports';
import EmailPopoverCombobox from 'shared/components/EmailPopoverCombobox';

const SubscriptionTransactionFiltersBar = ({ filters, setFilters }) => {
	const [searchTimeout, setSearchTimeout] = useState(null);
	const [subscribers, setSubscribers] = useState([]);
	const [subscribersLoading, setSubscribersLoading] = useState(false);

	const handleSearchParents = async (searchTerm) => {
		if (searchTimeout) {
			clearTimeout(searchTimeout);
		}

		const timeoutId = setTimeout(async () => {
			setSubscribersLoading(true);
			try {
				const res = await getSubscribersList({ search: searchTerm });

				setSubscribers(
					res.data.data.map((parent) => {
						return { label: parent.name, value: parent.id, email: parent.email };
					})
				);
			} catch (error) {
				console.error('Failed to fetch parents', error);
			}
			setSubscribersLoading(false);
		}, 300);

		setSearchTimeout(timeoutId);
	};

	return (
		<div
			className="text-white py-16 px-16 md:px-32 justify-between flex flex-wrap"
			style={{ background: 'rgb(0, 46,108)' }}
		>
			<div className="flex items-center gap-8 text-16">
				<FilterIcon /> <span>Filter:</span>
			</div>
			<div className="flex gap-8 md:gap-16 flex-wrap">
				<EmailPopoverCombobox
					currentFilter="parent_id"
					filters={filters}
					loading={subscribersLoading}
					onSearch={handleSearchParents}
					options={subscribers}
					setFilter={setFilters}
					title="Subscriber"
				/>
				<Divider className="bg-white" flexItem orientation="vertical" />
				<PopoverSelect
					currentFilter="platform"
					filters={filters}
					options={[
						{ label: 'iOS', value: 'ios' },
						{ label: 'Android', value: 'android' },
					]}
					setFilter={setFilters}
					title="Platform"
				/>
				<Divider className="bg-white" flexItem orientation="vertical" />
				<PopoverDateRange filters={filters} setFilters={setFilters} />
			</div>
		</div>
	);
};

export default SubscriptionTransactionFiltersBar;
