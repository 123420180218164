import DayjsUtils from "@date-io/dayjs";
import { useMaskito } from "@maskito/react";
import { InputAdornment, TextField, withStyles } from "@material-ui/core";
import { Error } from "@material-ui/icons";
import {
  DatePicker as MuiDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React, { forwardRef, useImperativeHandle } from "react";
import CalendarDotsIcon from "../icons/CalendarDotsIcon";

const styles = {
  root: {
    "& label.Mui-focused": {
      color: "#5E6368",
      borderColor: "#5E6368",
      transform: "translate(0px, -24px) ",
    },
    "& label.MuiFormLabel-filled": {
      transform: "translate(0px, -24px) ",
    },

    "& .MuiInputLabel-formControl": {
      transform: "translate(0px, -24px) ",
    },

    "& .MuiOutlinedInput-root": {
      background: "#F9F9F9",
      color: "#5E6368",
      paddingBlock: "2.5px",
      "&.Mui-focused": {
        background: "#F1F5FE",
      },
      "&.Mui-focused fieldset ": {
        borderColor: "#3339FF",
        borderWidth: "1px",
      },
      borderColor: "#5E6368",
      borderRadius: "10px",
      "&.Mui-error": {
        background: "#FEF1F1",
        borderColor: "#EF2C2C",
      },

      "& fieldset": {
        top: 0,
      },
      "& legend": {
        display: "none",
      },
    },

    "& .MuiFormHelperText-root": {
      marginLeft: "0",
      color: "#EF2C2C",
      fontSize: "12px",
      lineHeight: "8px",
    },
    "& input": {
      caretColor: "#3339FF",
      color: "black",
      "&::placeholder": {
        opacity: 1,
        color: "#9e9e9e",
        fontWeight: 400,
      },
    },
  },
};

const CustomTextField = withStyles(styles)(TextField);
const CustomDatePicker = withStyles(styles)(MuiDatePicker);

const StyledInput = forwardRef(
  (
    {
      helperText = "Required",
      error = false,
      showErrorIcon = true,
      type = "text",
      label,
      onChange,
      onBlur,
      placeholder,
      icon,
      ...props
    },
    ref
  ) => {
    const phoneInputRef = useMaskito({
      options: {
        mask: [
          "(",
          /\d/,
          /\d/,
          /\d/,
          ")",
          " ",
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ],
      },
    });

    useImperativeHandle(ref, () => ({
      focus: () => {
        if (phoneInputRef.current) {
          phoneInputRef.current.focus();
        }
      },
    }));

    if (type === "date") {
      return (
        <MuiPickersUtilsProvider utils={DayjsUtils}>
          <CustomDatePicker
            autoOk
            error={error}
            inputVariant="outlined"
            label={label}
            orientation="portrait"
            size="small"
            {...props}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              placeholder: placeholder,
              endAdornment: (
                <InputAdornment position="end">
                  <CalendarDotsIcon className="w-24" />
                </InputAdornment>
              ),
              inputProps: {
                ref: ref,
              },
            }}
            clearable
            format="MM/DD/YYYY"
            helperText={
              error ? (
                <>
                  {showErrorIcon && error ? (
                    <Error className="w-20 mr-2" />
                  ) : null}
                  {helperText}
                </>
              ) : null
            }
            onBlur={onBlur}
            onChange={onChange}
          />
        </MuiPickersUtilsProvider>
      );
    }
    if (type === "phone") {
      return (
        <CustomTextField
          label={label}
          variant="outlined"
          {...props}
          InputLabelProps={{
            shrink: true,
          }}
          error={error}
          helperText={
            error ? (
              <>
                {showErrorIcon && error ? (
                  <Error className="w-20 mr-2" />
                ) : null}
                {helperText}
              </>
            ) : null
          }
          inputProps={{
            ref: phoneInputRef,
            onBlur: onBlur,
            placeholder: placeholder,
          }}
          onInput={onChange}
          size="small"
        />
      );
    }

    return (
      <CustomTextField
        label={label}
        type={type}
        variant="outlined"
        {...props}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment: icon ? (
            <InputAdornment position="end">{icon}</InputAdornment>
          ) : null,
        }}
        className=""
        error={error}
        helperText={
          error ? (
            <>
              {showErrorIcon && error ? <Error className="w-20 mr-2" /> : null}
              {helperText}
            </>
          ) : null
        }
        inputProps={{
          ref: ref,
          onChange: onChange,
          onBlur: onBlur,
          placeholder: placeholder,
        }}
        size="small"
      />
    );
  }
);

StyledInput.displayName = "StyledInput";

export default StyledInput;
