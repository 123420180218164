import { XMarkIcon } from "@heroicons/react/24/outline";
import ChevronDownIcon from "../icons/ChevronDownIcon";
import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Popover,
  TextField,
  withStyles,
} from "@material-ui/core";
import { ArrowDropDown, Error } from "@material-ui/icons";
import React, { forwardRef, useEffect, useState } from "react";
import SearchIcon from "../icons/SearchIcon";

const StyledFormControl = withStyles({
  root: {
    "& .Mui-disabled": {
      cursor: "not-allowed",
    },
    "&.Mui-disabled": {
      background: "#e9e9e9",
      color: "#9e9e9e",
      cursor: "not-allowed",
    },
    "& .Mui-disabled ": {
      color: "#9e9e9e",
    },
    "& label.Mui-focused": {
      color: "#5E6368",
      borderColor: "#5E6368",
      transform: "translate(0px, -24px) ",
    },
    "& label.MuiFormLabel-filled": {
      transform: "translate(0px, -24px) ",
    },

    "& .MuiInputLabel-formControl": {
      transform: "translate(0px, -24px) ",
    },

    "& .MuiOutlinedInput-root": {
      background: "#F9F9F9",
      color: "#5E6368",
      cursor: "pointer",
      paddingBlock: "2.5px",
      "&.Mui-focused": {
        background: "#F1F5FE",
      },
      "&.Mui-focused fieldset ": {
        borderColor: "#3339FF",
        borderWidth: "1px",
      },
      borderColor: "#5E6368",
      borderRadius: "10px",
      "&.Mui-error": {
        background: "#FEF1F1",
        borderColor: "#EF2C2C",
      },

      "& fieldset": {
        top: 0,
      },

      "& legend": {
        display: "none",
      },
    },

    "& .MuiFormHelperText-root": {
      marginLeft: "0",
      color: "#EF2C2C",
      fontSize: "14px",
    },
    "& input": {
      caretColor: "#3339FF",
      color: "#5E6368",
      cursor: "pointer",
      "&::placeholder": {
        opacity: 1,
        color: "#9e9e9e",
        fontWeight: 400,
      },
    },
  },
})(FormControl);
const styles = {
  root: {
    border: "none",
  },
};
const textFieldStyles = {
  root: {
    "& label.Mui-focused": {
      color: "#5E6368",
      borderColor: "#5E6368",
      transform: "translate(0px, -24px) ",
    },
    "& label.MuiFormLabel-filled": {
      transform: "translate(0px, -24px) ",
    },

    "& .MuiInputLabel-formControl": {
      transform: "translate(0px, -24px) ",
    },

    "& .MuiOutlinedInput-root": {
      background: "#F9F9F9",
      color: "#5E6368",
      paddingBlock: "2.5px",
      "&.Mui-focused": {
        background: "#F1F5FE",
      },
      "&.Mui-focused fieldset ": {
        borderColor: "#3339FF",
        borderWidth: "1px",
      },
      borderColor: "#5E6368",
      borderRadius: "10px",
      "&.Mui-error": {
        background: "#FEF1F1",
        borderColor: "#EF2C2C",
      },

      "& fieldset": {
        top: 0,
      },
      "& legend": {
        display: "none",
      },
      "& input": {
        paddingBlock: "10.5px",
      },
      "&.Mui-disabled": {
        background: "#e9e9e9",
        color: "#9e9e9e",
        cursor: "not-allowed",
      },
    },

    "& .MuiFormHelperText-root": {
      marginLeft: "0",
      color: "#EF2C2C",
      fontSize: "14px",
    },
    "& input": {
      caretColor: "#3339FF",
    },
  },
};
const CustomCombobox = withStyles(styles)(TextField);
const CustomTextField = withStyles(textFieldStyles)(TextField);

const HierarchicalSearchSelect = forwardRef(
  (
    {
      label,
      companies,
      helperText = "Required",
      error = false,
      disabled = false,
      showErrorIcon = true,
      placeholder,
      onChange,
      value = [],
      isFilter = false,
      title,
      lockedSchools = [],
      compact = false,
      customTriggerClass,
      allowTopLevelSelect = true,
      singleCompanySelect = false,
    },
    ref
  ) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [expandedCompanies, setExpandedCompanies] = useState({});
    const [companySchools, setCompanySchools] = useState({});

    useEffect(() => {
      const schoolsData = {};

      companies.forEach((company) => {
        if (company.type === "company" && company.schools) {
          schoolsData[company.id] = company.schools.filter(
            (school) => school.type === "school"
          );
        }
      });

      setCompanySchools(schoolsData);
    }, [companies]);

    const handleSelect = (item, isCompany) => {
      let newValue = [];
      let selectedItems = [];

      if (isCompany) {
        // school admin can't select whole companies
        if (!allowTopLevelSelect) {
          return;
        }

        if (singleCompanySelect) {
          // Toggle company selection: if already selected, clear it -- otherwise, select only this one.
          if (value.includes(item.id)) {
            newValue = [];
          } else {
            newValue = [item.id];
          }
          // Only add to selectedItems if the company is selected.
          selectedItems = newValue.includes(item.id)
            ? [{ ...item, type: "company" }]
            : [];
        } else {
          // Multiple selections allowed
          if (value.includes(item.id)) {
            // Deselect company and all its schools.
            newValue = value.filter((id) => {
              const schoolIds =
                companySchools[item.id]?.map((school) => school.id) || [];
              return !schoolIds.includes(id) && id !== item.id;
            });
          } else {
            // Select company and remove any individual school selections for that company.
            const schoolIds =
              companySchools[item.id]?.map((school) => school.id) || [];
            newValue = [
              ...value.filter((id) => !schoolIds.includes(id)),
              item.id,
            ];
          }
          selectedItems.push({ ...item, type: "company" });
        }
      } else {
        // School selection
        if (singleCompanySelect) {
          // Toggle school selection in single mode.
          if (value.includes(item.id)) {
            newValue = [];
          } else {
            newValue = [item.id];
          }
          selectedItems = newValue.includes(item.id)
            ? [{ ...item, type: "school" }]
            : [];
        } else {
          const parentCompanyId = item.company_id;
          if (value.includes(item.id)) {
            // Deselect the school.
            newValue = value.filter((id) => id !== item.id);
          } else {
            // If selecting a school, remove parent company if it was selected.
            newValue = [
              ...value.filter((id) => id !== parentCompanyId),
              item.id,
            ];
          }
          selectedItems.push({ ...item, type: "school" });
        }
      }

      onChange(newValue, selectedItems);
    };

    const renderValue = () => {
      const selectedItems = value
        .map((id) => {
          const company = companies.find((c) => c.id === id);
          if (company) return company.name;

          for (const companyId in companySchools) {
            const school = companySchools[companyId]?.find((s) => s.id === id);
            if (school) return school.name;
          }
          return "";
        })
        .filter(Boolean);

      if (selectedItems.length === 0) {
        return "";
      } else if (selectedItems.length === 1) {
        return selectedItems[0];
      } else {
        return `${selectedItems[0]} +${selectedItems.length - 1}`;
      }
    };

    const getFilteredCompanies = () => {
      if (!searchTerm) return companies;

      const searchLower = searchTerm.toLowerCase();

      return companies.filter((company) => {
        // Check if company name matches
        const companyMatches = company.name.toLowerCase().includes(searchLower);

        // Check if any schools match
        const schoolsList = companySchools[company.id] || [];
        const hasMatchingSchools = schoolsList.some((school) =>
          school.name.toLowerCase().includes(searchLower)
        );

        // Include company if either company name or any school name matches
        return companyMatches || hasMatchingSchools;
      });
    };
    // when components is being used as a filter don't show the text field
    if (isFilter) {
      return (
        <div>
          <button
            className={
              customTriggerClass ||
              `flex items-center gap-8 md:text-16 border border-transparent rounded-md p-4 ${
                compact ? "!text-14 text-[#374151] font-medium" : "text-16"
              }`
            }
            onClick={(e) => setAnchorEl(e.currentTarget)}
            type="button"
          >
            {title}
            {value?.length > 0 && !customTriggerClass ? (
              <span
                className={`text-10 text-black rounded h-16 w-16 flex items-center justify-center ${
                  compact ? "bg-grey-300" : "bg-white"
                }`}
              >
                {value.length}
              </span>
            ) : null}
            <ChevronDownIcon
              className={`w-10 ${customTriggerClass ? "ml-auto" : ""}`}
            />
          </button>

          <Popover
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            className="max-h-256"
            onClose={() => {
              setAnchorEl(null);
              setSearchTerm("");
              setExpandedCompanies({});
            }}
            open={Boolean(anchorEl)}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
          >
            <div className="flex flex-col gap-0 my-0 p-8 pt-0 border-grey-200 rounded bg-white">
              <div className="flex items-center gap-8 px-8 sticky top-0 bg-white py-2 pt-8 z-10">
                <SearchIcon className="w-20 h-20 ml-2" />
                <CustomCombobox
                  InputProps={{
                    disableUnderline: true,
                    className: "",
                  }}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    if (e.target.value) {
                      const searchLower = e.target.value.toLowerCase();
                      const newExpandedState = { ...expandedCompanies };
                      companies.forEach((company) => {
                        if (
                          company.schools?.some((school) =>
                            school.name.toLowerCase().includes(searchLower)
                          )
                        ) {
                          newExpandedState[company.id] = true;
                        }
                      });
                      setExpandedCompanies(newExpandedState);
                    }
                  }}
                  placeholder="Search"
                  size="medium"
                  value={searchTerm}
                  variant="standard"
                />
              </div>

              <div className="flex flex-col gap-4">
                {getFilteredCompanies().map((company) => {
                  const companySchools = company.schools || [];
                  const isCompanySelected = value.includes(company.id);

                  return (
                    <div key={company.id}>
                      <button
                        className="gap-8 px-8 mx-0 hover:bg-grey-200 rounded-md w-full flex items-center"
                        disabled={!allowTopLevelSelect}
                        onClick={() => {
                          // Only call handleSelect if top level selection is allowed
                          if (allowTopLevelSelect) {
                            handleSelect(company, true);
                          }
                        }}
                        type="button"
                      >
                        <Checkbox
                          checked={isCompanySelected}
                          color="primary"
                          disableRipple
                          disabled={!allowTopLevelSelect}
                        />
                        <span>{company.name}</span>
                        {companySchools.length > 0 ? (
                          <button
                            className="ml-auto p-8 rounded-full hover:bg-gray-100 transition-colors"
                            onClick={(e) => {
                              e.stopPropagation();
                              setExpandedCompanies((prev) => ({
                                ...prev,
                                [company.id]: !prev[company.id],
                              }));
                            }}
                            type="button"
                          >
                            <ChevronDownIcon
                              className={`w-20 h-20 transition-transform duration-200 ${
                                expandedCompanies[company.id]
                                  ? "scale-y-[-1]"
                                  : ""
                              }`}
                            />
                          </button>
                        ) : null}
                      </button>
                      {expandedCompanies[company.id]
                        ? companySchools.map((school) => (
                            <button
                              className="gap-8 px-8 pl-24 mx-0 hover:bg-grey-200 rounded-md w-full flex items-center"
                              key={school.id}
                              onClick={() => handleSelect(school, false)}
                              type="button"
                            >
                              <span className="text-gray-500 ">-</span>
                              <Checkbox
                                checked={
                                  isCompanySelected || value.includes(school.id)
                                }
                                color={
                                  isCompanySelected ? "default" : "primary"
                                }
                                disableRipple
                                style={{
                                  color: isCompanySelected
                                    ? "#9e9e9e"
                                    : undefined,
                                }}
                              />
                              <span
                                className={
                                  isCompanySelected
                                    ? "text-gray-500"
                                    : undefined
                                }
                              >
                                {school.name}
                              </span>
                              {isCompanySelected ? (
                                <span className="ml-2 text-sm text-gray-500 italic">
                                  (included with company)
                                </span>
                              ) : null}
                            </button>
                          ))
                        : null}
                    </div>
                  );
                })}
              </div>
            </div>
          </Popover>
        </div>
      );
    }

    return (
      <StyledFormControl error={error} size="small" variant="outlined">
        <InputLabel shrink={true}>{label}</InputLabel>
        <CustomTextField
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                {getFilteredCompanies().length === 0 ? (
                  <CircularProgress size={20} />
                ) : (
                  <ArrowDropDown />
                )}
              </InputAdornment>
            ),
          }}
          disabled={disabled}
          error={error}
          onClick={(e) => {
            if (disabled) return;
            setAnchorEl(e.currentTarget);
          }}
          placeholder={placeholder}
          ref={ref}
          value={renderValue()}
          variant="outlined"
        />
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          onClose={() => setAnchorEl(null)}
          open={Boolean(anchorEl)}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
        >
          <div className="flex items-center gap-8 px-8 sticky top-0 bg-white py-2 pt-8 z-10">
            <SearchIcon className="w-20 h-20 ml-2" />
            <div className="flex-1 relative">
              <CustomCombobox
                InputProps={{
                  disableUnderline: true,
                  endAdornment: searchTerm ? (
                    <InputAdornment position="end">
                      <button
                        className="p-4 rounded-full hover:bg-gray-100 transition-colors"
                        onClick={() => {
                          setSearchTerm("");
                          setExpandedCompanies({});
                        }}
                        type="button"
                      >
                        <XMarkIcon className="w-16 h-16 text-gray-500" />
                      </button>
                    </InputAdornment>
                  ) : null,
                }}
                fullWidth
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  if (e.target.value) {
                    const searchLower = e.target.value.toLowerCase();
                    const newExpandedState = { ...expandedCompanies };

                    companies.forEach((company) => {
                      const schoolsList = companySchools[company.id] || [];
                      if (
                        schoolsList.some((school) =>
                          school.name.toLowerCase().includes(searchLower)
                        )
                      ) {
                        newExpandedState[company.id] = true;
                      }
                    });

                    setExpandedCompanies(newExpandedState);
                  }
                }}
                placeholder="Search"
                value={searchTerm}
                variant="standard"
              />
            </div>
          </div>
          <div className="max-h-[400px] overflow-auto">
            {getFilteredCompanies().length === 0 ? (
              <div className="flex justify-center items-center p-24">
                <CircularProgress />
              </div>
            ) : (
              getFilteredCompanies().map((company) => {
                const companySchoolsList = companySchools[company.id] || [];
                const isCompanySelected = value.includes(company.id);
                const hasSelectedSchools = companySchoolsList.some((school) =>
                  value.includes(school.id)
                );
                const companyHasSchools = companySchoolsList.length > 0;

                return companyHasSchools ? (
                  <div key={company.id}>
                    <MenuItem
                      className="pl-16 font-semibold"
                      onClick={() =>
                        allowTopLevelSelect
                          ? handleSelect(company, true)
                          : setExpandedCompanies((prev) => ({
                              ...prev,
                              [company.id]: !prev[company.id],
                            }))
                      }
                    >
                      {allowTopLevelSelect ? (
                        <Checkbox
                          checked={isCompanySelected}
                          color="primary"
                          indeterminate={
                            !isCompanySelected ? hasSelectedSchools : null
                          }
                          size="small"
                        />
                      ) : null}
                      <span
                        className={`${
                          hasSelectedSchools ? "font-black" : " font-semibold"
                        }`}
                      >
                        {company.name}
                      </span>
                      {companySchoolsList.length > 0 ? (
                        <button
                          className="ml-auto p-8 rounded-full hover:bg-gray-100 transition-colors"
                          onClick={(e) => {
                            e.stopPropagation();
                            setExpandedCompanies((prev) => ({
                              ...prev,
                              [company.id]: !prev[company.id],
                            }));
                          }}
                          type="button"
                        >
                          <ChevronDownIcon
                            className={`w-20 h-20 transition-transform duration-200 ${
                              expandedCompanies[company.id]
                                ? "scale-y-[-1]"
                                : ""
                            }`}
                          />
                        </button>
                      ) : null}
                    </MenuItem>
                    {expandedCompanies[company.id]
                      ? companySchoolsList.map((school) => {
                          console.log("school", school);
                          return (
                            <MenuItem
                              className="pl-32"
                              disabled={lockedSchools.includes(school.id)}
                              key={school.id}
                              onClick={() => handleSelect(school, false)}
                            >
                              <span className="text-gray-500 mr-2">-</span>
                              <Checkbox
                                checked={
                                  isCompanySelected || value.includes(school.id)
                                }
                                color={
                                  isCompanySelected ? "default" : "primary"
                                }
                                size="small"
                                style={{
                                  color: isCompanySelected
                                    ? "#9e9e9e"
                                    : undefined,
                                }}
                              />
                              <span
                                className={
                                  isCompanySelected
                                    ? "text-gray-500 ml-2"
                                    : "ml-2"
                                }
                              >
                                {school.name}
                              </span>
                              {isCompanySelected ? (
                                <span className="ml-2 text-sm text-gray-500 italic">
                                  (included with company)
                                </span>
                              ) : null}
                            </MenuItem>
                          );
                        })
                      : null}
                  </div>
                ) : null;
              })
            )}
          </div>
        </Popover>
        {error ? (
          <FormHelperText>
            {showErrorIcon ? <Error className="w-20 mr-2" /> : null}
            {helperText}
          </FormHelperText>
        ) : null}
      </StyledFormControl>
    );
  }
);

HierarchicalSearchSelect.displayName = "HierarchicalSearchSelect";

export default HierarchicalSearchSelect;
