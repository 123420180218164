import * as React from 'react';
const CsvIcon = (props) => (
	<svg fill="none" height={24} width={24} xmlns="http://www.w3.org/2000/svg" {...props}>
		<g filter="url(#csv-icon-a)">
			<path
				clipRule="evenodd"
				d="M5.233 2C4.497 2 3.9 2.597 3.9 3.333v17.334c0 .736.597 1.333 1.333 1.333h13.534c.736 0 1.333-.597 1.333-1.333V7l-5-5H5.233Z"
				fill="#72BF44"
				fillRule="evenodd"
			/>
		</g>
		<path d="M19.63 6.531H15.57l4.531 4.532V7l-.47-.469Z" fill="url(#csv-icon-b)" />
		<path d="M16.433 7H20.1l-5-5v3.667c0 .736.597 1.333 1.333 1.333Z" fill="#C7E5B4" />
		<path
			d="M7.038 15.1a.332.332 0 0 1-.241-.1.332.332 0 0 1-.1-.242c0-.092.033-.172.1-.238a.332.332 0 0 1 .241-.1c.091 0 .17.034.239.1a.321.321 0 0 1 .102.239.32.32 0 0 1-.048.171.368.368 0 0 1-.124.124.325.325 0 0 1-.169.045Zm3.416-1.928h-.622a.608.608 0 0 0-.07-.215.545.545 0 0 0-.325-.264.727.727 0 0 0-.231-.035.69.69 0 0 0-.394.112.716.716 0 0 0-.26.324 1.283 1.283 0 0 0-.092.514c0 .207.03.382.092.523.063.14.15.247.261.32a.7.7 0 0 0 .388.107.77.77 0 0 0 .229-.032.548.548 0 0 0 .325-.25.604.604 0 0 0 .077-.208l.622.003a1.164 1.164 0 0 1-.761.947 1.411 1.411 0 0 1-.502.084c-.26 0-.491-.058-.696-.176a1.25 1.25 0 0 1-.483-.51 1.713 1.713 0 0 1-.176-.808c0-.317.06-.587.18-.81.118-.222.28-.392.485-.508a1.36 1.36 0 0 1 .69-.176c.169 0 .325.023.47.07.144.048.272.117.384.208.112.09.203.2.273.331.07.13.116.28.136.449Zm2.02-.182a.36.36 0 0 0-.147-.267.58.58 0 0 0-.35-.095c-.101 0-.186.014-.255.043a.357.357 0 0 0-.16.115.277.277 0 0 0-.053.167.24.24 0 0 0 .032.136.34.34 0 0 0 .101.101.764.764 0 0 0 .148.073c.056.02.116.037.179.05l.261.063c.127.029.244.067.35.114.106.047.198.106.275.175a.726.726 0 0 1 .247.567.804.804 0 0 1-.136.463.866.866 0 0 1-.386.302c-.168.071-.37.107-.607.107a1.6 1.6 0 0 1-.613-.108.898.898 0 0 1-.407-.32.955.955 0 0 1-.152-.527h.596a.446.446 0 0 0 .083.245c.05.064.117.113.2.146.085.032.18.048.286.048a.737.737 0 0 0 .272-.045.416.416 0 0 0 .18-.127.292.292 0 0 0 .064-.186c0-.065-.02-.12-.058-.165a.442.442 0 0 0-.168-.113 1.703 1.703 0 0 0-.268-.085l-.317-.08a1.328 1.328 0 0 1-.581-.28.65.65 0 0 1-.212-.513c0-.176.046-.33.14-.461a.949.949 0 0 1 .394-.309 1.39 1.39 0 0 1 .568-.11c.216 0 .405.037.566.11a.895.895 0 0 1 .378.309c.09.131.136.284.139.457h-.59Zm1.568-.837.703 2.21h.027l.704-2.21h.682l-1.003 2.91h-.792l-1.004-2.91h.683Z"
			fill="#fff"
		/>
		<defs>
			<linearGradient gradientUnits="userSpaceOnUse" id="csv-icon-b" x1={16.584} x2={21.116} y1={5.516} y2={10.047}>
				<stop stopOpacity={0.2} />
				<stop offset={1} stopOpacity={0} />
			</linearGradient>
			<filter
				colorInterpolationFilters="sRGB"
				filterUnits="userSpaceOnUse"
				height={20.167}
				id="csv-icon-a"
				width={16.2}
				x={3.9}
				y={1.917}
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
				<feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
				<feOffset dy={-0.167} />
				<feGaussianBlur stdDeviation={0.042} />
				<feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
				<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
				<feBlend in2="shape" result="effect1_innerShadow_196_96" />
				<feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
				<feOffset dy={0.167} />
				<feGaussianBlur stdDeviation={0.042} />
				<feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
				<feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.3 0" />
				<feBlend in2="effect1_innerShadow_196_96" result="effect2_innerShadow_196_96" />
			</filter>
		</defs>
	</svg>
);
export default CsvIcon;
