/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useRef } from 'react';
import history from '@history';
import {
	Table,
	TableHead,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Paper,
	CircularProgress,
	IconButton,
	Avatar,
	InputLabel,
	Select,
	MenuItem,
	FormControl,
	Typography,
	TextField,
	InputAdornment,
} from '@material-ui/core';
import FuseAnimate from '@fuse/core/FuseAnimate';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from 'app/store/actions';
import CustomButton from 'app/customComponents/CustomButton/CustomButton';
import './Reports.css';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import CustomDatePicker from '@shared/customComponents/CustomDatePicker/CustomDatePicker';
import { getStateListByCountry, getCityList } from 'app/services/Company/companyService';
import { getRevenueInfo, getSchoolsList } from 'app/services/reports/reports';
import { Close } from '@material-ui/icons';
import { useReactToPrint } from 'react-to-print';
import InfiniteScroll from 'react-infinite-scroll-component';
import dayjs from 'dayjs';
import { generateAgeString } from 'utils/utils';

function RevenueReport() {
	const dispatch = useDispatch();
	const user = useSelector(({ auth }) => auth.user);
	const [rows, setRows] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [firstLoad, setFirstLoad] = useState(true);
	const [fetchingMore, setFetchingMore] = useState(false);
	const [hasMore, setHasMore] = useState(false);
	const revenueReport = useRef(null);
	const [page, setPage] = useState(1);
	const [filters, setFilters] = useState({
		school_id: '',
		name: '',
		state_id: '',
		city: '',
		revenue_id: 1,
		date_from: '',
		date_to: '',
	});
	const [states, setStates] = useState([]);
	const [schools, setschool] = useState([]);
	const date = new Date();
	const [isLoadingExport, setIsLoadingExport] = useState(false);
	const [city, setCity] = useState([]);

	useEffect(() => {
		let isMounted = true;
		getSchoolsList()
			.then((res) => {
				if (!isMounted) return;
				setschool(res.data);
			})
			.catch((err) => {
				if (!isMounted) return;
				dispatch(
					Actions.showMessage({
						message: 'Failed to get schools.',
						autoHideDuration: 1500,
						variant: 'error',
					}),
				);
			});

		getStateListByCountry('USA')
			.then((res) => {
				if (!isMounted) return;
				setStates(res.data);
			})
			.catch((err) => {
				if (!isMounted) return;
				dispatch(
					Actions.showMessage({
						message: 'Failed to get states.',
						autoHideDuration: 1500,
						variant: 'error',
					}),
				);
			});
		return () => {
			isMounted = false;
		};
	}, [dispatch]);

	useEffect(() => {
		let isMounted = true;
		if (filters.state_id !== '') {
			getCityList(filters.state_id).then((res) => {
				if (!isMounted) return;
				setCity(res.data);
			});
		}
		return () => {
			isMounted = false;
		};
	}, [filters.state_id]);

	const handleLoadMore = () => {
		setFetchingMore(true);
		getRevenueInfo(
			0,
			filters.name,
			filters.school_id === 'All' ? '' : filters.school_id,
			filters.state_id,
			filters.city,
			filters.date_from,
			filters.date_to,
			filters.revenue_id,
			page,
		)
			.then((res) => {
				if (res.data.last_page > res.data.current_page) {
					setHasMore(true);
				} else {
					setHasMore(false);
				}
				setPage(res.data.current_page + 1);
				setRows(rows.concat(res.data.data));
				setFetchingMore(false);
			})
			.catch((err) => {
				setFetchingMore(false);
				dispatch(
					Actions.showMessage({
						message: 'Failed to fetch data, please refresh',
						variant: 'error',
					}),
				);
			});
	};

	const handleExport = () => {
		setIsLoadingExport(true);
		getRevenueInfo(
			1,
			filters.name,
			filters.school_id === 'All' ? '' : filters.school_id,
			filters.state_id,
			filters.city,
			filters.date_from,
			filters.date_to,
			filters.revenue_id,
		)
			.then((res) => {
				const blob = new Blob([res.data], {
					type: 'text/csv',
				});
				const link = document.createElement('a');
				link.setAttribute('target', '_blank');
				link.href = window.URL.createObjectURL(blob);
				link.setAttribute('download', `Revenue_report_${new Date().getTime()}.csv`);
				document.body.appendChild(link);
				link.click();
				// Clean up and remove the link
				link.parentNode.removeChild(link);
				setIsLoadingExport(false);
			})
			.catch((err) => {
				dispatch(
					Actions.showMessage({
						message: 'Failed to Export',
						variant: 'error',
					}),
				);
				setIsLoadingExport(false);
			})
			.finally(() => {
				setIsLoadingExport(false);
			});
	};

	const ApplyFilters = () => {
		setRows([]);
		setIsLoading(true);
		setFirstLoad(false);
		const timeout = setTimeout(
			() => {
				getRevenueInfo(
					0,
					filters.name,
					filters.school_id === 'All' ? '' : filters.school_id,
					filters.state_id,
					filters.city,
					filters.date_from,
					filters.date_to,
					filters.revenue_id,
					1,
				)
					.then((res) => {
						setFirstLoad(false);
						setRows(res.data.data);
						if (res.data.last_page > res.data.current_page) {
							setHasMore(true);
						} else {
							setHasMore(false);
						}
						setPage(res.data.current_page + 1);
						dispatch(
							Actions.showMessage({
								message: 'Report has been generated',
								variant: 'success',
							}),
						);
					})
					.catch((err) => {
						dispatch(
							Actions.showMessage({
								message: 'Failed to fetch data, please refresh',
								variant: 'error',
							}),
						);
					})
					.finally(() => {
						setIsLoading(false);
					});
			},
			firstLoad ? 0 : 1000,
		);
		setPage(1);
		return () => {
			clearTimeout(timeout);
		};
	};

	const handlePrint = useReactToPrint({
		content: () => revenueReport.current,
	});

	const handleFilters = (ev) => {
		const { name, value } = ev.target;
		setFilters({ ...filters, [name]: value });
	};

	const handleClick = (row) => {
		console.log(row);
		dispatch(
			Actions.openDialog({
				children: (
					<div className="bg-white student-list-card">
						<div className="flex justify-between student-list-header">
							<div>
								<h1>Students</h1>
							</div>
							<div>
								<i
									style={{ cursor: 'pointer' }}
									className="fas fa-times"
									onClick={() => dispatch(Actions.closeDialog())}
								/>
							</div>
						</div>
						<div id="scrollable-list" className="student-list-cont w-full">
							{row?.parent?.family_child
								?.filter((e) => e.school_id === row.school_id)
								?.slice(1)
								.map((child, i) => (
									<div className="flex items-center " style={{ padding: 14 }} key={i}>
										<Avatar className="mr-6" alt="student-face" src={child.thumb} />
										<div className="flex flex-col items-start">
											<div className="student-name truncate break-all">
												{child.first_name} {child.last_name}
											</div>
										</div>
									</div>
								))}
						</div>
					</div>
				),
			}),
		);
	};

	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	});

	return (
		<FuseAnimate animation="transition.slideLeftIn" duration={600}>
			<div className="mx-auto enrolled-cont">
				<div className="flex items-center justify-between">
					<div className="reports-topDiv">
						<h1 className="">
							<span className="">
								<IconButton
									onClick={() => {
										history.goBack();
									}}
								>
									<img
										src="assets/images/arrow-long.png"
										alt="filter"
										width="24px"
										className="backBtn-img"
									/>
								</IconButton>
							</span>
							<span className="text-pd-xl self-end font-bold mr-28">Revenue Report</span>
						</h1>
						<p>Detailed data of revenue information</p>
					</div>
					<div className="flex justify-between">
						<div className="flex items-center justify-between">
							<div className="">
								<span>
									{!isLoadingExport ? (
										<CustomButton
											onClick={handleExport}
											variant="primary"
											height="40px"
											width="100px"
											marginRight="17px"
										>
											<span className="mr-4">
												<FontAwesomeIcon icon={faDownload} />
											</span>
											Export
										</CustomButton>
									) : (
										<div className="circle-bar">
											<CircularProgress size={35} />
										</div>
									)}
									<CustomButton
										onClick={() => handlePrint()}
										variant="secondary"
										height="40px"
										width="100px"
									>
										<i className="fa fa-print" aria-hidden="true" /> Print
									</CustomButton>
								</span>
							</div>
						</div>
					</div>
				</div>

				<div className="flex items-center flex-nowrap justify-between">
					<span className="text-pd-2xl self-end font-extrabold mr-28" />
					<div className="flex justify-between">
						<div className="flex">
							<div className="mx-8">
								<TextField
									className="mr-10"
									onChange={handleFilters}
									id="search-input"
									name="name"
									value={filters.name}
									label="Search by Parent"
									style={{ width: 154 }}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													id="search-icon"
													onClick={() => {
														document.getElementById('search-input').focus();
													}}
												>
													<img
														alt="search-icon"
														src="assets/images/search-icon.svg"
														height="80%"
														width="80%"
													/>
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</div>
							<div className="mx-8">
								<FormControl>
									<InputLabel id="roomLabel">School</InputLabel>
									<Select
										name="school_id"
										onChange={handleFilters}
										value={filters.school_id}
										labelId="school_id"
										id="school_id"
										label="School"
										style={{ width: 110 }}
										endAdornment={
											filters.school_id ? (
												<IconButton
													id="clear-room-filter"
													size="small"
													className="mr-16"
													onClick={() => {
														setFilters({
															...filters,
															school_id: '',
														});
													}}
												>
													<Close fontSize="small" />
												</IconButton>
											) : (
												''
											)
										}
									>
										<MenuItem value="All">All</MenuItem>
										{schools.length ? (
											schools.map((school) => {
												return (
													<MenuItem key={school.id} value={school.id} id={school.id}>
														{school.name}
													</MenuItem>
												);
											})
										) : (
											<MenuItem disabled>Loading...</MenuItem>
										)}
									</Select>
								</FormControl>
							</div>
							<div className="mx-8">
								<FormControl>
									<InputLabel id="roomLabel">State</InputLabel>
									<Select
										name="state_id"
										onChange={handleFilters}
										value={filters.state_id}
										labelId="roomLabel"
										id="state"
										label="Filter by State"
										style={{ width: 100 }}
										endAdornment={
											filters.state_id ? (
												<IconButton
													size="small"
													className="mr-16"
													onClick={() =>
														setFilters({
															...filters,
															state_id: '',
															city: '',
														})
													}
												>
													<Close fontSize="small" />
												</IconButton>
											) : (
												''
											)
										}
									>
										{states.length ? (
											states.map((state) => {
												return (
													<MenuItem key={state.id} value={state.id}>
														{state.name}
													</MenuItem>
												);
											})
										) : (
											<MenuItem disabled>Loading...</MenuItem>
										)}
									</Select>
								</FormControl>
							</div>
							<div className="mx-8">
								<FormControl>
									<InputLabel id="cityLabel">City</InputLabel>
									<Select
										name="city"
										onChange={handleFilters}
										value={filters.city}
										labelId="cityLabel"
										id="city"
										label="City"
										style={{ width: 100 }}
										endAdornment={
											filters.city ? (
												<IconButton size="small" className="mr-16">
													<Close
														onClick={() =>
															setFilters({
																...filters,
																city: '',
															})
														}
														fontSize="small"
													/>
												</IconButton>
											) : (
												''
											)
										}
									>
										{!filters.state_id ? (
											<MenuItem disabled value="">
												<span>Select State</span>
											</MenuItem>
										) : city.length > 0 ? (
											city?.map((city) => {
												return (
													<MenuItem key={city.id} value={city.name}>
														<span>{city.name}</span>
													</MenuItem>
												);
											})
										) : (
											<MenuItem disabled value="">
												{!city.length ? <span>No City Found</span> : <span>Loading...</span>}
											</MenuItem>
										)}
									</Select>
								</FormControl>
							</div>
							<div className="mx-10 student-date-field" style={{ width: 130 }}>
								<CustomDatePicker
									id="date-from"
									label="Date From"
									value={filters.date_from}
									setValue={(Date) => {
										setFilters({ ...filters, date_from: Date?.format('YYYY-MM-DD') || '' });
									}}
									maxDate={filters.date_to || undefined}
									disableFuture
								/>
							</div>
							<div className="mr-20 ml-10 student-date-field" style={{ width: 130 }}>
								<CustomDatePicker
									id="date-to"
									label="Date To"
									value={filters.date_to}
									setValue={(Date) => {
										setFilters({ ...filters, date_to: Date?.format('YYYY-MM-DD') || '' });
									}}
									minDate={filters.date_from || undefined}
									disableFuture
								/>
							</div>
						</div>

						<div className="self-end">
							<span>
								<span className="mx-4">
									<CustomButton
										variant="secondary"
										height="43"
										width="140px"
										fontSize="15px"
										disabled={
											!filters.name &&
											!filters.school_id &&
											!filters.state_id &&
											!filters.city &&
											// !filters.package_type &&
											!filters.date_from &&
											!filters.date_to
										}
										onClick={() => {
											ApplyFilters();
										}}
									>
										Apply
									</CustomButton>
								</span>
							</span>
						</div>
					</div>
				</div>

				{/* table  */}
				<TableContainer id="Scrollable-table" component={Paper} className="student-table-cont">
					<div style={{ display: 'none' }}>
						<div ref={revenueReport} className="p-32">
							<div className="flex flex-row justify-between">
								<div>
									<img src="assets/images/logos/logo22.png" alt="" />
								</div>
								<div style={{ textAlign: 'right' }}>
									<Typography variant="subtitle1">
										<span style={{ display: 'block', marginBottom: '5px', writingMode: '' }}>
											{user?.data?.school?.address}
										</span>
									</Typography>
									<Typography variant="subtitle1">{user?.data?.phone}</Typography>
									<Typography variant="subtitle1">{user?.data?.email}</Typography>
								</div>
							</div>
							<div style={{ marginBottom: '20px' }}>
								<span>
									<strong>Date: </strong>
								</span>
								<span>{moment(date).format('dddd, DD MMMM YYYY')}</span>
							</div>
							<div className="pdf-heading">
								<h1 className="font-extrabold">Revenue Report</h1>
							</div>
							<Table stickyHeader style={{ width: '100%' }}>
								<TableHead>
									<TableRow>
										<TableCell
											style={{ marginLeft: 20 }}
											className="bg-white revenueTableHeader-print"
										>
											Parent
										</TableCell>
										<TableCell style={{}} className="bg-white revenueTableHeader-print">
											Students
										</TableCell>
										<TableCell style={{}} className="bg-white revenueTableHeader-print">
											Package
										</TableCell>
										<TableCell style={{}} className="bg-white revenueTableHeader-print">
											Amount
										</TableCell>
										<TableCell style={{}} className="bg-white revenueTableHeader-print">
											Transaction ID
										</TableCell>
										<TableCell style={{}} className="bg-white revenueTableHeader-print">
											Gateway
										</TableCell>
										<TableCell style={{}} className="bg-white revenueTableHeader-print">
											Gateway Fee
										</TableCell>
										<TableCell style={{}} className="bg-white revenueTableHeader-print">
											Royalty
										</TableCell>
										<TableCell style={{}} className="bg-white revenueTableHeader-print">
											Revenue
										</TableCell>
										<TableCell style={{}} className="bg-white revenueTableHeader-print">
											Date
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody className="">
									{firstLoad ? (
										<TableRow>
											<TableCell align="center" colSpan={8} style={{ fontSize: 'smaller' }}>
												Generate your report
											</TableCell>
										</TableRow>
									) : isLoading ? (
										<TableRow>
											<TableCell align="center" colSpan={8}>
												<CircularProgress size={35} />
											</TableCell>
										</TableRow>
									) : !rows.length && !firstLoad ? (
										<TableRow>
											<TableCell align="center" colSpan={8}>
												No Record Found
											</TableCell>
										</TableRow>
									) : (
										rows?.map((row) => {
											return (
												<TableRow key={row?.id}>
													<TableCell style={{ fontWeight: 700 }} component="th" scope="row">
														<div id={`parent-${row?.id}`} className="flex items-center">
															<Avatar
																className="mr-6"
																alt="student-face"
																src={row?.parent?.thumb}
															/>
															<div className="flex flex-col">
																<div className="revenue-parent-name-print break-word break-word">
																	{row?.parent?.first_name} {row?.parent?.last_name}
																</div>
																<div className="student-age-font break-all">
																	{row?.parent?.email}
																</div>
															</div>
														</div>
													</TableCell>
													<TableCell style={{ fontWeight: 700 }}>
														<div className="flex">
															{row?.parent?.family_child
																?.filter((e) => e.school_id === row.school_id)
																?.map((child, index) => {
																	return (
																		<div
																			className="flex cursor-pointer mr-10"
																			key={child.id}
																		>
																			<Avatar
																				className="mr-6"
																				alt="student-face"
																				src={child?.thumb}
																			/>
																			<div className="flex flex-col items-start">
																				<div className="revenue-student-homeroom break-word">
																					{child?.home_room?.name}
																				</div>
																				<div className="revenue-student-name break-word">
																					{`${child?.first_name} ${child?.last_name}`}{' '}
																				</div>
																				<div
																					className="font-normal revenue-student-age-font "
																					style={{ width: '90px' }}
																				>
																					{generateAgeString(
																						child?.date_of_birth,
																					)}
																				</div>
																			</div>
																		</div>
																	);
																})
																.splice(0, 1)}
														</div>
													</TableCell>
													<TableCell>
														<div className="package-details">
															{row.package?.title
																? row.package?.title.split('Subscription')
																: '-'}
														</div>
													</TableCell>
													<TableCell>
														<div className="package-details">
															$
															{row?.transaction?.original_amount
																? row?.transaction?.original_amount
																: '0.00'}
														</div>
													</TableCell>
													<TableCell>
														<div className="package-details break-all">
															{row?.stripe_id ? row?.stripe_id : 'N/A'}
														</div>
													</TableCell>
													<TableCell>
														<div className="package-details">
															{row?.provider === 'stripe'
																? 'Stripe'
																: row.provider === 'in_app_ios'
																	? 'In App'
																	: 'N/A'}
														</div>
													</TableCell>
													<TableCell>
														<div className="package-details">
															$
															{row?.transaction?.service_charge_amount
																? row?.transaction?.service_charge_amount
																: '0.00'}
														</div>
													</TableCell>
													<TableCell>
														<div className="package-details">
															$
															{row?.transaction?.school_amount
																? row?.transaction?.school_amount
																: '0.00'}
														</div>
													</TableCell>
													<TableCell>
														<div className="package-details">
															$
															{row?.transaction?.po_amount
																? row?.transaction?.po_amount
																: '0.00'}
														</div>
													</TableCell>
													<TableCell>
														<div className="flex flex-col">
															<div className="revenue-subscribe-date">
																{moment.utc(row?.created_at).local().format('L')}
															</div>
															<div className="subscribe-time">
																{moment.utc(row?.created_at).local().format('LT')}
															</div>
														</div>
													</TableCell>
												</TableRow>
											);
										})
									)}
									{fetchingMore ? (
										<TableRow>
											<TableCell align="center" colSpan={8}>
												<CircularProgress size={35} />
											</TableCell>
										</TableRow>
									) : (
										<></>
									)}
								</TableBody>
							</Table>
						</div>
					</div>
					<Table stickyHeader className="enrolledtudent-table" style={{ width: '100%' }}>
						<TableHead>
							<TableRow>
								<TableCell
									style={{ marginLeft: 20, width: '13%' }}
									className="bg-white revenueTableHeader-print"
								>
									Parent
								</TableCell>
								<TableCell style={{}} className="bg-white revenueTableHeader-print">
									Students
								</TableCell>
								<TableCell className="bg-white revenueTableHeader-print"></TableCell>
								<TableCell style={{}} className="bg-white revenueTableHeader-print">
									Package
								</TableCell>
								<TableCell style={{}} className="bg-white revenueTableHeader-print">
									Amount
								</TableCell>
								<TableCell style={{}} className="bg-white revenueTableHeader-print">
									Transaction ID
								</TableCell>
								<TableCell style={{}} className="bg-white revenueTableHeader-print">
									Gateway
								</TableCell>
								<TableCell style={{}} className="bg-white revenueTableHeader-print">
									Gateway Fee
								</TableCell>
								<TableCell style={{}} className="bg-white revenueTableHeader-print">
									Royalty
								</TableCell>
								<TableCell style={{}} className="bg-white revenueTableHeader-print">
									Revenue
								</TableCell>
								<TableCell style={{}} className="bg-white revenueTableHeader-print">
									Date
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody className="">
							{firstLoad ? (
								<TableRow>
									<TableCell
										align="center"
										colSpan={11}
										style={{ fontSize: 'smaller', color: '#06071d' }}
									>
										Generate your report
									</TableCell>
								</TableRow>
							) : isLoading ? (
								<TableRow>
									<TableCell align="center" colSpan={11}>
										<CircularProgress size={35} />
									</TableCell>
								</TableRow>
							) : !rows.length && !firstLoad ? (
								<TableRow>
									<TableCell align="center" colSpan={11}>
										No Record Found
									</TableCell>
								</TableRow>
							) : (
								rows?.map((row) => {
									return (
										<TableRow key={row?.id}>
											<TableCell style={{ fontWeight: 700 }} component="th" scope="row">
												<div id={`parent-${row?.id}`} className="flex items-center">
													<Avatar
														className="mr-6"
														alt="student-face"
														src={row?.parent?.thumb}
													/>
													<div className="flex flex-col">
														<div className="revenue-parent-name break-word">
															{row?.parent?.first_name} {row?.parent?.last_name}
														</div>
														<div className="student-age-font break-all">
															{row?.parent?.email}
														</div>
													</div>
												</div>
											</TableCell>
											<TableCell style={{ fontWeight: 700 }}>
												<div className="flex">
													{row?.parent?.family_child
														?.filter((e) => e.school_id === row.school_id)
														?.map((child, index) => {
															return (
																<div
																	className="flex cursor-pointer mr-10"
																	key={child.id}
																>
																	<Avatar
																		className="mr-6"
																		alt="student-face"
																		src={child?.thumb}
																	/>
																	<div className="flex flex-col items-start">
																		<div className="revenue-student-homeroom break-word">
																			{child?.home_room?.name}
																		</div>
																		<div className="revenue-student-name break-word">
																			{`${child?.first_name} ${child?.last_name}`}{' '}
																		</div>
																		<div
																			className="font-normal revenue-student-age-font "
																			style={{ width: '90px' }}
																		>
																			{generateAgeString(child?.date_of_birth)}
																		</div>
																	</div>
																</div>
															);
														})
														.splice(0, 1)}
												</div>
											</TableCell>
											<TableCell style={{ padding: 0 }}>
												<div>
													{row?.parent?.family_child?.filter(
														(e) => e.school_id === row.school_id,
													)?.length > 2 ? (
														<>
															<div
																onClick={() => handleClick(row)}
																style={{
																	cursor: 'pointer',
																	fontSize: '11px',
																	color: '#008dff',
																	fontWeight: 700,
																	marginLeft: -12,
																}}
															>
																{' '}
																+
																{`${
																	row?.parent?.family_child?.filter(
																		(e) => e.school_id === row.school_id,
																	)?.length - 1
																} Others`}
															</div>
														</>
													) : (
														''
													)}
												</div>
											</TableCell>
											<TableCell>
												<div className="package-details">
													{row.package?.title
														? row.package?.title.split('Subscription')
														: '-'}
												</div>
											</TableCell>
											<TableCell>
												<div className="package-details">
													$
													{row?.transaction?.original_amount
														? row?.transaction?.original_amount
														: '0.00'}
												</div>
											</TableCell>
											<TableCell>
												<div className="package-details break-all">
													{row?.stripe_id ? row?.stripe_id : 'N/A'}
												</div>
											</TableCell>
											<TableCell>
												<div className="package-details">
													{row?.provider === 'stripe'
														? 'Stripe'
														: row.provider === 'in_app_ios'
															? 'In App'
															: 'N/A'}
												</div>
											</TableCell>
											<TableCell>
												<div className="package-details">
													$
													{row?.transaction?.service_charge_amount
														? row?.transaction?.service_charge_amount
														: '0.00'}
												</div>
											</TableCell>
											<TableCell>
												<div className="package-details">
													$
													{row?.transaction?.school_amount
														? row?.transaction?.school_amount
														: '0.00'}
												</div>
											</TableCell>
											<TableCell>
												<div className="package-details">
													$
													{row?.transaction?.po_amount ? row?.transaction?.po_amount : '0.00'}
												</div>
											</TableCell>
											<TableCell>
												<div className="flex flex-col">
													<div className="revenue-subscribe-date">
														{moment.utc(row?.created_at).local().format('L')}
													</div>
													<div className="subscribe-time">
														{moment.utc(row?.created_at).local().format('LT')}
													</div>
												</div>
											</TableCell>
										</TableRow>
									);
								})
							)}
							{fetchingMore ? (
								<TableRow>
									<TableCell align="center" colSpan={11}>
										<CircularProgress size={35} />
									</TableCell>
								</TableRow>
							) : (
								<></>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<InfiniteScroll
					dataLength={rows?.length}
					next={handleLoadMore}
					hasMore={hasMore}
					scrollableTarget="Scrollable-table"
				/>
			</div>
		</FuseAnimate>
	);
}

export default RevenueReport;
