import history from '@/@history';
// import { getParentSubscriptionReports, getParentSubscriptionStats } from '@/app/services/reports/reports';
import * as Actions from '@/app/store/actions';
import html2pdf from 'html2pdf.js';
import moment from 'moment-timezone';
import Pusher from 'pusher-js';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import secureLocalStorage from 'react-secure-storage';
import { useReactToPrint } from 'react-to-print';
import AppliedFiltersBar from '@shared/Reports/AppliedFiltersBar';
import ReportsHeader from '@shared/Reports/ReportsHeader';
import SubscriptionTransactionFiltersBar from './SubTransFiltersBar';
import SubscriptionTransactionPrintViewTable from './SubTransPrintViewTable';
import SubscriptionTransactionTable from './SubTransTable';
import { getSubscriptionEvents } from '@/app/services/reports/reports';
import TransactionDetailsModal from './TransactionDetailsModal';
import { formatEventName } from './formatEventName';

const SubscriptionTransactionReports = () => {
	const [filters, setFilters] = useState({
		page: 1,
		start_date: null,
		end_date: null,
		platform: null,
		timezone: null,
		export: null,
		sort: 'student',
		dir: 'desc',
		parent_id: [],
	});
	const user = useSelector((state) => state.auth.user);
	const viewAsId = secureLocalStorage.getItem('view_as_id');
	const authToken = secureLocalStorage.getItem('jwt_access_token');
	const [loading, setLoading] = useState(false);
	const [printData, setPrintData] = useState([]);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [printDataLoading, setPrintDataLoading] = useState(false);
	const [pdfDataLoading, setPdfDataLoading] = useState(false);
	const printRef = useRef();
	const [rows, setRows] = useState([]);
	const [totalResults, setTotalResults] = useState(0);
	const dispatch = useDispatch();
	const cache = useRef({}); // Cache object to prevent refetching pages already fetched
	const prevFilters = useRef(filters); // Track previous filters to know when to refetch a set of results
	const [exportType, setExportType] = useState(null);
	const [exportLoading, setExportLoading] = useState(false);
	const [hasFilterApplied, setHasFilterApplied] = useState(false);
	const exportTimeoutRef = useRef(null);
	const [selectedTransaction, setSelectedTransaction] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);

	useEffect(() => {
		const pusher = new Pusher(import.meta.env.VITE_PUSHER_CHANNEL_ID, {
			cluster: import.meta.env.VITE_PUSHER_CLUSTER_ID,
			channelAuthorization: {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
				endpoint: `${import.meta.env.VITE_API_ENDPOINT}broadcasting/auth`,
			},
		});
		const channel = pusher.subscribe(
			`private-App.Models.User.${user.role[0] === 'super_school_admin' ? viewAsId : user.data?.id}`
		);

		channel.bind('report.export.complete', (res) => {
			const exportLink = JSON.parse(window.localStorage.getItem('export_link'));
			if (exportLink && exportLink === res.link) {
				setExportLoading(false);
				const anchor = document.createElement('a');
				anchor.href = res.link;
				document.body.appendChild(anchor);
				anchor.click();
				document.body.removeChild(anchor);
				window.localStorage.removeItem('export_link');
				clearTimeout(exportTimeoutRef.current); // Clear the timeout
			}
		});

		return () => {
			pusher.disconnect();
		};
	}, []);

	const handlePrint = useReactToPrint({
		content: () => printRef.current,
	});

	useEffect(() => {
		setFilters({
			...filters,
			timezone: user.data.school.timezone,
		});
	}, [user]);

	const generateQueryParams = (filters, exportType = null) => {
		return {
			page: filters.page,
			start_date: filters.start_date,
			end_date: filters.end_date,
			timezone: filters.timezone,
			email: filters?.parent_id[0]?.email,
			provider: filters?.platform?.value ?? null,
			transaction_id: filters.transaction_id,
			export: exportType,
			parent_id: filters?.parent_id[0]?.value,
			dir: filters.dir,
		};
	};

	// Helper function to update filters and reset page to 1 if any filter other than page changes
	const updateFilters = (newFilters) => {
		const filtersChanged = Object.keys(newFilters).some(
			(key) => key !== 'page' && newFilters[key] !== filters[key]
		);
		if (filtersChanged) {
			setFilters({ ...newFilters, page: 1 });
		} else {
			setFilters(newFilters);
		}
	};
	// checks if any actionable filters have changed
	const hasAtLeastOneFilter = (filters) => {
		// First check if both required filters are set
		const hasRequiredFilters =
			filters.platform && // platform is selected
			Array.isArray(filters.parent_id) &&
			filters.parent_id.length > 0; // parent_id is selected

		if (!hasRequiredFilters) {
			return false;
		}

		// Then check for any other filters
		return Object.keys(filters).some(
			(key) =>
				key !== 'timezone' &&
				key !== 'page' &&
				key !== 'sort' &&
				key !== 'dir' &&
				(Array.isArray(filters[key]) ? filters[key].length > 0 : filters[key])
		);
	};

	useEffect(() => {
		// Clear cache if any filter other than page changes
		const filtersChanged = Object.keys(filters).some(
			(key) => key !== 'page' && filters[key] !== prevFilters.current[key]
		);
		//  create a list of the filters than changed
		const changedFilters = Object.keys(filters).filter((key) => filters[key] !== prevFilters.current[key]);

		// clearing the date should update the results
		const dateCleared = changedFilters.includes('start_date') && changedFilters.includes('end_date');

		if (!dateCleared) {
			// if a date changed, but the other one is not set, we don't need to do anything until the other one is set
			if (changedFilters[0] === 'start_date' && !filters.end_date) {
				return;
			}
			if (changedFilters[0] === 'end_date' && !filters.start_date) {
				return;
			}
		}

		if (filtersChanged) {
			cache.current = {};
		}
		prevFilters.current = filters;

		const filterApplied = hasAtLeastOneFilter(filters);
		// update the state in here so the we can pass the most current hasFilterApplied state to the table
		setHasFilterApplied(filterApplied);

		if (filterApplied) {
			setLoading(true);
			const queryParams = generateQueryParams(filters);
			const cacheKey = JSON.stringify(queryParams);

			// Check if the results for the current page are already in the cache
			if (cache.current[cacheKey]) {
				const cachedData = cache.current[cacheKey];
				setRows(cachedData.data);
				setTotalResults(cachedData.total);
				setLoading(false);
			} else {
				getSubscriptionEvents(queryParams)
					.then((res) => {
						setRows(res.data.data);
						setTotalResults(res.data.meta.total);
						// Store the results in the cache
						cache.current[cacheKey] = {
							data: res.data.data,
							total: res.data.meta.total,
						};
					})
					.catch(() => {
						dispatch(
							Actions.showMessage({
								message: 'Failed to fetch report',
								variant: 'error',
							})
						);
						setRows([]);
						setTotalResults(0);
					})
					.finally(() => {
						setLoading(false);
					});
			}
		} else {
			// Clear data if no filters are applied
			setRows([]);
			setTotalResults(0);
		}
	}, [filters]);

	const handleExport = async (type) => {
		if (filters.platform && filters.parent_id.length) {
			const queryParams = generateQueryParams(filters, type);
			try {
				const res = await getSubscriptionEvents(queryParams);
				window.localStorage.setItem('export_link', JSON.stringify(res.data.link));
				setExportLoading(true);
				// Set a timeout to reset exportLoading if no response within 20 seconds
				exportTimeoutRef.current = setTimeout(() => {
					setExportLoading(false);
					dispatch(
						Actions.showMessage({
							message: 'Export failed to generate. Please try again.',
							variant: 'error',
						})
					);
				}, 20000);
			} catch (error) {
				dispatch(
					Actions.showMessage({
						message: 'Failed to export report',
						variant: 'error',
					})
				);
			}
		}
	};

	const handlePrintClick = async () => {
		setExportType('print');
		setPrintDataLoading(true);
		setPrintData([]);

		try {
			const queryParams = generateQueryParams(filters, 'print');
			const res = await getSubscriptionEvents(queryParams);
			setPrintData(res.data.data);
			handlePrint();
		} catch (error) {
			console.error('Error fetching all pages for print:', error);
		} finally {
			setPrintDataLoading(false);
		}
	};

	const handleDownloadPDF = async () => {
		setExportType('pdf');
		setPrintData([]);
		if (filters.platform && filters.parent_id.length) {
			setPdfDataLoading(true);
			try {
				// we set export to print because it fetches all pages
				// pdf is rendered in app
				const queryParams = generateQueryParams(filters, 'print');

				const res = await getSubscriptionEvents(queryParams);
				setPrintData(res.data.data);

				const element = printRef.current;
				const opt = {
					margin: 0.5,
					filename: 'SubscriptionTransactions.pdf',
					image: { type: 'jpeg', quality: 0.98 },
					html2canvas: { scale: 2 },
					jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
					pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
				};

				const style = document.createElement('style');
				style.innerHTML = `
				@media print {
					.page-break { page-break-before: always; }
					.avoid-page-break { page-break-inside: avoid; }
				}
			`;
				document.head.appendChild(style);

				html2pdf().from(element).set(opt).save();
			} catch (error) {
				console.error('Error fetching all pages for print:', error);
			} finally {
				setPdfDataLoading(false);
			}
		}
	};

	const capitalize = (str) => {
		if (!str) return '';
		return str.charAt(0).toUpperCase() + str.slice(1);
	};

	const handleOpenDetails = (transaction) => {
		setSelectedTransaction(transaction);
		setIsModalOpen(true);
	};

	const handleCloseDetails = () => {
		setIsModalOpen(false);
		setSelectedTransaction(null);
	};

	const columns = [
		{
			id: 'student',
			label: 'Student',
			render: (row) => {
				return (
					<div className="flex items-start gap-4 flex-col">
						{row.students.map((student) => (
							<div key={student.id}>
								{student.first_name} {student.last_name}
							</div>
						))}
					</div>
				);
			},
			printRender: (row) => `${capitalize(row.students[0].first_name)} ${capitalize(row.students[0].last_name)}`,
			widthClass: 'min-w-256',
		},

		{
			id: 'parent',
			label: 'Subscriber',
			render: (row) => (
				<div className="flex items-center gap-8">
					{row.parent.first_name} {row.parent.last_name}
				</div>
			),
			printRender: (row) => `${capitalize(row.parent.first_name)} ${capitalize(row.parent.last_name)}`,
			widthClass: 'min-w-200',
		},

		{
			id: 'email',
			label: 'Subscriber Email',

			render: (row) => {
				return row.parent.email;
			},
			printRender: (row) => row.parent.email,
			widthClass: 'min-w-200',
		},
		{
			id: 'platform',
			label: 'Platform',
			render: (row) => {
				const printValue = row.provider === 'ios' ? 'iOS' : capitalize(row.provider);
				return (
					<div className="flex items-center">
						<span>{printValue}</span>
					</div>
				);
			},
			printRender: (row) => capitalize(row.provider),
			widthClass: 'min-w-96',
		},
		{
			id: 'transaction_event',
			label: 'Event',
			render: (row) => (
				<div className="flex items-center">{formatEventName(row.event, row.provider || row.platform)}</div>
			),
			printRender: (row) => formatEventName(row.event, row.provider || row.platform),
			widthClass: 'min-w-128',
		},
		{
			id: 'process_date',
			label: 'Process Date',
			render: (row) => <span>{moment(row.date).format('MM/DD/YY HH:mm:ss')}</span>,
			printRender: (row) => moment(row.date).format('MM/DD/YY HH:mm:ss'),
			widthClass: 'min-w-128',
			align: 'right',
		},
		{
			id: 'details',
			label: 'Details',
			render: (row) => (
				<button
					className="text-blue-500 hover:underline cursor-pointer font-medium"
					onClick={() => handleOpenDetails(row)}
					type="button"
				>
					View Details
				</button>
			),
			printRender: () => null,
			widthClass: 'min-w-128',
			align: 'right',
		},
	];

	return (
		<div className="">
			<ReportsHeader
				exportLoading={exportLoading}
				exportsDisabled={!rows?.length}
				hideExport={true}
				onBack={() => history.goBack()}
				onDownloadPDF={handleDownloadPDF}
				onExport={handleExport}
				onPrint={handlePrintClick}
				pdfLoading={pdfDataLoading}
				printLoading={printDataLoading}
				subtitle="Track key details of subscribers, including subscription package types and subscription renewal dates."
				title="Subscription Transaction Report"
			/>
			<SubscriptionTransactionFiltersBar filters={filters} setFilters={updateFilters} />
			<AppliedFiltersBar filters={filters} reportType="subscription" setFilters={updateFilters} />
			<div className="bg-white">
				<div>
					{!filters.platform || !filters.parent_id.length ? (
						<div className="p-24 text-center text-gray-500">
							Please select both a platform and a subscriber to view results
						</div>
					) : (
						<SubscriptionTransactionTable
							columns={columns}
							data={rows}
							dir={filters.dir}
							filters={filters}
							hasFilterApplied={hasFilterApplied}
							loading={loading}
							page={filters.page}
							rowsPerPage={rowsPerPage}
							setDir={(dir) => updateFilters({ ...filters, dir })}
							setFilters={updateFilters}
							setPage={(page) => updateFilters({ ...filters, page })}
							setRowsPerPage={setRowsPerPage}
							setSort={(sort) => updateFilters({ ...filters, sort })}
							sort={filters.sort}
							totalResults={totalResults}
						/>
					)}
				</div>
				<div className="h-0 overflow-hidden">
					<SubscriptionTransactionPrintViewTable
						columns={columns}
						data={printData}
						exportType={exportType}
						filters={filters}
						ref={printRef}
						title="Subscription Report"
						user={user}
					/>
				</div>
			</div>
			<TransactionDetailsModal
				onClose={handleCloseDetails}
				open={isModalOpen}
				transaction={selectedTransaction}
			/>
		</div>
	);
};

export default SubscriptionTransactionReports;
