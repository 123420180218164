import React from "react";
import {
  FormControlLabel,
  Popover,
  TextField,
  withStyles,
} from "@material-ui/core";
import { useState } from "react";

import ChevronDownIcon from "../icons/ChevronDownIcon";
import SearchIcon from "../icons/SearchIcon";
import StyledCheckbox from "./StyledCheckbox";

const styles = {
  root: {
    border: "none",
  },
};

const CustomCombobox = withStyles(styles)(TextField);

const EmailPopoverCombobox = ({
  title,
  filters,
  setFilter,
  options,
  currentFilter,
  onSearch,
  loading,
  compact = false,
  customTriggerClass,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSearchTerm("");
  };

  const handleSelect = (option) => {
    // For single select, we just set the selected option directly
    setFilter({ ...filters, [currentFilter]: [option] });
  };

  const handleSearchChange = async (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (onSearch) {
      await onSearch(value);
    }
  };

  const filteredOptions = options?.filter((option) =>
    (option.label.toLowerCase() + option.email?.toLowerCase()).includes(
      searchTerm.toLowerCase()
    )
  );

  const highlightMatch = (text, searchTerm) => {
    if (!searchTerm) return text;

    const regex = new RegExp(`(${searchTerm})`, "gi");
    const parts = text.split(regex);

    return parts.map((part, i) =>
      regex.test(part) ? (
        <span className="!font-bold" key={i} style={{ fontWeight: "inherit" }}>
          {part}
        </span>
      ) : (
        <span className="" key={i}>
          {part}
        </span>
      )
    );
  };

  const selectedOption = filters[currentFilter]?.[0];

  return (
    <div>
      <button
        className={
          customTriggerClass ||
          `flex items-center gap-8 md:text-16 border border-transparent rounded-md p-4 ${
            compact ? "!text-14 text-[#374151] font-medium" : "text-16"
          }`
        }
        onClick={handleClick}
        type="button"
      >
        {title}
        {filters[currentFilter]?.length > 0 && !customTriggerClass ? (
          <span
            className={`text-10 text-black rounded h-16 w-16 flex items-center justify-center ${
              compact ? "bg-grey-300" : "bg-white"
            }`}
          >
            1
          </span>
        ) : null}
        <ChevronDownIcon
          className={`w-10 ${customTriggerClass ? "ml-auto" : ""}`}
        />
      </button>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        className="max-h-256"
        onClose={handleClose}
        open={Boolean(anchorEl)}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <div className="flex flex-col gap-0 my-0 p-8 pt-0 border-grey-200 rounded bg-white">
          <div className="flex items-center gap-8 px-8 sticky top-0 bg-white py-2 pt-8 z-10">
            <SearchIcon className="w-20 h-20 ml-2" />
            <CustomCombobox
              InputProps={{
                disableUnderline: true,
                className: "",
              }}
              onChange={handleSearchChange}
              placeholder="search by name or email"
              size="medium"
              value={searchTerm}
              variant="standard"
            />
          </div>
          {loading ? (
            <div className="px-8 py-4">Loading...</div>
          ) : (
            <div className="flex flex-col gap-0">
              {!searchTerm ? (
                <div className="p-40 text-center text-gray-500 text-14">
                  Enter search term
                </div>
              ) : (
                filteredOptions?.map((option, index) => {
                  const isLast = index === filteredOptions.length - 1;
                  const isSelected = selectedOption?.value === option.value;
                  return (
                    <FormControlLabel
                      className={`gap-8 p-8 mx-0 hover:bg-grey-200 w-full ${
                        isLast ? "mb-0" : " border-b border-grey-300"
                      }`}
                      control={
                        <StyledCheckbox
                          checked={isSelected}
                          color="primary"
                          disableRipple
                          onChange={() => handleSelect(option)}
                        />
                      }
                      key={`${option.value}-${index}`}
                      label={
                        <div className="flex flex-col">
                          <span className="text-14">
                            {highlightMatch(option.label, searchTerm)}
                          </span>
                          {option.email ? (
                            <span className="text-14 text-grey-700">
                              {highlightMatch(option.email, searchTerm)}
                            </span>
                          ) : null}
                        </div>
                      }
                      labelPlacement="end"
                    />
                  );
                })
              )}
            </div>
          )}
        </div>
      </Popover>
    </div>
  );
};

export default EmailPopoverCombobox;
