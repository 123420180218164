import axios from "../axios-shared";

/**
 * Reset password using token
 * @param {Object} data
 * @param {string} data.token - The reset token from the URL
 * @param {string} data.username - The email from the URL
 * @param {string} data.password - The new password
 * @param {string} data.password_confirmation - The password confirmation
 * @returns {Promise}
 */
export const resetPasswordWithToken = (data) => {
  return axios.post("/api/v2/password/reset", data);
};

export const verifyOtp = (data) => {
  return axios.post("api/v1/verify/otp", data);
};

export const resetPassword = (data) => {
  return axios.post("api/v1/reset/password", data);
};

export const forgetPassword = (data) => {
  return axios.post("/api/v1/forgot/password", data);
};
