import * as React from "react";
const PrintIcon = (props) => (
  <svg
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.5 7.152H16v-4.5a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v4.5H2.5a1.5 1.5 0 0 0-1.5 1.5v10a1.5 1.5 0 0 0 1.5 1.5H7v2.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-2.5h4.5a1.5 1.5 0 0 0 1.5-1.5v-10a1.5 1.5 0 0 0-1.5-1.5Zm0 1a.5.5 0 0 1 .5.5v1.5h-5v-2h4.5Zm-12.5-5h7v7H8v-7Zm-5.5 5H7v2H2v-1.5a.5.5 0 0 1 .5-.5Zm12.5 14H8v-5h7v5Zm5.5-3H16v-2h.5a.5.5 0 1 0 0-1h-10a.5.5 0 0 0 0 1H7v2H2.5a.5.5 0 0 1-.5-.5v-7.5h19v7.5a.5.5 0 0 1-.5.5Z"
      fill="#5B81F0"
    />
    <path
      d="M4.5 12.152h-1a.5.5 0 0 0 0 1h1a.5.5 0 1 0 0-1ZM7.5 12.152h-1a.5.5 0 0 0 0 1h1a.5.5 0 1 0 0-1Z"
      fill="#5B81F0"
    />
  </svg>
);
export default PrintIcon;
