import * as React from "react";
const CloudUploadGradient = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 42"
    fill="none"
    {...props}
  >
    <path
      fill="url(#cloud-upload-gradient-a)"
      d="M0 29.331c.248 1.23.561 2.446.94 3.644 1.957 5.274 7.331 9.106 13.143 9.024 3.71-.054 7.42-.01 11.13-.01h.743V29.466H22.55c-.757 0-1.566.121-1.997-.71-.416-.803.138-1.355.569-1.93 2.525-3.366 5.053-6.73 7.581-10.095.854-1.136 1.753-1.136 2.605-.002 2.596 3.455 5.19 6.912 7.785 10.37.106.135.204.275.295.42a1.209 1.209 0 0 1 .063 1.29c-.113.199-.282.365-.487.479-.205.113-.438.17-.675.165-1.19.036-2.382.01-3.572.013h-.668v12.522h.687c4.256 0 8.514-.02 12.77.006 4.228.026 7.476-1.769 9.922-4.967a11.832 11.832 0 0 0 2.17-4.282c.42-1.552.51-3.17.27-4.757a11.218 11.218 0 0 0-1.63-4.491 11.688 11.688 0 0 0-3.38-3.472 4.018 4.018 0 0 0-.486-.312.54.54 0 0 1-.316-.298.5.5 0 0 1 .024-.425 7.697 7.697 0 0 0 .21-3.829 7.85 7.85 0 0 0-1.67-3.481 8.264 8.264 0 0 0-3.164-2.33 8.579 8.579 0 0 0-3.928-.642c-.743.086-1.48.21-2.209.373-.504-.777-.972-1.645-1.58-2.412C37.678 1.537 32.303-.737 25.656.21c-3.398.477-6.552 1.972-9.017 4.273-2.465 2.3-4.115 5.29-4.715 8.544-.207 1.082-.596 1.458-1.67 1.79C4.396 16.635 1.129 20.632.123 26.42c-.031.182-.08.36-.122.542v2.37Z"
    />
    <defs>
      <linearGradient
        id="cloud-upload-gradient-a"
        x1={0}
        x2={60}
        y1={0}
        y2={0}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#36CEE5" />
        <stop offset={1} stopColor="#676AF6" />
      </linearGradient>
    </defs>
  </svg>
);
export default CloudUploadGradient;
