import CsvIcon from '@shared/icons/CsvIcon';
import DownloadIcon from '@shared/icons/DownloadIcon';
import ExcelIcon from '@shared/icons/ExcelIcon';
import PdfIcon from '@shared/icons/PdfIcon';
import { CircularProgress, Divider, List, ListItem, ListItemText, Popover } from '@material-ui/core';
import React, { useState } from 'react';

const ExportButton = ({ exportLoading, onExport, onDownloadPDF, pdfLoading, exportsDisabled }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const open = Boolean(anchorEl);
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleExport = (format) => {
		onExport(format);
		handleClose();
	};
	return (
		<div>
			<button
				className={`border-2 border-pd-blue px-16 md:px-24 py-8 md:py-12 rounded-4 flex items-center gap-8 bg-blue-gradient text-white font-bold ${
					exportsDisabled ? 'opacity-50' : ''
				}`}
				disabled={exportsDisabled}
				onClick={handleClick}
				style={{ background: 'linear-gradient(270deg, #028AFD 0.05%, #015297 99.95%)' }}
				type="button"
			>
				{exportLoading || pdfLoading ? (
					<CircularProgress className="mx-auto" color="inherit" size={24} />
				) : (
					<>
						<DownloadIcon className="w-24" />
						Export
					</>
				)}
			</button>
			<Popover
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				onClose={handleClose}
				open={open}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<List>
					<ListItem className="flex items-center justify-start w-full gap-16 pr-32">
						<button
							className="flex items-center gap-8"
							disabled={pdfLoading || exportLoading}
							onClick={onDownloadPDF}
							type="button"
						>
							<PdfIcon className="w-24" />
							<ListItemText>PDF</ListItemText>
						</button>
					</ListItem>
					<Divider />
					<ListItem className="flex items-center justify-start w-full gap-16 pr-32">
						<button
							className="flex items-center gap-8"
							disabled={exportLoading}
							onClick={() => handleExport('xlsx')}
							type="button"
						>
							<ExcelIcon className="w-24" />
							<ListItemText>Excel</ListItemText>
						</button>
					</ListItem>
					<Divider />
					<ListItem className="flex items-center justify-start w-full gap-16 pr-32">
						<button
							className="flex items-center gap-8"
							disabled={exportLoading}
							onClick={() => handleExport('csv')}
							type="button"
						>
							<CsvIcon className="w-24" />
							<ListItemText>CSV</ListItemText>
						</button>
					</ListItem>
				</List>
			</Popover>
		</div>
	);
};

export default ExportButton;
