import { Checkbox, withStyles } from "@material-ui/core";
import React from "react";

const CheckboxWithStyles = withStyles({
  root: {
    padding: "0",
    color: "rgba(0, 0, 0, 0.54)",
    "&$checked": {
      color: "black",
    },
  },
  checked: {},
})(Checkbox);

const StyledCheckbox = (props) => {
  return <CheckboxWithStyles {...props} disableRipple />;
};

export default StyledCheckbox;
