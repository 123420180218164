/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import {
	Table,
	TableHead,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Paper,
	CircularProgress,
	IconButton,
	TextField,
	InputAdornment,
	Avatar,
	InputLabel,
	Select,
	MenuItem,
	FormControl,
} from '@material-ui/core';
import CustomDatePicker from '@shared/customComponents/CustomDatePicker/CustomDatePicker';
import Close from '@material-ui/icons/Close';
import './payment.css';
import FuseAnimate from '@fuse/core/FuseAnimate';
import { useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getpayment } from 'app/services/paymentManagement/paymentManagement';
import * as Actions from 'app/store/actions';
import moment from 'moment';
import dayjs from 'dayjs';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getSearchableSchoolsList } from 'app/services/userManagement/userManagement';
import { generateAgeString } from 'utils/utils';

function Payment() {
	const dispatch = useDispatch();
	const [rows, setRows] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [hasMore, setHasMore] = useState(false);
	const [firstLoad, setFirstLoad] = useState(true);
	const [refresh, setRefresh] = useState(false);
	const [page, setPage] = useState(1);
	const [fetchingMore, setFetchingMore] = useState(false);
	const [filters, setFilters] = useState({
		name: '',
		school_id: '',
		status: '',
		date_from: '',
		date_to: '',
	});
	const [dateFrom, setDateFrom] = useState('');
	const [dateTo, setDateTo] = useState('');
	const [schools, setSchools] = useState([]);
	const [anchorEl, setAnchorEl] = useState(null);
	const [isSchoolloading, setIsSchoolloading] = useState(false);
	const [searchSchoolQuery, setSchoolSearchQuery] = useState();

	const handleClick = (row) => {
		dispatch(
			Actions.openDialog({
				children: (
					<div className="bg-white student-list-card">
						<div className="flex justify-between student-list-header">
							<div>
								<h1>Students</h1>
							</div>
							<div>
								<i
									style={{ cursor: 'pointer' }}
									className="fas fa-times"
									onClick={() => dispatch(Actions.closeDialog())}
								/>
							</div>
						</div>
						<div id="scrollable-list" className="student-list-cont w-full">
							{row?.children.slice(2).map((child, i) => {
								return (
									<div className="flex items-center " style={{ padding: 14 }} key={i}>
										<Avatar className="mr-6" alt="student-face" src={child.thumb} />
										<div className="flex flex-col items-start">
											<div className="student-name truncate break-all">
												{child.first_name} {child.last_name}
											</div>
										</div>
									</div>
								);
							})}

							{/* 
              <img
                style={{ width: '30px', height: '30px', borderRadius: '100%', backgroundColor: 'red' }}
                src={row?.photo}
                alt={row?.first_name}
              />
              <div className="student-name truncate break-all">
                {row?.first_name}
              </div> */}
						</div>
					</div>
				),
			}),
		);
	};

	useEffect(() => {
		let isMounted = true;
		const timeout = setTimeout(
			() => {
				if (!isMounted) return;
				setIsSchoolloading(true);
				setSchools([]);
				if (!searchSchoolQuery) {
					getSearchableSchoolsList('', 1)
						.then((res) => {
							if (!isMounted) return;
							setSchools(res.data.data);
						})
						.catch((err) => {
							if (!isMounted) return;
							dispatch(
								Actions.showMessage({
									message: 'Failed to get schools.',
									autoHideDuration: 1500,
									variant: 'error',
								}),
							);
						})
						.finally(() => {
							if (!isMounted) return;
							setIsSchoolloading(false);
						});
				} else {
					getSearchableSchoolsList(searchSchoolQuery, searchSchoolQuery ? undefined : 1)
						.then((res) => {
							if (!isMounted) return;
							setSchools(res.data.data);
						})
						.catch((err) => {
							if (!isMounted) return;
							dispatch(
								Actions.showMessage({
									message: 'Failed to get schools.',
									autoHideDuration: 1500,
									variant: 'error',
								}),
							);
						})
						.finally(() => {
							if (!isMounted) return;
							setIsSchoolloading(false);
						});
				}
			},
			firstLoad ? 0 : 1000,
		);
		return () => {
			isMounted = false;
			clearTimeout(timeout);
		};
	}, [dispatch, searchSchoolQuery, filters]);

	useEffect(() => {
		let isMounted = true;
		const timeout = setTimeout(
			() => {
				if (!isMounted) return;
				setIsLoading(true);
				getpayment(
					filters.name,
					filters.school_id,
					filters.status,
					dateFrom,
					dateTo,
					1,
					// date_from ? dayjs(date_from).format('YYYY-MM-DD') : '',
					// date_to ? dayjs(date_to).format('YYYY-MM-DD') : ''
				)
					.then((res) => {
						if (!isMounted) return;
						setFirstLoad(false);
						setRows(res.data.data || []);
						setHasMore(res.data.to < res.data.total);
						setPage(res.data.current_page + 1);
					})
					.catch((err) => {
						if (!isMounted) return;
						dispatch(
							Actions.showMessage({
								message: 'Failed to fetch data, please refresh',
								variant: 'error',
							}),
						);
					})
					.finally(() => {
						if (!isMounted) return;
						setIsLoading(false);
					});
			},
			firstLoad ? 0 : 1000,
		);
		return () => {
			isMounted = false;
			clearTimeout(timeout);
		};
		// eslint-disable-next-line
	}, [refresh, filters, dateFrom, dateTo]);

	const handleLoadMore = () => {
		setFetchingMore(true);
		getpayment(filters.name, filters.school_id, filters.status, dateFrom, dateTo, page)
			.then((res) => {
				if (res.data.last_page > res.data.current_page) {
					setHasMore(true);
				} else {
					setHasMore(false);
				}
				setPage(res.data.current_page + 1);
				setRows(rows.concat(res.data.data));
				setFetchingMore(false);
			})
			.catch((err) => {
				setFetchingMore(false);
				dispatch(
					Actions.showMessage({
						message: 'Failed to fetch data, please refresh',
						variant: 'error',
					}),
				);
			});
	};

	// const handleFilters = ev => {

	//   if (ev.target.name === 'name') {
	//     setFilters({ ...filters, name: ev.target.value });
	//   }

	//   if (ev.target.name === 'status') {
	//     setFilters({ ...filters, status: ev.target.value });
	//   }
	//   if (ev.target.name === 'school_id') {
	// 		if (ev.target.value) {
	// 			setFilters({ ...filters, school_id: ev.target.value });
	// 		} else {
	// 			setFilters({ ...filters, school_id: '' });
	// 		}
	// 	}
	// };

	const handleFilters = (ev) => {
		const { name, value } = ev.target;
		setFilters({ ...filters, [name]: value });
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	const getAgeDetails = (oldDate, newDate) => {
		const years = newDate.diff(oldDate, 'year');
		const allMonths = newDate.diff(oldDate, 'month');
		const months = newDate.diff(oldDate, 'month') - years * 12;
		const days = newDate.diff(oldDate.add(years, 'year').add(months, 'month'), 'day');
		return {
			years,
			months,
			allMonths,
			days,
			allDays: newDate.diff(oldDate, 'day'),
		};
	};

	return (
		<>
			<FuseAnimate animation="transition.slideLeftIn" duration={600}>
				<div className="mx-auto company-cont">
					<div className="flex items-center flex-nowrap justify-between">
						<h3 className="mr-28" style={{ fontSize: '20px', fontWeight: '700' }}>
							Payment
						</h3>{' '}
						<div className="flex justify-between">
							<div className="flex">
								<TextField
									name="name"
									className="mx-8"
									style={{ width: 140 }}
									id="name"
									value={filters.name}
									onChange={handleFilters}
									label="Search"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													onClick={() => {
														document.getElementById('name').focus();
													}}
												>
													<img
														alt="search-icon"
														src="assets/images/search-icon.svg"
														height="80%"
														width="80%"
													/>
												</IconButton>
											</InputAdornment>
										),
									}}
								/>

								<div className="mx-8">
									{/* <FormControl>
										<InputLabel id="roomLabel">Filter By School</InputLabel>
										<Select
											name="school_id"
											onChange={handleFilters}
											value={filters.school_id}
											labelId="roomLabel"
											id="school_id"
											label="Filter by School"
											style={{ width: 140 }}
											endAdornment={
												filters.school_id ? (
													<IconButton size="small" className="mr-16">
														<Close
															onClick={() =>
																setFilters({
																	...filters,
																	school_id: ''
																})
															}
															fontSize="small"
														/>
													</IconButton>
												) : (
													''
												)
											}
										>
											{school.length ? (
												school.map(sch => {
													return (
														<MenuItem key={sch.id} value={sch.id}>
															{sch.name}
														</MenuItem>
													);
												})
											) : (
												<MenuItem disabled>Loading...</MenuItem>
											)}
										</Select>
									</FormControl> */}
									<Autocomplete
										id="state-autocomplete"
										className="mx-8"
										options={schools}
										renderOption={(option) => (
											<>
												<div className="flex" style={{ gap: 10 }}>
													<div>{option.name}</div>
												</div>
											</>
										)}
										getOptionLabel={(option) => option.name}
										autoComplete={false}
										clearOnBlur={false}
										disableClearable
										loading={isSchoolloading}
										loadingText="...Loading"
										sx={{ width: '100%' }}
										onChange={(_e, v) => {
											setFilters({ ...filters, school_id: v?.id || '' });
										}}
										onInputChange={(e, value) => {
											setSchoolSearchQuery(value);
											if (value === '') {
												setFilters({ ...filters, school_id: '' });
											}
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Filter By School"
												style={{ width: '140px' }}
												autoComplete="off"
											/>
										)}
									/>
								</div>
								<div className="mx-8">
									<FormControl>
										<InputLabel id="roomLabel">Status </InputLabel>
										<Select
											name="status"
											onChange={handleFilters}
											value={filters.status}
											labelId="status"
											id="status"
											label="Filter by type"
											style={{ width: 140 }}
											endAdornment={
												filters.status ? (
													<IconButton
														size="small"
														className="mr-16"
														onClick={() =>
															setFilters({
																...filters,
																status: '',
															})
														}
													>
														<Close fontSize="small" />
													</IconButton>
												) : (
													''
												)
											}
										>
											<MenuItem value="active">Active</MenuItem>
											<MenuItem value="un_active">Inactive</MenuItem>
										</Select>
									</FormControl>
								</div>
								{/* <div style={{ width: '20%' }}>
                  <CustomDatePicker label="Date From"
                    value={date_from}
                    onChange={handleFilters}
                    setValue={date =>
                      setDate_from(dayjs(date).format('YYYY-MM-DD'))
                    }

                    disableFuture
                    maxDate={date_to || undefined}
                  />
                </div>
                <div style={{ width: '20%' }}>
                  <CustomDatePicker
                    value={date_to}
                    setValue={date => setDate_to(dayjs(date).format('YYYY-MM-DD'))}
                    label="Date To"
                    onChange={handleFilters}
                    disableFuture
                    minDate={date_from || undefined}
                  />
                </div> */}
								<div style={{ width: '20%', marginRight: '11px' }}>
									<CustomDatePicker
										label="Date From"
										value={dateFrom}
										setValue={(d) => setDateFrom(d?.format('YYYY-MM-DD') || '')}
										disableFuture
										maxDate={dateTo || undefined}
									/>
								</div>
								<div style={{ width: '20%' }}>
									<CustomDatePicker
										label="Date To"
										value={dateTo}
										setValue={(d) => setDateTo(d?.format('YYYY-MM-DD') || '')}
										disableFuture
										minDate={dateFrom || undefined}
									/>
								</div>
							</div>
						</div>
					</div>
					<TableContainer id="Scrollable-table" component={Paper} className="company-table-cont">
						<Table stickyHeader className="company-table" style={{ maxWidth: '100%' }}>
							<TableHead>
								<TableRow>
									<TableCell style={{ width: '10%' }} className="bg-white companyTableHeader">
										Parent
									</TableCell>
									<TableCell style={{ width: '20%' }} className="bg-white companyTableHeader">
										Student
									</TableCell>
									<TableCell
										className="bg-white companyTableHeader"
										style={{ width: '15%', padding: 0 }}
									/>
									<TableCell style={{ width: '5%' }} className="bg-white companyTableHeader">
										Package
									</TableCell>
									<TableCell style={{ width: '5%' }} className="bg-white companyTableHeader">
										Status
									</TableCell>
									<TableCell style={{ width: '15%' }} className="bg-white companyTableHeader">
										Last Amount
									</TableCell>
									<TableCell style={{ width: '15%' }} className="bg-white companyTableHeader">
										Total Amount
									</TableCell>
									<TableCell style={{ width: '10%' }} className="bg-white companyTableHeader">
										Date
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody className="">
								{isLoading ? (
									<TableRow>
										<TableCell align="center" colSpan={8}>
											<CircularProgress />
										</TableCell>
									</TableRow>
								) : !rows.length && !firstLoad ? (
									<TableRow>
										<TableCell align="center" colSpan={8}>
											No Payment
										</TableCell>
									</TableRow>
								) : (
									rows?.map((row) => {
										return (
											<TableRow key={row.id}>
												<TableCell
													style={{ fontSize: '12px', color: '#000', fontWeight: '700' }}
													component="th"
													scope="row"
												>
													<div className="flex items-center">
														<Avatar
															className="mr-6"
															alt="student-face"
															src={row.parent?.thumb}
														/>
														<div className="flex flex-col items-center">
															<div className="parent-name truncate">
																{row.parent?.first_name} {row.parent?.last_name}
															</div>
														</div>
													</div>
												</TableCell>

												<TableCell align="left" style={{ fontWeight: 700 }}>
													<div className="flex items-center justify-between">
														<div className="flex items-center">
															{row.children?.length > 0
																? row.children
																		.map((item, index) => {
																			return (
																				<div
																					className="grid auto-col-min auto-row-min"
																					key={`${item.id}${index}`}
																				>
																					<div
																						className="flex items-center payment-parent-column"
																						style={{ marginRight: 20 }}
																						// key={i}
																					>
																						<Avatar
																							className="mr-4"
																							alt="parent-face"
																							src={item.thumb}
																						/>

																						<div className="flex flex-col">
																							<div
																								className="font-normal truncate student-age-font"
																								style={{
																									width: '101px',
																								}}
																							>
																								{item.school?.name}
																							</div>
																							<div
																								className="parent-name truncate imppp"
																								style={{
																									fontSize: '12px',
																									color: '#000',
																									fontWeight: '700',
																								}}
																							>
																								{item.first_name}{' '}
																								{item.last_name}
																							</div>
																							<div
																								className="font-normal truncate student-age-font"
																								style={{
																									width: '90px',
																								}}
																							>
																								{generateAgeString(
																									item?.date_of_birth,
																								)}
																							</div>
																						</div>
																					</div>
																				</div>
																			);
																		})
																		.slice(0, 2)
																: null}
														</div>
													</div>
												</TableCell>
												<TableCell style={{ padding: 0 }}>
													<div>
														{row.children?.length > 2 ? (
															<>
																<div
																	onClick={() => handleClick(row)}
																	style={{
																		cursor: 'pointer',
																		fontSize: '11px',
																		color: '#008dff',
																		fontWeight: 700,
																		marginLeft: -12,
																	}}
																>
																	{/* View More */} +
																	{`${row.children?.length - 2} Others`}
																</div>
															</>
														) : (
															''
														)}
													</div>
												</TableCell>
												<TableCell style={{ fontWeight: 700 }} component="th" scope="row">
													<div className="flex items-center">
														<div className="company-name truncate">
															{row.package?.title.split('Subscription')}
															{/* {row?.package === null ? '-' : '' } */}
														</div>
													</div>
												</TableCell>
												<TableCell style={{ fontWeight: 700 }} component="th" scope="row">
													<div className="flex items-center">
														<div
															className="company-name truncate"
															style={{
																color: `${
																	row?.stripe_status === 'active' ||
																	row?.stripe_status === 'trialing'
																		? '#04C01C'
																		: row?.stripe_status === 'cancelled_past_due' ||
																			  row?.stripe_status === 'past_due'
																			? '#FF4B4B'
																			: row?.stripe_status ===
																						'cancelled_by_user' ||
																				  row?.stripe_status === 'cancelled'
																				? '#FF4B4B'
																				: ''
																}`,
															}}
														>
															{row?.stripe_status === 'active' ||
															row?.stripe_status === 'trialing'
																? 'Active'
																: row?.stripe_status === 'cancelled_past_due' ||
																	  row?.stripe_status === 'past_due'
																	? 'Expired'
																	: row?.stripe_status === 'cancelled_by_user' ||
																		  row?.stripe_status === 'cancelled'
																		? 'Inactive'
																		: ''}
														</div>
													</div>
												</TableCell>
												<TableCell component="th" scope="row" style={{ fontWeight: 700 }}>
													<div className="flex items-center">
														<div
															className="truncate"
															style={{
																fontSize: '12px',
																textTransform: 'capitalize',
																color: '#06071d',
															}}
														>
															${row.package_price}
														</div>
													</div>
												</TableCell>
												<TableCell component="th" scope="row" style={{ fontWeight: 700 }}>
													<div className="flex items-center">
														<div
															className="truncate"
															style={{
																fontSize: '12px',
																textTransform: 'capitalize',
																color: '#06071d',
															}}
														>
															${row.total_amount?.toFixed(2)}
														</div>
													</div>
												</TableCell>
												<TableCell component="th" scope="row">
													<div className="flex flex-col">
														<div
															style={{
																fontSize: '12px',
																fontWeight: '600',
																color: '#000',
															}}
														>
															{moment.utc(row?.created_at).local().format('L')}
														</div>
														<div className="subscribe-date">
															{moment.utc(row?.created_at).local().format('LT')}
														</div>
													</div>
												</TableCell>
											</TableRow>
										);
									})
								)}
								{fetchingMore ? (
									<TableRow>
										<TableCell align="center" colSpan={8}>
											<CircularProgress />
										</TableCell>
									</TableRow>
								) : (
									<></>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<InfiniteScroll
						dataLength={rows.length}
						next={handleLoadMore}
						hasMore={hasMore}
						scrollableTarget="Scrollable-table"
					/>
				</div>
			</FuseAnimate>
		</>
	);
}

export default Payment;
