import CompanyConfig from './Company/CompanyConfig';
import AdminDashboardConfig from './home/AdminDashboardConfig';
import NotificationConfig from './Notifications/NotificationConfig';
import PackagesConfig from './Packages/PackagesConfig';
import PaymentManagementConfig from './PaymentManagement/PaymentManagementConfig';
import SuperAdminProfileConfig from './profile/profileConfig';
import ReportsConfig from './Reports/ReportsConfig';
import FinanceConfig from './finance/FinanceConfig';
import MapViewConfig from './MapViewOfSchool/MapViewConfig';
import UserManagementConfig from './UserManagement/UserManagementConfig';

const ProductOwnerConfig = [
	CompanyConfig,
	AdminDashboardConfig,
	SuperAdminProfileConfig,
	ReportsConfig,
	UserManagementConfig,
	PackagesConfig,
	NotificationConfig,
	PaymentManagementConfig,
	FinanceConfig,
	MapViewConfig,
];

export default ProductOwnerConfig;
