/* eslint-disable camelcase */
import axios from '@shared/axios-shared';

const getCompanyList = (search, city, state, page) => {
	return axios.get(`api/v1/owner/company/list?company=${search}&city=${city}&state=${state}&page=${page}`);
};

const getMyCompanies = (page = 1, only_active = false, name = '') => {
	return axios.get(`/api/v2/companies`, { params: { page, active: only_active ? 1 : 0, name: name } });
};

const getStateList = (page) => {
	return axios.get(`api/v1/states?page=${page}`);
};

const updateCompanyStatus = (company_id, data) => {
	return axios.post(`/api/v1/owner/company/status/${company_id}`, data);
};

const addCompany = (data) => {
	return axios.post(`/api/v1/owner/company`, data);
};

const getCompany = (id) => {
	return axios.get(`api/v1/owner/company/show/${id}`);
};
const getschooldetail = (id) => {
	return axios.get(`api/v1/owner/schools/detail/${id}`);
};
const updateCompany = (id, data) => {
	return axios.patch(`api/v1/owner/company/${id}`, data);
};

const getStateListByCountry = (country_id) => {
	return axios.get(`api/v1/states/${country_id}`);
};

const getCountryList = () => {
	return axios.get(`api/v1/countries?code=USA`);
};

const getCityList = (state_id) => {
	return axios.get(`/api/v1/cities?state_id=${state_id}`);
};

const addCompanySchool = (data) => {
	return axios.post(`/api/v1/owner/schools`, data);
};

const getCompSchoolList = (company_id, page) => {
	return axios.get(`/api/v1/owner/schools/list/${company_id}?page=${page}`);
};

const updateSchoolStatus = (school_id) => {
	return axios.post(`/api/v1/owner/schools/status/${school_id}`);
};

const updateCompanySchool = (id, data) => {
	return axios.patch(`api/v1/owner/schools/${id}`, data);
};

const getSearchableStateList = (search, page) => {
	return axios.get(`api/v1/countries/USA/states?search=${search}&page=${page}`);
};

const getSearchableCityList = (state_id, search, page) => {
	return axios.get(`api/v1/states/${state_id}/cities?search=${search}&page=${page}`);
};

export {
	getMyCompanies,
	addCompany,
	addCompanySchool,
	getCityList,
	getCompany,
	getCompanyList,
	getCompSchoolList,
	getCountryList,
	getschooldetail,
	getSearchableCityList,
	getSearchableStateList,
	getStateList,
	getStateListByCountry,
	updateCompany,
	updateCompanySchool,
	updateCompanyStatus,
	updateSchoolStatus,
};
