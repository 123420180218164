//
// import { ChevronLeftIcon, EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import React from "react";
import { Close } from "@material-ui/icons";
import { animated, useSpring, useTransition } from "@react-spring/web";
import { useState } from "react";
import UserActionModal from "./UserActionModal";

const SelectionBar = ({
  setSelectedUsersIds,
  selectedUsers,
  setSelectedUsers,
  setRefresh,
}) => {
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [modalConfig, setModalConfig] = useState({
    isOpen: false,
    type: null,
  });

  const hasAnyNonPrimaryUsers = selectedUsers.some((user) => !user.is_primary);

  const springProps = useSpring({
    opacity: selectedUsers?.length > 0 ? 1 : 0,
    transform:
      selectedUsers?.length > 0 ? "translateY(0px)" : "translateY(100px)",
    config: {
      tension: 400,
      friction: 26,
      mass: 1.1,
    },
  });

  const optionsTransition = useTransition(showMoreOptions, {
    from: { opacity: 0, transform: "translateX(-20px)" },
    exitBeforeEnter: true,
    trail: 0,
    enter: { opacity: 1, transform: "translateX(0px)" },
    leave: { opacity: 0, transform: "translateX(20px)" },
    config: {
      tension: 400,
      friction: 26,
      duration: 100,
      mass: 1,
    },
  });

  const handleClearSelections = () => {
    setSelectedUsersIds([]);
    setSelectedUsers([]);
    setShowMoreOptions(false);
  };

  const buttonClasses =
    "bg-slate-600 rounded-full md:px-16 md:py-8 px-8 py-4 whitespace-nowrap text-12";

  const handleOpenModal = (type) => {
    setModalConfig({
      isOpen: true,
      type,
    });
  };

  return (
    <>
      <UserActionModal
        open={modalConfig.isOpen}
        selectedUsers={selectedUsers}
        setOpen={(isOpen) => setModalConfig((prev) => ({ ...prev, isOpen }))}
        setRefresh={setRefresh}
        setSelectedUsers={setSelectedUsers}
        setSelectedUsersIds={setSelectedUsersIds}
        type={modalConfig.type}
      />
      <animated.div
        className="fixed bottom-[4rem] left-0 right-0 bg-slate-700 w-max lg:w-full lg:max-w-max mx-auto rounded-full text-white px-16 py-8 z-50"
        style={springProps}
      >
        <div className="flex gap-10 md:gap-32">
          <div className="flex gap-8 items-center">
            <button
              className="hover:bg-slate-800 rounded-full p-2"
              onClick={handleClearSelections}
              type="button"
            >
              <Close className="size-16" />
            </button>
            <div className="flex items-center">
              <span className="text-12 bg-white text-black rounded-full px-8 py-0 flex items-center justify-center">
                {selectedUsers?.length}
              </span>
            </div>
            <span className="whitespace-nowrap">
              User{selectedUsers?.length > 1 ? "s" : ""} Selected
            </span>
          </div>
          <div className="flex gap-4 relative">
            {optionsTransition((styles, show) =>
              show ? null : (
                <animated.div
                  className="md:flex gap-4 grid grid-cols-2  left-0"
                  style={styles}
                >
                  <button
                    className={buttonClasses}
                    onClick={() => handleOpenModal("activate")}
                    type="button"
                  >
                    Activate User{selectedUsers?.length > 1 ? "s" : ""}
                  </button>
                  {hasAnyNonPrimaryUsers ? (
                    <>
                      <button
                        className={buttonClasses}
                        onClick={() => handleOpenModal("deactivate")}
                        type="button"
                      >
                        Deactivate User{selectedUsers?.length > 1 ? "s" : ""}
                      </button>
                      <button
                        className={buttonClasses}
                        onClick={() => handleOpenModal("delete")}
                        type="button"
                      >
                        Delete User{selectedUsers?.length > 1 ? "s" : ""}
                      </button>
                    </>
                  ) : null}
                  <button
                    className={buttonClasses}
                    onClick={() => handleOpenModal("resetPassword")}
                    type="button"
                  >
                    Reset Password{selectedUsers?.length > 1 ? "s" : ""}
                  </button>
                </animated.div>
              )
            )}
            {/* Ellipsis menu can be unhidden if we need more options in the future */}
            {/* <div className="ml-auto flex items-center z-99">
							<animated.button
								className="bg-slate-600 rounded-full size-[35px] flex items-center justify-center"
								onClick={() => setShowMoreOptions(!showMoreOptions)}
								style={toggleButtonSpring}
							>
								{showMoreOptions ? (
									<ChevronLeftIcon className="size-24" />
								) : (
									<EllipsisVerticalIcon className="size-24" />
								)}
							</animated.button>
						</div> */}
          </div>
        </div>
      </animated.div>
    </>
  );
};

export default SelectionBar;
