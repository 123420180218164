// import firebaseService from 'app/services/firebaseService';
import jwtService from 'app/services/jwtService';
import * as Actions from 'app/store/actions';
import { sendFCMTokenToBackend } from 'app/services/notifications/fcmServices';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import * as UserActions from './user.actions';

const firebaseConfig = {
	apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
	authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
	projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
	storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
	appId: import.meta.env.VITE_FIREBASE_APP_ID,
	measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export function submitLogin({ username, password }, setIsLoggingIn) {
	return (dispatch) =>
		jwtService
			.signInWithEmailAndPassword(username, password)
			.then((user) => {
				setIsLoggingIn(false);
				getToken(messaging, {
					vapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY,
				})
					.then((deviceToken) => {
						if (deviceToken) {
							sendFCMTokenToBackend(deviceToken);
						} else {
							// Show permission request UI
							console.log('No registration token available. Request permission to generate one.');
							// ...
						}
					})
					.catch((err) => {
						console.log('An error occurred while retrieving token. ', err);
						// ...
					});
				user.company = null;
				dispatch(UserActions.setUserData(user));
				return dispatch({
					type: LOGIN_SUCCESS,
				});
			})
			.catch((error) => {
				setIsLoggingIn(false);
				dispatch(Actions.showMessage({ message: error.response.data.message, variant: 'error' }));
				return dispatch({
					type: LOGIN_ERROR,
					payload: error,
				});
			});
}

export function submitLoginWithFireBase({ username, password }) {
	// if (!firebaseService.auth) {
	// 	console.warn("Firebase Service didn't initialize, check your configuration");
	// 	return () => false;
	// }
	// return dispatch =>
	// 	firebaseService.auth
	// 		.signInWithEmailAndPassword(username, password)
	// 		.then(() => {
	// 			return dispatch({
	// 				type: LOGIN_SUCCESS
	// 			});
	// 		})
	// 		.catch(error => {
	// 			console.info('error');
	// 			const usernameErrorCodes = [
	// 				'auth/email-already-in-use',
	// 				'auth/invalid-email',
	// 				'auth/operation-not-allowed',
	// 				'auth/user-not-found',
	// 				'auth/user-disabled'
	// 			];
	// 			const passwordErrorCodes = ['auth/weak-password', 'auth/wrong-password'];
	// 			const response = {
	// 				username: usernameErrorCodes.includes(error.code) ? error.message : null,
	// 				password: passwordErrorCodes.includes(error.code) ? error.message : null
	// 			};
	// 			if (error.code === 'auth/invalid-api-key') {
	// 				dispatch(Actions.showMessage({ message: error.message }));
	// 			}
	// 			return dispatch({
	// 				type: LOGIN_ERROR,
	// 				payload: response
	// 			});
	// 		});
}
