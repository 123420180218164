import React from "react";
import AscendingSortIcon from "../../icons/AscendingSortIcon";
import DescendingSortIcon from "../../icons/DescendingSortIcon";
import {
  CircularProgress,
  Divider,
  Fade,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  withStyles,
} from "@material-ui/core";
import { Check } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import { useEffect, useRef, useState } from "react";

const StyledSortLabel = withStyles(() => ({
  root: {
    width: "100%",
    justifyContent: "space-between",
  },
}))(TableSortLabel);

const UsersTableHead = ({
  columns,
  sort,
  order,
  onRequestSort,
  studentOrder,
  onRequestStudentNameOrderChange,
  onSelectAll,
  selectedAll,
  data,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [hoveredColumn, setHoveredColumn] = useState(null);

  const handlePopoverOpen = (event, columnId) => {
    setAnchorEl(event.currentTarget);
    setHoveredColumn(columnId);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setHoveredColumn(null);
  };

  const open = Boolean(anchorEl);

  const handleStudentNameOrderChange = (order, nameOrder) => {
    onRequestStudentNameOrderChange(order, nameOrder);
    handlePopoverClose();
  };

  const handleColumnOrderChange = (order, columnId) => {
    onRequestSort(order, columnId);
    handlePopoverClose();
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((headCell) => {
          const isStudent = headCell.id === "student";
          const isStudentSort = sort === "student";
          const columnsWithSort = ["users"];

          return (
            <TableCell
              className={`font-bold  ${headCell.widthClass} bg-white`}
              key={headCell.id}
              onMouseEnter={(event) => handlePopoverOpen(event, headCell.id)}
              onMouseLeave={handlePopoverClose}
              scope="col"
            >
              <div className="flex items-center gap-8">
                {headCell.hasSelectAll ? (
                  <input
                    checked={selectedAll}
                    onChange={() => onSelectAll(data)}
                    type="checkbox"
                  />
                ) : null}
                <StyledSortLabel
                  active={sort === headCell.id}
                  direction={sort === headCell.id ? order : "asc"}
                  hideSortIcon={!columnsWithSort.includes(headCell.id)}
                >
                  <span className="text-md">{headCell.label}</span>
                </StyledSortLabel>
              </div>
              {columnsWithSort.includes(headCell.id) ? (
                <Popper
                  anchorEl={anchorEl}
                  onClose={handlePopoverClose}
                  open={open ? hoveredColumn === headCell.id : false}
                  placement="bottom-start"
                  transition
                >
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps}>
                      <div className="flex flex-col p-4 min-w-216 bg-white shadow-xl">
                        {isStudent ? (
                          <>
                            <button
                              className="flex items-center p-4 hover:bg-gray-100"
                              onClick={() =>
                                handleStudentNameOrderChange("asc", "first")
                              }
                              type="button"
                            >
                              <AscendingSortIcon />
                              <span className="">First, Last Ascending</span>
                              <Check
                                className={`absolute right-10 ${
                                  isStudentSort &&
                                  studentOrder === "first" &&
                                  order === "asc"
                                    ? "text-black"
                                    : "text-transparent"
                                }`}
                              />
                            </button>
                            <Divider />
                            <button
                              className="flex items-center p-4 hover:bg-gray-100 active:bg-gray-200"
                              onClick={() =>
                                handleStudentNameOrderChange("desc", "first")
                              }
                              type="button"
                            >
                              <DescendingSortIcon />
                              <span>First, Last Descending</span>
                              <Check
                                className={`absolute right-10 ${
                                  isStudentSort &&
                                  studentOrder === "first" &&
                                  order === "desc"
                                    ? "text-black"
                                    : "text-transparent"
                                }`}
                              />
                            </button>
                            <Divider />
                            <button
                              className="flex items-center p-4 hover:bg-gray-100"
                              onClick={() =>
                                handleStudentNameOrderChange("asc", "last")
                              }
                              type="button"
                            >
                              <AscendingSortIcon />
                              <span className="">Last, First Ascending</span>
                              <Check
                                className={`absolute right-10 ${
                                  isStudentSort &&
                                  studentOrder === "last" &&
                                  order === "asc"
                                    ? "text-black"
                                    : "text-transparent"
                                }`}
                              />
                            </button>
                            <Divider />
                            <button
                              className="flex items-center p-4 hover:bg-gray-100 active:bg-gray-200"
                              onClick={() =>
                                handleStudentNameOrderChange("desc", "last")
                              }
                              type="button"
                            >
                              <DescendingSortIcon />
                              <span>Last, First Descending</span>
                              <Check
                                className={`absolute right-10 ${
                                  isStudentSort &&
                                  studentOrder === "last" &&
                                  order === "desc"
                                    ? "text-black"
                                    : "text-transparent"
                                }`}
                              />
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              className="flex items-center p-4 hover:bg-gray-100"
                              onClick={() =>
                                handleColumnOrderChange("asc", headCell.id)
                              }
                              type="button"
                            >
                              <AscendingSortIcon />
                              <span className="">Sort Ascending</span>
                              <Check
                                className={`absolute right-10 ${
                                  sort === headCell.id && order === "asc"
                                    ? "text-black"
                                    : "text-transparent"
                                }`}
                              />
                            </button>
                            <Divider />
                            <button
                              className="flex items-center p-4 hover:bg-gray-100 active:bg-gray-200"
                              onClick={() =>
                                handleColumnOrderChange("desc", headCell.id)
                              }
                              type="button"
                            >
                              <DescendingSortIcon />
                              <span>Sort Descending</span>
                              <Check
                                className={`absolute right-10 ${
                                  sort === headCell.id && order === "desc"
                                    ? "text-black"
                                    : "text-transparent"
                                }`}
                              />
                            </button>
                          </>
                        )}
                      </div>
                    </Fade>
                  )}
                </Popper>
              ) : null}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

const UsersTable = ({
  isLoading,
  columns,
  data,
  tableHeight,
  filters,
  setFilters,
  totalCount,
  selectedUsersIds,
  setSelectedUsersIds,
  setSelectedUsers,
}) => {
  const tableContainerRef = useRef(null);

  const handleSelectAll = (currentPageData) => {
    const currentPageIds = currentPageData.map((row) => row.id);

    if (currentPageIds.every((id) => selectedUsersIds.includes(id))) {
      // If all current page items are selected, deselect only current page items
      const newSelectedIds = selectedUsersIds.filter(
        (id) => !currentPageIds.includes(id)
      );
      const newSelectedUsers = newSelectedIds.length
        ? data.filter((user) => newSelectedIds.includes(user.id))
        : [];

      setSelectedUsersIds(newSelectedIds);
      setSelectedUsers(newSelectedUsers);
    } else {
      // Select all current page items while maintaining other selections
      const newSelectedIds = [
        ...new Set([...selectedUsersIds, ...currentPageIds]),
      ];
      const newSelectedUsers = [
        ...data.filter((user) => currentPageIds.includes(user.id)),
      ];

      setSelectedUsersIds(newSelectedIds);
      setSelectedUsers((prevUsers) => [...prevUsers, ...newSelectedUsers]);
    }
  };

  const handleRequestSort = (dir, property) => {
    setFilters({ ...filters, dir, sort: property });
  };

  const onRequestStudentNameOrderChange = (order, nameOrder) => {
    setFilters({
      ...filters,
      sort: "student",
      student_name: nameOrder,
      dir: order,
    });
  };

  const handleChangePage = (event, newPage) => {
    setFilters({ ...filters, page: newPage });
  };

  useEffect(() => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
    }
  }, [filters]);

  return (
    <div className="flex flex-col h-full">
      <TableContainer
        className={`flex-1 w-full whitespace-no-wrap relative bg-white overflow-auto ${
          selectedUsersIds?.length > 0 ? "pb-44" : ""
        }`}
        ref={tableContainerRef}
        style={{ maxHeight: `${tableHeight}px`, minHeight: `${tableHeight}px` }}
      >
        <Table stickyHeader>
          <UsersTableHead
            columns={columns}
            data={data}
            onRequestSort={handleRequestSort}
            onRequestStudentNameOrderChange={onRequestStudentNameOrderChange}
            onSelectAll={handleSelectAll}
            order={filters?.dir}
            selectedAll={
              data?.length > 0
                ? data.every((row) => selectedUsersIds?.includes(row.id))
                : false
            }
            sort={filters?.sort}
            studentOrder={filters?.student_name}
          />
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell className="h-96" colSpan={columns.length}>
                  <div className="w-full h-full flex items-center justify-center">
                    <CircularProgress />
                  </div>
                </TableCell>
              </TableRow>
            ) : data?.length === 0 ? (
              <TableRow>
                <TableCell className="h-96" colSpan={columns.length}>
                  <div className="w-full h-full flex items-center justify-center text-gray-500">
                    No records found
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              data?.map((row) => {
                const isSelected = selectedUsersIds?.includes(row.id);
                return (
                  <TableRow key={row.id} selected={isSelected}>
                    {columns.map((column) => (
                      <TableCell
                        className="truncate cursor-default"
                        key={column.id}
                      >
                        {column.render(row)}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        className="bg-white w-full flex justify-center p-4 py-12 border-t border-gray-200"
        key={totalCount}
      >
        <Pagination
          color="primary"
          count={Math.ceil(totalCount / 20)}
          onChange={handleChangePage}
          page={filters?.page}
          size="small"
        />
      </div>
    </div>
  );
};

export default UsersTable;
