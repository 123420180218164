import axios from '@shared/axios-shared';

const getUserListing = (subscriber, student_name, school_id, package_id, page) => {
	return axios.get(
		`/api/v1/owner/management/list?subscriber=${subscriber}&student_name=${student_name}&school_id=${school_id}&package_id=${package_id}&page=${page}`,
	);
};

const getPackagesList = () => {
	return axios.get(`/api/v1/owner/management/package-list`);
};

const getSchoolsList = () => {
	return axios.get(`/api/v1/owner/management/school-list`);
};

const getSearchableSchoolsList = (search, page) => {
	return axios.get(`/api/v1/owner/management/schools?search=${search}&page=${page}`);
};

export { getUserListing, getPackagesList, getSchoolsList, getSearchableSchoolsList };
