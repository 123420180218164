import React, { useState } from "react";

/**
 * A button component with gradient styling options
 * @param {Object} props
 * @param {'small' | undefined} props.size
 * @param {React.ReactNode} props.children
 * @param {Function} props.onClick
 * @param {'secondary' | 'blurple' | 'light-blue' | 'royal-blue'} props.variant
 * @param {boolean} [props.disabled=false]
 * @param {'button' | 'submit' | 'reset'} [props.type='button']
 *
 * @example
 * <GradientButton variant="blurple" onClick={handleClick}>
 *   Click me
 * </GradientButton>
 *
 * @example
 * <GradientButton variant="light-blue" size="small">
 *   Small Button
 * </GradientButton>
 */
const GradientButton = ({
  size,
  children,
  onClick,
  variant = "blurple",
  disabled = false,
  type = "button",
}) => {
  const [hover, setHover] = useState(false);

  const getVariantClasses = (variant) => {
    switch (variant) {
      case "secondary":
        return `${hover ? "shadow-[0px_6px_20px_0px_rgba(137,137,137,0.39)]" : "shadow-none"} 
          bg-white text-black outline outline-1 outline-[#36CEE5]
          active:shadow-[0px_3px_10px_0px_rgba(137,137,137,0.39)]`;

      case "tertiary":
        return `${
          hover
            ? "shadow-[0px_6px_20px_0px_rgba(134,194,255,0.59)] bg-gradient-to-l from-[#36CEE5] to-[#676AF6] text-white outline outline-1 outline-white/25"
            : "shadow-none bg-transparent text-pd-blue outline outline-1 outline-pd-blue"
        } active:shadow-[0px_3px_10px_0px_rgba(134,194,255,0.59)]`;

      case "blurple":
        return `${
          hover
            ? "shadow-[0px_6px_20px_0px_rgba(134,194,255,0.59)] bg-white text-black outline outline-1 outline-[#36CEE5]"
            : "shadow-none bg-gradient-to-l from-[#36CEE5] to-[#676AF6] text-white outline outline-1 outline-white/25"
        } active:shadow-[0px_3px_10px_0px_rgba(134,194,255,0.59)]`;

      case "light-blue":
        return `${
          hover
            ? "shadow-[0px_6px_20px_0px_rgba(137,137,137,0.39)] bg-white text-black border border-[#028AFD]"
            : "shadow-[0px_6px_20px_0px_rgba(134,194,255,0.59)] bg-gradient-to-l from-[#028AFD] to-[#015297] text-white border border-white/25"
        } active:shadow-[0px_3px_10px_0px_rgba(134,194,255,0.59)]`;

      case "royal-blue":
        return `${
          hover
            ? "shadow-[0px_6px_20px_0px_rgba(137,137,137,0.39)] bg-white text-black border border-[#2F6EFF]"
            : "shadow-[0px_6px_20px_0px_rgba(134,194,255,0.59)] bg-gradient-to-r from-[#2F6EFF] to-[#171B8E] text-white border border-transparent"
        } active:shadow-[0px_3px_10px_0px_rgba(134,194,255,0.59)]`;

      default:
        return `bg-white text-black ${
          hover
            ? "shadow-[0px_6px_10px_0px_rgba(137,137,137,0.39)]"
            : "shadow-[0px_6px_20px_0px_rgba(137,137,137,0.39)]"
        } active:shadow-[0px_3px_10px_0px_rgba(137,137,137,0.39)]`;
    }
  };

  return (
    <button
      className={`transition-shadow duration-150 disabled:cursor-not-allowed rounded-full cursor-pointer font-bold flex justify-center gap-8 items-center ${
        size === "small"
          ? "px-36 md:px-40 py-8"
          : "px-16 sm:px-20 py-8 md:py-12 md:px-20"
      } ${getVariantClasses(variant)}`}
      disabled={disabled}
      onClick={onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      // eslint-disable-next-line react/button-has-type
      type={type ? type : "button"}
    >
      {children}
    </button>
  );
};

export default GradientButton;
