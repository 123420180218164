import React from "react";
import { InputAdornment, TextField } from "@material-ui/core";

const UsersHeader = ({ handleSearch, filters }) => {
  const handleSearchChange = (e) => {
    handleSearch(e.target.value);
  };

  return (
    <div className="px-32 py-16 flex justify-between md:items-center flex-col md:flex-row bg-white">
      <p className="text-16 w-full">
        View active users, their roles, and statuses. Edit details, reset
        passwords, or deactivate users as needed.
      </p>

      <div className="flex justify-end md:justify-end md:order-3 items-center gap-16 shrink-0">
        <div className="pb-8">
          <TextField
            InputProps={{
              style: {
                marginRight: "24px",
              },
              endAdornment: (
                <InputAdornment
                  disablePointerEvents
                  position="end"
                  style={{ marginLeft: "24px" }}
                >
                  <img
                    alt="search-icon"
                    className="w-16"
                    src="assets/images/search-icon.svg"
                  />
                </InputAdornment>
              ),
            }}
            // variant="outlined"
            className="border-none"
            fullWidth
            id="search"
            label="Search for users"
            margin="none"
            name="searchQuery"
            onChange={handleSearchChange}
            size="small"
            value={filters?.searchQuery || ""}
          />
        </div>
      </div>
    </div>
  );
};
export default UsersHeader;
