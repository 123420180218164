import authRoles from 'app/auth/authRoles';
import Reports from './Reports';
import SchoolEnrollment from './SchoolEnrollment';
import ParentEnrollment from './ParentEnrollment';
import RevenueReport from './RevenueReport';
import FinancialReport from './Financial/FinancialReport';
import SubscriptionTransactionReports from './SubscriptionTransaction/SubTransReports';

const ReportsConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	routes: [
		{
			path: '/Reports',
			component: Reports,
		},
		{
			path: '/SchoolEnrollment',
			component: SchoolEnrollment,
		},
		{
			path: '/SubscriptionTransaction',
			component: SubscriptionTransactionReports,
		},
		{
			path: '/ParentEnrollment',
			component: ParentEnrollment,
		},
		{
			path: '/RevenueReport',
			component: RevenueReport,
		},
		{
			path: '/FinancialReport',
			component: FinancialReport,
		},
	],
	auth: authRoles.productOwner,
};

export default ReportsConfig;
