import * as React from 'react';
const UploadPhotoGradientIcon = (props) => (
	<svg fill="none" height={39} viewBox="0 0 40 39" width={40} xmlns="http://www.w3.org/2000/svg" {...props}>
		<rect fill="url(#upload-photo-gradient-a)" height={36.788} rx={18.394} width={38} x={1} y={1.211} />
		<rect height={36.788} rx={18.394} stroke="#fff" strokeWidth={2} width={38} x={1} y={1.211} />
		<path
			d="M23.87 21.162a3.62 3.62 0 0 1-.652 2.067 3.842 3.842 0 0 1-1.737 1.37 4.015 4.015 0 0 1-2.236.212 3.921 3.921 0 0 1-1.982-1.018 3.68 3.68 0 0 1-1.059-1.905 3.588 3.588 0 0 1 .22-2.15 3.757 3.757 0 0 1 1.426-1.67A3.982 3.982 0 0 1 20 17.44a3.955 3.955 0 0 1 2.736 1.092 3.654 3.654 0 0 1 1.135 2.63ZM32 15.878v10.57c0 .334-.069.666-.202.976-.133.31-.329.59-.575.828-.247.236-.54.425-.862.553a2.747 2.747 0 0 1-1.015.194H10.654a2.709 2.709 0 0 1-1.877-.748A2.503 2.503 0 0 1 8 26.447v-10.57c0-.676.28-1.325.777-1.803a2.71 2.71 0 0 1 1.877-.748h3.264v-.883c0-.293.06-.583.177-.854.117-.271.288-.517.504-.725a2.33 2.33 0 0 1 .754-.484c.282-.112.584-.17.889-.17h7.52c.304 0 .606.059.888.17.282.113.538.277.754.485.215.207.386.453.503.724.116.27.177.561.177.854v.883h3.262c.703 0 1.379.269 1.876.747.498.479.778 1.128.778 1.805Zm-6.139 5.284a5.482 5.482 0 0 0-.988-3.13 5.817 5.817 0 0 0-2.63-2.075 6.08 6.08 0 0 0-3.386-.32 5.936 5.936 0 0 0-3 1.541 5.571 5.571 0 0 0-1.604 2.885 5.432 5.432 0 0 0 .333 3.255 5.688 5.688 0 0 0 2.158 2.529 6.03 6.03 0 0 0 3.256.95 5.99 5.99 0 0 0 4.142-1.652 5.534 5.534 0 0 0 1.72-3.983Z"
			fill="#fff"
		/>
		<defs>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="upload-photo-gradient-a"
				x1={33.76}
				x2={7.151}
				y1={33.724}
				y2={6.723}
			>
				<stop stopColor="#FED557" />
				<stop offset={1} stopColor="#F67E91" />
			</linearGradient>
		</defs>
	</svg>
);
export default UploadPhotoGradientIcon;
