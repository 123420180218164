/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useRef } from 'react';

import {
	Table,
	TableHead,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Paper,
	Avatar,
	CircularProgress,
	IconButton,
	InputLabel,
	Select,
	Typography,
	MenuItem,
	FormControl,
	TextField,
} from '@material-ui/core';
import './Finance.css';
import FuseAnimate from '@fuse/core/FuseAnimate';
import { useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import CustomDatePicker from '@shared/customComponents/CustomDatePicker/CustomDatePicker';
import CustomButton from 'app/customComponents/CustomButton/CustomButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { getfinance } from 'app/services/finance/finance';
import * as Actions from 'app/store/actions';
import moment from 'moment';
import Close from '@material-ui/icons/Close';
import { useReactToPrint } from 'react-to-print';
import axios from '@shared/axios-shared';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getSearchableSchoolsList } from 'app/services/userManagement/userManagement';

function Finance() {
	const dispatch = useDispatch();
	const [rows, setRows] = useState([]);
	const [rowLoader, setRowLoader] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [hasMore, setHasMore] = useState(false);
	const [firstLoad, setFirstLoad] = useState(true);
	const [fetchingMore, setFetchingMore] = useState(false);
	const [iisLoading, ssetIsLoading] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const [schools, setSchools] = useState([]);
	const [page, setPage] = useState(1);
	const [dateFrom, setdateFrom] = useState('');
	const [dateTo, setdateTo] = useState('');
	const [filters, setFilters] = useState({
		status: '',
		school: '',
		export_data: 0,
	});
	const [date, setDate] = useState(new Date());
	const TransactionReportRef = useRef(null);
	const [isSchoolloading, setIsSchoolloading] = useState(false);
	const [searchSchoolQuery, setSchoolSearchQuery] = useState();

	useEffect(() => {
		let isMounted = true;
		const timeout = setTimeout(
			() => {
				if (!isMounted) return;
				setIsSchoolloading(true);
				setSchools([]);
				if (!searchSchoolQuery) {
					getSearchableSchoolsList('', 1)
						.then((res) => {
							if (!isMounted) return;
							setSchools(res.data.data);
						})
						.catch((err) => {
							if (!isMounted) return;
							dispatch(
								Actions.showMessage({
									message: 'Failed to get schools.',
									autoHideDuration: 1500,
									variant: 'error',
								})
							);
						})
						.finally(() => {
							if (!isMounted) return;
							setIsSchoolloading(false);
						});
				} else {
					getSearchableSchoolsList(searchSchoolQuery, searchSchoolQuery ? undefined : 1)
						.then((res) => {
							if (!isMounted) return;
							setSchools(res.data.data);
						})
						.catch((err) => {
							if (!isMounted) return;
							dispatch(
								Actions.showMessage({
									message: 'Failed to get schools.',
									autoHideDuration: 1500,
									variant: 'error',
								})
							);
						})
						.finally(() => {
							if (!isMounted) return;
							setIsSchoolloading(false);
						});
				}
			},
			firstLoad ? 0 : 1000
		);
		return () => {
			isMounted = false;
			clearTimeout(timeout);
		};
	}, [dispatch, searchSchoolQuery, filters]);

	useEffect(() => {
		let isMounted = true;
		setIsLoading(true);
		setRows([]);
		setPage(1);
		setHasMore(false);
		getfinance(filters.status, filters.school, dateFrom, dateTo, filters.export_data, 1)
			.then((res) => {
				if (!isMounted) return;
				setFirstLoad(false);
				setRows(res.data.data);
				setRowLoader(Array(res.data.data.length).fill(false));
				if (res.data.current_page < res.data.last_page) {
					setHasMore(true);
					setPage(res.data.current_page + 1);
				}
			})
			.catch((err) => {
				if (!isMounted) return;
				dispatch(
					Actions.showMessage({
						message: 'Failed to fetch data, please refresh',
						variant: 'error',
					})
				);
			})
			.finally(() => {
				if (!isMounted) return;
				setIsLoading(false);
			});
		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line
	}, [refresh, filters, dateFrom, dateTo]);

	const handleLoadMore = () => {
		setFetchingMore(true);
		getfinance(filters.status, filters.school, dateFrom, dateTo, filters.export_data, page)
			.then((res) => {
				if (res.data.last_page > res.data.current_page) {
					setHasMore(true);
				} else {
					setHasMore(false);
				}
				setPage(res.data.current_page + 1);
				setRows(rows.concat(res.data.data));
				setRowLoader(rowLoader.concat(Array(res.data.data.length).fill(false)));
				setFetchingMore(false);
			})
			.catch((err) => {
				setFetchingMore(false);
				dispatch(
					Actions.showMessage({
						message: 'Failed to fetch data, please refresh',
						variant: 'error',
					})
				);
			});
	};

	const handleFilters = (ev) => {
		const { name, value } = ev.target;
		setFilters({ ...filters, [name]: value });
	};

	const handlePrint = useReactToPrint({
		content: () => TransactionReportRef.current,
	});

	const handleExport = () => {
		// setFetchingMore(true);
		ssetIsLoading(true);
		getfinance(filters.status, filters.school, dateFrom, dateTo, 1, page)
			.then((res) => {
				const config = {
					method: 'get',
					url: res.data.link,
					responseType: 'blob',
				};
				const newAxios = axios.create({});
				delete newAxios.defaults.headers.common.Authorization;
				newAxios(config).then((resp) => {
					const blob = new Blob([resp.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					});
					const link = document.createElement('a');
					link.setAttribute('target', '_blank');
					link.href = window.URL.createObjectURL(blob);
					link.setAttribute('download', `Transaction_report_${new Date().getTime()}.xlsx`);
					document.body.appendChild(link);
					link.click();
					// Clean up and remove the link
					link.parentNode.removeChild(link);
				});
			})
			.catch((err) => {
				dispatch(
					Actions.showMessage({
						message: 'Failed to Export',
						variant: 'error',
					})
				);
			})
			.finally(() => {
				ssetIsLoading(false);
			});
	};

	const handleManualPayout = (row, index) => {
		setRowLoader(rowLoader.map((item, i) => (i === index ? true : item)));
		axios
			.post('/api/v1/owner/stripe/manual/payout', { payout_id: row.id, school_id: row.school_id })
			.then((res) => {
				dispatch(
					Actions.showMessage({
						message: 'Payout Successful',
						variant: 'success',
					})
				);
				setRefresh(!refresh);
			})
			.catch((err) => {
				dispatch(
					Actions.showMessage({
						message: err.response?.data?.message || 'Payout Failed',
						variant: 'error',
						autoHideDuration: 2500,
					})
				);
				setRowLoader(rowLoader.map((item, i) => (i === index ? false : item)));
			});
	};

	return (
		<>
			<FuseAnimate animation="transition.slideLeftIn" duration={600}>
				<div className="mx-auto company-cont">
					<div className="flex items-center justify-between">
						<div className="schoolReport-topdiv" />
						<div className="flex justify-between">
							<div className="flex">
								<div
									className="self-end "
									style={{
										marginBottom: '22px',
									}}
								>
									<span>
										{!iisLoading ? (
											<CustomButton
												onClick={handleExport}
												variant="primary"
												style={{ marginRight: '18px' }}
												height="38px"
												width="100px"
												marginRight="17px"
											>
												<span className="mr-4">
													<FontAwesomeIcon icon={faDownload} />
												</span>
												Export
											</CustomButton>
										) : (
											<div className="circle-bar">
												<CircularProgress />
											</div>
										)}
										<CustomButton
											onClick={() => handlePrint()}
											variant="secondary"
											height="38px"
											width="100px"
										>
											<i className="fa fa-print" aria-hidden="true" /> Print
										</CustomButton>
										{/* <div className="help-btn cursor-pointer">
										<img src="assets/images/CENTER.png" />
										Help Center
									</div> */}
									</span>
								</div>
							</div>
						</div>
					</div>

					<div className="flex items-center flex-nowrap justify-between">
						<h3 className="font-bold mr-28" style={{ fontSize: '20px' }}>
							Transaction
						</h3>{' '}
						<div className="flex justify-between">
							<div className="flex">
								<div className="mx-8">
									{/* <FormControl>
										<InputLabel id="roomLabel">Filter By School</InputLabel>
										<Select
											name="school"
											onChange={handleFilters}
											value={filters.school}
											labelId="roomLabel"
											id="school"
											label="Filter by School"
											style={{ width: 170 }}
											endAdornment={
												filters.school ? (
													<IconButton size="small" className="mr-16">
														<Close
															onClick={() =>
																setFilters({
																	...filters,
																	school: ''
																})
															}
															fontSize="small"
														/>
													</IconButton>
												) : (
													''
												)
											}
										>
											{schools.length ? (
												schools.map(school => {
													return (
														<MenuItem key={school.id} value={school.name}>
															{school.name}
														</MenuItem>
													);
												})
											) : (
												<MenuItem disabled>Loading...</MenuItem>
											)}
										</Select>
									</FormControl> */}
									<Autocomplete
										id="state-autocomplete"
										className="mx-8"
										options={schools}
										renderOption={(option) => (
											<>
												<div className="flex" style={{ gap: 10 }}>
													<div>{option.name}</div>
												</div>
											</>
										)}
										getOptionLabel={(option) => option.name}
										autoComplete={false}
										clearOnBlur={false}
										disableClearable
										loading={isSchoolloading}
										loadingText="...Loading"
										sx={{ width: '100%' }}
										onChange={(_e, v) => {
											setFilters({ ...filters, school: v?.name || '' });
										}}
										onInputChange={(e, value) => {
											setSchoolSearchQuery(value);
											if (value === '') {
												setFilters({ ...filters, school: '' });
											}
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Filter By School"
												style={{ width: '170px' }}
												autoComplete="off"
											/>
										)}
									/>
								</div>
								<div className="mx-8">
									<FormControl>
										<InputLabel id="roomLabel">Filter by Status </InputLabel>
										<Select
											name="status"
											onChange={handleFilters}
											value={filters.status}
											labelId="status"
											id="status"
											label="Filter by type"
											style={{ width: 170 }}
											endAdornment={
												filters.status ? (
													<IconButton
														size="small"
														className="mr-16"
														onClick={() =>
															setFilters({
																...filters,
																status: '',
															})
														}
													>
														<Close fontSize="small" />
													</IconButton>
												) : (
													''
												)
											}
										>
											<MenuItem value="paid">Paid</MenuItem>
											<MenuItem value="failed">Failed</MenuItem>
										</Select>
									</FormControl>
								</div>

								<div style={{ width: '24%', marginRight: '15px' }}>
									<CustomDatePicker
										label="Date From"
										value={dateFrom}
										setValue={(d) => setdateFrom(d?.format('YYYY-MM-DD') || '')}
										disableFuture
										maxDate={dateTo || undefined}
									/>
								</div>
								<div style={{ width: '24%' }}>
									<CustomDatePicker
										label="Date To"
										value={dateTo}
										setValue={(d) => setdateTo(d?.format('YYYY-MM-DD') || '')}
										disableFuture
										minDate={dateFrom || undefined}
									/>
								</div>
							</div>
						</div>
					</div>

					<TableContainer id="Scrollable-table" component={Paper} className="company-table-cont">
						<div style={{ display: 'none' }}>
							<div ref={TransactionReportRef} className="p-32">
								<div className="flex flex-row justify-between">
									<div>
										<img src="assets/images/logos/logo22.png" alt="" />
									</div>
									<div style={{ textAlign: 'right' }}>
										<Typography variant="subtitle1">
											<span style={{ display: 'block', marginBottom: '-0.7em', writingMode: '' }}>
												642 Glan Eagles Street
											</span>{' '}
											<span>Hom Lake, Orlando, FL 38637</span>
										</Typography>
										<Typography variant="subtitle1">+1 365 986 1258</Typography>
										<Typography variant="subtitle1">perfectday@gmail.com</Typography>
									</div>
								</div>
								<div style={{ marginBottom: '20px' }}>
									<h3>
										<strong>Schedules</strong>
									</h3>
									<span>
										<strong>Date: </strong>
									</span>
									<span>{moment(date).format('dddd, DD MMMM YYYY')}</span>
								</div>
								<div className="pdf-heading">
									<h1 className="font-extrabold" style={{ fontSize: '20px' }}>
										Transaction
									</h1>
								</div>

								<Table stickyHeader className="company-table" style={{ width: '100%' }}>
									<TableHead>
										<TableRow>
											<TableCell style={{ width: '25%' }} className="bg-white companyTableHeader">
												School Name
											</TableCell>
											<TableCell style={{ width: '25%' }} className="bg-white companyTableHeader">
												Active Subscriber
											</TableCell>
											<TableCell style={{ width: '25%' }} className="bg-white companyTableHeader">
												Total Amount
											</TableCell>
											<TableCell style={{ width: '25%' }} className="bg-white companyTableHeader">
												Status
											</TableCell>
											<TableCell style={{ width: '25%' }} className="bg-white companyTableHeader">
												Date
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody className="">
										{isLoading ? (
											<TableRow>
												<TableCell align="center" colSpan={8}>
													{/* <CircularProgress /> */}
												</TableCell>
											</TableRow>
										) : !rows.length && !firstLoad ? (
											<TableRow>
												<TableCell align="center" colSpan={8}>
													No transactions
												</TableCell>
											</TableRow>
										) : (
											rows?.map((row) => (
												<TableRow key={row.id}>
													<TableCell style={{ fontWeight: 700 }} component="th" scope="row">
														<div className="flex items-center">
															<Avatar
																className="mr-6"
																alt="student-face"
																src={row.school?.logo}
															/>
															<div className="company-name truncate add-width">
																{row.school.name}
															</div>
														</div>
													</TableCell>
													<TableCell
														style={{
															fontWeight: '700',
															fontSize: '12px',
															color: '#06071d',
														}}
														component="th"
														scope="row"
													>
														{row.school.school_subscriptions?.length
															? row.school.school_subscriptions?.length
															: '0'}
													</TableCell>
													<TableCell style={{ fontWeight: 700 }} component="th" scope="row">
														<div className="flex items-center">
															<div className="company-name truncate">
																<div className="w-1/4 relative">
																	${row.amount_payed_out}
																</div>
															</div>
														</div>
													</TableCell>
													<TableCell style={{ fontWeight: 700 }} component="th" scope="row">
														<div className="flex items-center">
															<div
																className="company-name truncate"
																style={{
																	color: `${
																		row?.status === 'paid' ? '#04C01C' : '#FF4B4B'
																	}`,
																}}
															>
																{row?.status}
															</div>
														</div>
													</TableCell>
													<TableCell style={{ fontWeight: 700 }} component="th" scope="row">
														<div className="flex flex-col">
															<div
																style={{
																	fontSize: '12px',
																	fontWeight: '600',
																	color: '#000',
																}}
															>
																{moment.utc(row?.created_at).local().format('L')}
															</div>
															<div className="subscribe-date">
																{moment.utc(row?.created_at).local().format('LT')}
															</div>
														</div>{' '}
													</TableCell>
												</TableRow>
											))
										)}
										{fetchingMore ? (
											<TableRow>
												<TableCell align="center" colSpan={8}>
													<CircularProgress />
												</TableCell>
											</TableRow>
										) : (
											<></>
										)}
									</TableBody>
								</Table>
							</div>
						</div>

						<Table stickyHeader className="company-table" style={{ width: '100%' }}>
							<TableHead>
								<TableRow>
									<TableCell style={{ width: '25%' }} className="bg-white companyTableHeader">
										School Name
									</TableCell>
									<TableCell style={{ width: '25%' }} className="bg-white companyTableHeader">
										Active Subscriber
									</TableCell>
									<TableCell style={{ width: '25%' }} className="bg-white companyTableHeader">
										Total Amount
									</TableCell>
									<TableCell style={{ width: '25%' }} className="bg-white companyTableHeader">
										Status
									</TableCell>
									<TableCell style={{ width: '25%' }} className="bg-white companyTableHeader">
										Date
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody className="">
								{isLoading ? (
									<TableRow>
										<TableCell align="center" colSpan={8}>
											<CircularProgress />
										</TableCell>
									</TableRow>
								) : !rows.length && !firstLoad ? (
									<TableRow>
										<TableCell align="center" colSpan={8}>
											No transactions
										</TableCell>
									</TableRow>
								) : (
									rows?.map((row, index) => (
										<TableRow key={row.id}>
											<TableCell style={{ fontWeight: 700 }} component="th" scope="row">
												<div className="flex items-center">
													<Avatar
														className="mr-6"
														alt="student-face"
														src={row.school?.logo}
													/>
													<div className="company-name truncate add_width">
														{row.school.name}
													</div>
												</div>
											</TableCell>
											<TableCell
												style={{ fontWeight: '700', fontSize: '12px', color: '#06071d' }}
												component="th"
												scope="row"
											>
												{row.school.school_subscriptions?.length
													? row.school.school_subscriptions?.length
													: '0'}
											</TableCell>
											<TableCell style={{ fontWeight: 700 }} component="th" scope="row">
												<div className="flex items-center">
													<div className="company-name truncate">
														<div className="w-1/4 relative">${row.amount_payed_out}</div>
													</div>
												</div>
											</TableCell>
											<TableCell style={{ fontWeight: 700 }} component="th" scope="row">
												<div className="flex items-center">
													<div
														className="company-name payout-status truncate flex justify-between items-center"
														style={{
															color: `${row?.status === 'paid' ? '#04C01C' : '#FF4B4B'}`,
														}}
													>
														{row?.status}
														{row?.status === 'failed' ? (
															rowLoader[index] ? (
																<div style={{ marginRight: 20 }}>
																	<CircularProgress size={20} />
																</div>
															) : (
																<button
																	style={{
																		width: '77px',
																		height: '31px',
																		background:
																			'transparent linear-gradient(248deg, #FFFFFF 0%, #CCE0FF 100%) 0% 0% no-repeat padding-box',
																		borderRadius: '22px',
																		color: '#5B81F0',
																		textTransform: 'capitalize',
																		marginLeft: '41px',
																		border: '1px solid #6372F4',
																	}}
																	onClick={() => handleManualPayout(row, index)}
																>
																	resend
																</button>
															)
														) : (
															' '
														)}
													</div>
												</div>
											</TableCell>
											<TableCell style={{ fontWeight: 700 }} component="th" scope="row">
												<div className="flex flex-col">
													<div style={{ fontSize: '12px', fontWeight: '600', color: '#000' }}>
														{moment.utc(row?.created_at).local().format('L')}
													</div>
													<div className="subscribe-date">
														{moment.utc(row?.created_at).local().format('LT')}
													</div>
												</div>{' '}
											</TableCell>
										</TableRow>
									))
								)}
								{fetchingMore ? (
									<TableRow>
										<TableCell align="center" colSpan={8}>
											<CircularProgress />
										</TableCell>
									</TableRow>
								) : (
									<></>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<InfiniteScroll
						dataLength={rows.length}
						next={handleLoadMore}
						hasMore={hasMore}
						scrollableTarget="Scrollable-table"
					/>
				</div>
			</FuseAnimate>
		</>
	);
}
export default Finance;
