import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CheckCircle, Close } from "@material-ui/icons";
import GradientButton from "../GradientButton";
import { Paper } from "@material-ui/core";
import StyledInput from "../StyledInput";

const passwordValidationRules = {
  required: "Password is required",
  minLength: {
    value: 8,
    message: "Password must be at least 8 characters",
  },
  validate: {
    hasUpperCase: (value) =>
      /[A-Z]/.test(value) ||
      "Password must contain at least one uppercase letter",
    hasLowerCase: (value) =>
      /[a-z]/.test(value) ||
      "Password must contain at least one lowercase letter",
    hasNumber: (value) =>
      /\d/.test(value) || "Password must contain at least one number",
    hasSpecialChar: (value) =>
      /[!@#$%^&*(),.?":{}|<>]/.test(value) ||
      "Password must contain at least one special character",
  },
};

const ResetPassword = ({ onSuccess, onError, onResetPassword }) => {
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      password: "",
      password_confirmation: "",
    },
  });

  const password = watch("password");

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      await onResetPassword({
        password: data.password,
        password_confirmation: data.password_confirmation,
      });

      onSuccess?.("Password has been reset successfully");
      history.push("/login");
    } catch (error) {
      onError?.(
        error.response?.data?.message ||
          "Failed to reset password. Please try again."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const requirements = [
    {
      label: "At least 8 characters long",
      met: password?.length >= 8,
    },
    {
      label: "Contains at least one uppercase letter",
      met: /[A-Z]/.test(password),
    },
    {
      label: "Contains at least one lowercase letter",
      met: /[a-z]/.test(password),
    },
    {
      label: "Contains at least one number",
      met: /\d/.test(password),
    },
    {
      label: "Contains at least one special character",
      met: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    },
  ];

  return (
    <div className="min-h-screen flex items-center justify-center p-4 bg-[url('/assets/images/banner/bg.png')] bg-no-repeat bg-center">
      <Paper className="max-w-[400px] w-full rounded-3xl p-24 flex flex-col gap-20 items-center">
        <img
          alt="Logo"
          className="w-[150px]"
          src="assets/images/logos/login-logo.png"
        />

        <h1 className="text-4xl font-bold font-proxima mb-40">
          Reset Your Password
        </h1>

        <form
          className="w-full flex flex-col gap-20"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="w-full flex flex-col gap-60">
            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <StyledInput
                  {...field}
                  error={!!errors.password}
                  helperText={errors.password?.message}
                  icon={
                    <IconButton
                      edge="end"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  }
                  label="New Password"
                  placeholder="Enter new password"
                  type={showPassword ? "text" : "password"}
                />
              )}
              rules={passwordValidationRules}
            />

            <Controller
              control={control}
              name="password_confirmation"
              render={({ field }) => (
                <StyledInput
                  {...field}
                  error={!!errors.password_confirmation}
                  helperText={errors.password_confirmation?.message}
                  icon={
                    <IconButton
                      edge="end"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    >
                      {showConfirmPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  }
                  label="Confirm Password"
                  placeholder="Confirm your password"
                  type={showConfirmPassword ? "text" : "password"}
                />
              )}
              rules={{
                required: "Please confirm your password",
                validate: (value) =>
                  value === password || "Passwords do not match",
              }}
            />
          </div>
          <div className="mx-auto">
            <GradientButton disabled={isSubmitting} type="submit">
              {isSubmitting ? (
                <CircularProgress color="inherit" size={24} />
              ) : (
                "Reset Password"
              )}
            </GradientButton>
          </div>
          {/* Password Requirements Checklist */}
          <div className="mt-4 bg-gray-50 rounded-lg p-8">
            <ul className="space-y-2">
              {requirements.map((requirement, index) => (
                <li className="flex items-center text-14" key={index}>
                  <span className="mr-12">
                    {requirement.met ? (
                      <span className="text-green-500">
                        <CheckCircle className="size-16" />
                      </span>
                    ) : (
                      <span className="text-red-500">
                        <Close className="size-16" />
                      </span>
                    )}
                  </span>
                  <span
                    className={
                      requirement.met ? "text-gray-900" : "text-gray-500"
                    }
                  >
                    {requirement.label}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </form>
      </Paper>
    </div>
  );
};

export default withRouter(ResetPassword);
