import { XMarkIcon } from "@heroicons/react/24/outline";
import { CircularProgress, Fade, Modal } from "@material-ui/core";
import BulkActionButton from "@shared/components/BulkActionButton";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getImageUrl } from "../../helpers/utils";
import { uploadFile } from "../../services/imageUpload";
import {
  addDocumentToUser,
  getUserById,
  updateUser,
  deleteDocumentFromUser,
} from "../../services/users";
import UserForm from "./UserForm";

const EditUserModal = ({
  currentUser,
  open,
  setOpen,
  user,
  isOwner,
  schools,
  companies,
  actions,
  allowedSchools,
  dispatch,
  setRefresh,
}) => {
  const [loading, setLoading] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const [documentsToDelete, setDocumentsToDelete] = useState([]);
  const [hasFormChanges, setHasFormChanges] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [lockedSchools, setLockedSchools] = useState([]);
  useEffect(() => {
    const loadUserData = async () => {
      if (!user?.id || !open) return;

      setLoading(true);
      try {
        const response = await getUserById(user.id);
        const userData = response.data;
        setLockedSchools(userData?.locked_schools);
        const transformedData = {
          first_name: userData.first_name,
          last_name: userData.last_name,
          email: userData.email,
          phone: userData.phone,
          address: userData.address,
          address2: userData.address2,
          city: userData.city,
          state_id: userData.state_id,
          zip_code: userData.zip_code,
          country_code: userData.country_code,
          // TODO: add user_group after custom roles are implemented
          // user_group: userData.user_group,
          role: userData.role,
          photo: userData.photo,
          schools:
            userData.schools?.map((school) => {
              console.log("school", school);
              return school.id;
            }) || [],
          permissions: userData.permissions?.map((perm) => perm.id) || [],
          documents:
            userData.documents?.map((doc) => {
              return {
                id: doc.id,
                file_name: doc.file_name,
                url: doc.url,
                type: doc.file_name.toLowerCase().endsWith(".pdf")
                  ? "application/pdf"
                  : "image/*",
                isExisting: true,
              };
            }) || [],
        };

        reset(transformedData);
        setLoading(false);
      } catch (error) {
        console.error("Error loading user data:", error);
        dispatch(
          actions.showMessage({
            message: "Error loading user data",
            variant: "error",
          })
        );
        setLoading(false);
      }
    };

    loadUserData();
  }, [user?.id, open, reset, actions, dispatch]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      setSubmitting(true);
      const changes = {};
      if (data.start_date) {
        const formattedDate = new Date(data.start_date)
          .toISOString()
          .split("T")[0];
        if (formattedDate !== user.start_date) {
          changes.start_date = formattedDate;
        }
      }

      Object.keys(data).forEach((key) => {
        // Skip fields that are handled separately
        if (
          key === "start_date" ||
          key === "permissions" ||
          key === "schools" ||
          key === "photo" ||
          key === "attachments" ||
          key === "documents"
        ) {
          return;
        }

        if (data[key] !== user[key] && data[key] !== undefined) {
          changes[key] = data[key];
        }
      });

      // Handle permissions
      const newPermissions = data.permissions || [];
      const oldPermissions = user.permissions?.map((p) => p.id) || [];
      if (JSON.stringify(newPermissions) !== JSON.stringify(oldPermissions)) {
        changes.permissions = newPermissions;
      }

      // Handle schools
      changes.schools = data.schools || [];

      // Handle file uploads only if new files were selected
      if (data.photo instanceof File) {
        const filename = getImageUrl(data.photo);
        let url = `${import.meta.env.VITE_S3_BASE_URL}`;
        url += await uploadFile(data.photo, filename);
        changes.photo = url;
        changes.thumb = url;
      }

      // Handle documents - only include new files
      const newFiles = data.documents?.filter((file) => !file.isExisting) || [];
      if (newFiles.length > 0) {
        // Upload new files after user update
        const uploadPromises = newFiles.map(async (file) => {
          const filename = getImageUrl(file);
          const uploadedUrl = await uploadFile(file, filename);
          return {
            url: `${import.meta.env.VITE_S3_BASE_URL}${uploadedUrl}`,
            file_name: file.name,
            type: file.type,
          };
        });

        // Only make the user update call if there are other changes
        if (Object.keys(changes).length > 0) {
          const mergedData = { ...user, ...changes };
          await updateUser(user.id, mergedData);
        }

        // Upload new documents
        const uploadedDocs = await Promise.all(uploadPromises);
        await Promise.all(
          uploadedDocs.map((doc) => addDocumentToUser(user.id, doc))
        );

        // Delete any marked documents
        if (documentsToDelete.length > 0) {
          await Promise.all(
            documentsToDelete.map((documentId) =>
              deleteDocumentFromUser(user.id, documentId)
            )
          );
        }

        setOpen(false);
        setRefresh((prev) => !prev);
        dispatch(
          actions.showMessage({
            message: "User updated successfully",
            variant: "success",
          })
        );
      } else {
        // No new files, just handle regular updates and deletions
        if (Object.keys(changes).length > 0) {
          const mergedData = { ...user, ...changes };
          await updateUser(user.id, mergedData);
        }

        // Handle any document deletions
        if (documentsToDelete.length > 0) {
          await Promise.all(
            documentsToDelete.map((documentId) =>
              deleteDocumentFromUser(user.id, documentId)
            )
          );
        }

        setOpen(false);
        setRefresh((prev) => !prev);
        if (Object.keys(changes).length > 0 || documentsToDelete.length > 0) {
          dispatch(
            actions.showMessage({
              message: "User updated successfully",
              variant: "success",
            })
          );
        }
      }
    } catch (error) {
      console.error("Error updating user:", error);
      dispatch(
        actions.showMessage({
          message: "Error updating user",
          variant: "error",
        })
      );
    } finally {
      setSubmitting(false);
    }
  });

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  return (
    <Modal
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
      onClose={handleClose}
      open={open}
    >
      <div
        className="fixed inset-0 flex items-center justify-center p-4 overflow-auto"
        onClick={(e) => {
          if (e.target === e.currentTarget) {
            handleClose();
          }
        }}
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            handleClose();
          }
        }}
        role="presentation"
      >
        <Fade in={open}>
          <div className="w-full max-w-[80vw] bg-white rounded-[46px] shadow-lg">
            <form className="flex flex-col max-h-[80vh]" onSubmit={onSubmit}>
              {/* Header */}
              <div className="border-b px-32 py-24 flex-shrink-0">
                <button
                  className="flex justify-between items-center w-full"
                  onClick={handleClose}
                  type="button"
                >
                  <XMarkIcon className="size-24" title="Close" />
                  <span className="text-24 font-bold">Edit User</span>
                  <span />
                </button>
              </div>
              <div className="flex-1 overflow-y-auto p-6">
                {loading ? (
                  <div className="flex items-center justify-center h-full">
                    <CircularProgress />
                  </div>
                ) : (
                  <UserForm
                    actions={actions}
                    allowedSchools={allowedSchools}
                    companies={companies}
                    control={control}
                    currentUser={currentUser}
                    dispatch={dispatch}
                    errors={errors}
                    isOwner={isOwner}
                    lockedSchools={lockedSchools}
                    onDocumentsToDelete={setDocumentsToDelete}
                    onFormChange={setHasFormChanges}
                    reset={reset}
                    schools={schools}
                    setRefresh={setRefresh}
                    setValue={setValue}
                    userId={user.id}
                  />
                )}
              </div>

              {/* Footer */}
              <div className="border-t px-32 py-24 flex-shrink-0">
                <div className="flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
                  <p className="text-gray-600 shrink-0">
                    {hasFormChanges
                      ? "Are you sure you want to save these changes?"
                      : "No changes have been made"}
                  </p>
                  <div className="flex gap-4 justify-end">
                    <BulkActionButton onClick={handleClose} type="button">
                      Cancel
                    </BulkActionButton>
                    <BulkActionButton
                      disabled={!hasFormChanges || submitting}
                      type="submit"
                      variant="primary"
                    >
                      Save
                    </BulkActionButton>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Fade>
      </div>
    </Modal>
  );
};

export default EditUserModal;
