import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Actions from 'app/store/actions';
import CompanyListDialog from 'app/fuse-layouts/layout1/components/CompanyListDialog';

const SelectCompany = () => {
	const dispatch = useDispatch();
	const company = useSelector(({ auth }) => auth.user.company);

	const showDialog = () => {
		dispatch(
			Actions.openDialog({
				children: <CompanyListDialog />,
			}),
		);
	};

	return (
		<div className="text-white text-transform max-w-[169px] mt-2 mx-auto">
			<div className="text-center">{company?.name}</div>
			<div>
				<button
					className="text-white w-full text-center border-white rounded-full py-6 px-2 border flex items-center justify-center hover:shadow-md"
					onClick={showDialog}
					type="button"
				>
					Select Company <i className="fas fa-angle-down ml-8" />
				</button>
			</div>
		</div>
	);
};

export default SelectCompany;
