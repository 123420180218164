import * as React from 'react';
const EditPhotoGradientIcon = (props) => (
	<svg fill="none" viewBox="0 0 40 39" xmlns="http://www.w3.org/2000/svg" {...props}>
		<rect fill="url(#edit-photo-gradient-a)" height={36.788} rx={18.394} width={38} x={1} y={1.211} />
		<rect height={36.788} rx={18.394} stroke="#fff" strokeWidth={2} width={38} x={1} y={1.211} />
		<path
			d="M30.875 8.897a3.333 3.333 0 0 0-2.33-.673c-.415.03-.82.137-1.19.316-.369.179-.696.426-.961.727L17.86 18.89a4.398 4.398 0 0 0-.916 1.56 4.21 4.21 0 0 0-.195 1.77c.02.292.105.576.246.837.142.26.339.49.578.677a2.558 2.558 0 0 0 1.613.538h.283c1.274-.106 2.455-.673 3.293-1.581l8.507-9.593c.534-.61.791-1.392.718-2.178a2.959 2.959 0 0 0-1.112-2.027l-.001.003Zm-9.537 12.678a3.079 3.079 0 0 1-.971.688c-.37.167-.77.263-1.18.283-.282 0-.501-.168-.537-.538-.034-.352.01-.706.126-1.042.117-.335.306-.645.555-.91l5.808-6.516a.234.234 0 0 1 .251-.034l1.722 1.346c.03.031.05.07.057.113a.196.196 0 0 1-.022.123l-5.809 6.487Zm8.497-9.587-1.294 1.483a.234.234 0 0 1-.251.034l-1.721-1.346a.196.196 0 0 1-.036-.236l1.29-1.48c.228-.248.548-.406.896-.44h.107c.299 0 .588.093.825.264.265.214.433.514.47.84a1.18 1.18 0 0 1-.286.885v-.004Z"
			fill="#fff"
		/>
		<path
			d="M25.71 30.999H12.732a4.999 4.999 0 0 1-1.811-.337 4.761 4.761 0 0 1-1.536-.962 4.43 4.43 0 0 1-1.026-1.442 4.2 4.2 0 0 1-.359-1.7V14.382a4.2 4.2 0 0 1 .359-1.7 4.43 4.43 0 0 1 1.026-1.441 4.76 4.76 0 0 1 1.536-.963 5 5 0 0 1 1.811-.336h6.991a.939.939 0 0 1 .344.061c.11.042.209.104.292.182.084.079.15.172.194.274a.789.789 0 0 1 .066.323.892.892 0 0 1-.076.36.939.939 0 0 1-.217.307 1.007 1.007 0 0 1-.326.204 1.057 1.057 0 0 1-.385.071h-6.883a2.979 2.979 0 0 0-1.086.198 2.837 2.837 0 0 0-.921.576 2.639 2.639 0 0 0-.614.864 2.5 2.5 0 0 0-.211 1.02v12.176a2.5 2.5 0 0 0 .21 1.02c.143.323.351.617.615.864.263.248.576.443.92.576.346.133.715.2 1.087.198H25.71c.373.002.742-.065 1.087-.198.344-.133.657-.328.92-.576.264-.247.473-.54.614-.864a2.5 2.5 0 0 0 .212-1.02v-6.286a.787.787 0 0 1 .065-.323.833.833 0 0 1 .194-.274.896.896 0 0 1 .292-.182.941.941 0 0 1 .344-.062h.111a.941.941 0 0 1 .345.062c.109.042.208.104.291.182.084.078.15.172.194.274a.788.788 0 0 1 .066.323v6.324c-.007 1.172-.51 2.294-1.397 3.12a4.885 4.885 0 0 1-3.338 1.283Z"
			fill="#fff"
		/>
		<defs>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="edit-photo-gradient-a"
				x1={33.76}
				x2={7.151}
				y1={33.724}
				y2={6.723}
			>
				<stop stopColor="#FED557" />
				<stop offset={1} stopColor="#F67E91" />
			</linearGradient>
		</defs>
	</svg>
);
export default EditPhotoGradientIcon;
