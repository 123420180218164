import React from "react";
import * as Actions from "@/app/store/actions";
import { Avatar } from "@material-ui/core";
import { useDispatch } from "react-redux";
import {
  bulkActivateUsers,
  bulkDeactivateUsers,
  bulkDeleteUsers,
  bulkResetPasswordUsers,
} from "../../services/users";
import BulkActionButton from "../BulkActionButton";
import ConfirmModalLayout from "./ConfirmModalLayout";

const UserActionModal = ({
  open,
  setOpen,
  type = "activate",
  selectedUsers,
  setRefresh,
  setSelectedUsers,
  setSelectedUsersIds,
}) => {
  const dispatch = useDispatch();

  const eligibleUsers = selectedUsers.filter((user) => {
    if (type === "delete" || type === "deactivate") {
      return !user.is_primary;
    }
    return true;
  });

  const primaryUsers = selectedUsers.filter((user) => user.is_primary);
  const hasPrimaryUsers = primaryUsers.length > 0;
  const skippedPrimaryMessage =
    hasPrimaryUsers && (type === "delete" || type === "deactivate")
      ? `${primaryUsers.length} primary user${primaryUsers.length > 1 ? "s" : ""} will be skipped for this action.`
      : null;

  const modalConfig = {
    activate: {
      title: "Activate Users",
      buttonText: "Activate",
      action: bulkActivateUsers,
      successMessage: "Successfully activated users.",
      errorMessage: "Failed to activate users.",
      confirmMessage: "Are you sure you want to activate these users?",
      buttonType: "confirm",
    },
    deactivate: {
      title: "Deactivate Users",
      buttonText: "Deactivate",
      action: bulkDeactivateUsers,
      successMessage: "Successfully deactivated users.",
      errorMessage: "Failed to deactivate users.",
      confirmMessage: "Are you sure you want to deactivate these users?",
      buttonType: "confirm",
    },
    delete: {
      title: "Delete Users",
      buttonText: "Delete",
      action: bulkDeleteUsers,
      successMessage: "Successfully deleted users.",
      errorMessage: "Failed to delete users.",
      confirmMessage:
        "This action cannot be undone. Are you sure you want to delete these users?",
      buttonType: "delete",
      warningMessage:
        "Deleting these users will permanently remove all their data.",
    },
    resetPassword: {
      title: "Reset Password",
      buttonText: "Reset Password",
      action: bulkResetPasswordUsers,
      successMessage: "Successfully reset passwords.",
      errorMessage: "Failed to reset passwords.",
      confirmMessage:
        "Are you sure you want to reset passwords for these users?",
      buttonType: "warning",
      additionalInfo:
        "Users will receive an email with instructions to set up their new password.",
    },
  }[type];

  const handleAction = () => {
    modalConfig
      .action({
        user_id: eligibleUsers.map((user) => user.id),
      })
      .then((res) => {
        if (res.data.success === false) {
          throw new Error(res.data.message || modalConfig.errorMessage);
        }
        dispatch(
          Actions.showMessage({
            message: res.data.message || modalConfig.successMessage,
            autoHideDuration: 1500,
            variant: "success",
          })
        );
        setOpen(false);
        setRefresh((prev) => prev + 1);
        setSelectedUsers([]);
        setSelectedUsersIds([]);
      })
      .catch((error) => {
        dispatch(
          Actions.showMessage({
            message: error.message || modalConfig.errorMessage,
            autoHideDuration: 1500,
            variant: "error",
          })
        );
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderContent = () => (
    <div className="flex flex-col gap-12 w-full">
      {modalConfig?.warningMessage ? (
        <div className="w-full p-12 bg-red-50 border border-red-200 rounded-lg text-red-600">
          {modalConfig?.warningMessage}
        </div>
      ) : null}
      {modalConfig?.additionalInfo ? (
        <div className="w-full p-12 bg-blue-50 border border-blue-200 rounded-lg">
          {modalConfig?.additionalInfo}
        </div>
      ) : null}
      {skippedPrimaryMessage ? (
        <div className="w-full p-12 bg-yellow-50 border border-yellow-200 rounded-lg text-yellow-800">
          {skippedPrimaryMessage}
        </div>
      ) : null}
      <div className="flex flex-col gap-4 w-full bg-white border border-gray-200 rounded-lg overflow-hidden">
        {selectedUsers?.map((user, index) => {
          const isLast = index === selectedUsers.length - 1;
          const isPrimary = user.is_primary;
          const isDisabled =
            isPrimary && (type === "delete" || type === "deactivate");

          return (
            <div
              className={`flex items-center gap-8 p-16 ${!isLast ? "border-b" : ""} ${
                isDisabled ? "opacity-50 bg-black/10" : ""
              }`}
              key={user.id}
            >
              <Avatar src={user.thumb} />
              <div className="flex-1">
                <p>{`${user?.first_name} ${user?.last_name}`}</p>
                <p>{user?.email}</p>
              </div>
              {isPrimary ? (
                <span className="text-12 bg-blue-100 text-blue-800 px-8 py-4 rounded">
                  Primary User
                </span>
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );

  // Disable action button if no eligible users
  const renderFooter = () => (
    <div className="flex flex-col gap-12 w-full md:flex-row items-center">
      <p className="text-gray-600 shrink-0">{modalConfig?.confirmMessage}</p>
      <div className="flex gap-12 justify-end w-full">
        <BulkActionButton onClick={handleClose} type="cancel">
          Cancel
        </BulkActionButton>
        <BulkActionButton
          disabled={eligibleUsers.length === 0}
          onClick={handleAction}
          type={modalConfig?.buttonType}
        >
          {modalConfig?.buttonText}
        </BulkActionButton>
      </div>
    </div>
  );

  return (
    <ConfirmModalLayout
      content={renderContent()}
      footer={renderFooter()}
      onClose={handleClose}
      open={open}
      title={modalConfig?.title}
    />
  );
};

export default UserActionModal;
