import { Tabs, withStyles } from "@material-ui/core";
import React from "react";

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    justifySelf: "center",
    height: "5px",
    borderRadius: "10px",
    backgroundColor: "#5B81F0",
    "& > span": {
      width: "100%",
      backgroundColor: "transparent",
    },
  },
  root: {
    backgroundColor: "#fff",
    minHeight: "68px",
    paddingTop: "14px",
    display: "flex",
    flexDirection: "column",
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

export default StyledTabs;
