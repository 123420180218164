import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, withStyles } from '@material-ui/core';
import dayjs from 'dayjs';
import React from 'react';
import FilterBubble from '@shared/Reports/FilterBubble';

const styles = {
	root: {
		'& .MuiTableCell-root': {
			paddingInline: 0,
			paddingBlock: '1rem',
		},
	},
};

const PrintTable = withStyles(styles)(Table);

const FinancialPrintViewTable = React.forwardRef(({ columns, data, filters, title = 'Report', exportType }, ref) => {
	const filtersToShow = ['transaction_id', 'parent_id', 'company_id', 'school_id'];

	const getPageMargins = () => {
		return `@page { margin: 0.75in 0.5in 0.75in 0.5in !important; }`;
	};

	return (
		<div ref={ref}>
			<style>{getPageMargins()}</style>
			<div className="flex justify-between">
				<div>
					<img alt="logo" className="max-w-full h-auto w-128" src="/assets/images/logos/logo.png" />
				</div>
			</div>
			<div>
				<h2>{title}</h2>
				<div className="flex items-center flex-wrap gap-10 my-10">
					<span>Applied Filters: </span>
					{filters.start_date && filters.end_date ? (
						<FilterBubble
							className="mr-2 mb-2"
							isPdf={exportType === 'pdf'}
							isPrint={exportType === 'print'}
							key="date-range"
							title={`${dayjs(filters.start_date).format('MM/DD/YY')} ~ ${dayjs(filters.end_date).format(
								'MM/DD/YY'
							)}`}
						/>
					) : null}
					{Object.keys(filters)
						?.filter(
							(filter) =>
								filter !== 'start_date' &&
								filter !== 'end_date' &&
								filters[filter] &&
								filtersToShow.includes(filter)
						)
						?.map((filter) => {
							const filterValues = Array.isArray(filters[filter]) ? filters[filter] : [filters[filter]];
							return filterValues.map((item) => {
								return (
									<FilterBubble
										className="mr-2 mb-2"
										isPdf={exportType === 'pdf'}
										isPrint={exportType === 'print'}
										key={`${filter}-${item.value || item}`}
										title={item.label || item}
									/>
								);
							});
						})}
				</div>
			</div>
			<TableContainer className="shadow-none" component={Paper} elevation={0}>
				<PrintTable size="small">
					<TableHead>
						<TableRow>
							{columns.map((column) => {
								const { align } = column || 'left';
								return (
									<TableCell align={align} className="text-10" key={column.id} padding="none">
										{column.label}
									</TableCell>
								);
							})}
						</TableRow>
					</TableHead>
					<TableBody>
						{data.map((row) => {
							return (
								<TableRow className="avoid-page-break" key={row.id}>
									{columns.map((column) => {
										const { align } = column;
										return (
											<TableCell align={align} className="py-0 pl-8" key={column.id} size="small">
												<span className="text-10">
													{column.printRender ? column.printRender(row) : column.render(row)}
												</span>
											</TableCell>
										);
									})}
								</TableRow>
							);
						})}
					</TableBody>
				</PrintTable>
			</TableContainer>
		</div>
	);
});

FinancialPrintViewTable.displayName = 'FinancialPrintViewTable';

export default FinancialPrintViewTable;
