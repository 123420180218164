/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import './Reports.css';
import FuseAnimate from '@fuse/core/FuseAnimate';
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import { useHistory } from 'react-router-dom';

const Reports = () => {
	const history = useHistory();
	return (
		<FuseScrollbars scrollToTopOnRouteChange>
			<FuseAnimate animation="transition.slideLeftIn" duration={600}>
				<div className="px-60 pt-60">
					<div className="mb-48 max-w-3xl mx-auto">
						<h3 className="font-bold mb-28" style={{ fontSize: '20px' }}>
							Reports
						</h3>
						<div className="flex flex-col md:grid grid-cols-2 gap-28">
							<div
								className="bg-white rounded room-reports-box cursor-pointer"
								onClick={() => history.push('/SchoolEnrollment')}
							>
								<h3>School Enrollment</h3>
								<p>Overview of all enrolled schools and their student counts.</p>
							</div>
							<div
								className="bg-white rounded room-reports-box cursor-pointer"
								onClick={() => history.push('/ParentEnrollment')}
							>
								<h3>Parent Enrollment</h3>
								<p>Summary of parent accounts and their connection status.</p>
							</div>

							<div
								className="bg-white rounded room-reports-box cursor-pointer"
								onClick={() => history.push('/RevenueReport')}
							>
								<h3>Revenue / Subscription Report</h3>
								<p>Overview of subscription revenue and active subscriptions.</p>
							</div>
							<div className="bg-white rounded room-reports-box cursor-pointer">
								<h3>Daily Users</h3>
								<p>Summary of daily platform usage and user activity.</p>
							</div>

							<div className="bg-white rounded room-reports-box cursor-pointer">
								<h3>Daily Live Stream Users</h3>
								<p>Overview of live streaming activity and viewer engagement.</p>
							</div>
							<div
								className="bg-white rounded room-reports-box cursor-pointer"
								onClick={() => history.push('/FinancialReport')}
							>
								<h3>Financial Report</h3>
								<p>Summary of all financial transactions and revenue.</p>
							</div>
							<div
								className="bg-white rounded room-reports-box cursor-pointer"
								onClick={() => history.push('/SubscriptionTransaction')}
							>
								<h3>Subscription Transaction</h3>
								<p>Details of subscription payments and processing status.</p>
							</div>
						</div>
					</div>
				</div>
			</FuseAnimate>
		</FuseScrollbars>
	);
};
export default Reports;
