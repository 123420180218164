/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/iframe-has-title */
import CustomButton from '@shared/components/CustomButton/CustomButton';
import { getMyCompanies } from 'app/services/Company/companyService';
import * as Actions from 'app/store/actions';
import { CircularProgress, TextField } from '@material-ui/core';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import jwtService from 'app/services/jwtService';

const CompanyListDialog = () => {
	const dispatch = useDispatch();
	const user = useSelector(({ auth }) => auth.user);
	const [isLoading, setIsLoading] = useState(false);
	const [hasMore, setHasMore] = useState(false);
	const [search, setSearch] = useState('');
	const [page, setPage] = useState(1);
	const [fetchingMore, setFetchingMore] = useState(false);
	const [rows, setRows] = useState([]);
	const [activeId, setActiveId] = useState(user.company?.id ?? null);
	const onlyActive = true;

	useEffect(() => {
		let isMounted = true;
		setIsLoading(true);
		getMyCompanies(1, onlyActive, search)
			.then((res) => {
				if (!isMounted) return;
				if (res.data.meta.last_page > res.data.meta.current_page) {
					setHasMore(true);
				} else {
					setHasMore(false);
				}
				setPage(res.data.meta.current_page);
				setRows(res.data.data);
				setIsLoading(false);
			})
			.catch(() => {
				if (!isMounted) return;
				setIsLoading(false);
				dispatch(
					Actions.showMessage({
						message: 'Failed to fetch data, please refresh',
						variant: 'error',
					})
				);
			});

		return () => {
			isMounted = false;
		};
	}, [search]);

	const handleLoadMore = () => {
		setFetchingMore(true);
		getMyCompanies(page + 1, onlyActive, search)
			.then((res) => {
				if (res.data.meta.last_page > res.data.meta.current_page) {
					setHasMore(true);
				} else {
					setHasMore(false);
				}
				setPage(res.data.meta.current_page);
				setRows(rows.concat(res.data.data));
				setFetchingMore(false);
			})
			.catch(() => {
				setFetchingMore(false);
				dispatch(
					Actions.showMessage({
						message: 'Failed to fetch data, please refresh',
						variant: 'error',
					})
				);
			});
	};

	const selectCompany = (company) => {
		if (!company) {
			dispatch(Actions.closeDialog());
			return;
		}
		// Get a single-use token
		jwtService.createSingleUseLogin().then((response) => {
			// redirect to product owner login page
			window.location = `${import.meta.env.VITE_ADMIN_APP_URL}/product-owner/login?token=${response.data.token}&hash=${response.data.hash}&school_id=${company.id}`;
		});
	};

	return (
		<div className="bg-white px-32 min-h-[450px] max-h-[480px] pt-[10px] min-[400px]:min-w-[370px] min-[500px]:min-w-[440px]">
			<div className="flex justify-between py-[10px] px-0 content-center items-center mx-auto font-bold w-[90%]">
				<div>
					<h1 className="text-[20px] font-bold my-[13.4px]">Select Company</h1>
				</div>

				<div>
					<i
						className="fas text-[20px]"
						onClick={() => dispatch(Actions.closeDialog())}
						style={{ cursor: 'pointer' }}
					/>
				</div>
			</div>
			<div>
				<TextField
					className="w-full"
					label="Filter by company name"
					onChange={(e) => setSearch(e.target.value)}
					value={search}
				/>
			</div>

			<InfiniteScroll dataLength={rows.length} hasMore={hasMore} height={200} next={handleLoadMore}>
				<div>
					{isLoading ? (
						<div className="w-1/12 mx-auto mt-16">
							<CircularProgress size={35} />
						</div>
					) : (
						<>
							{rows
								?.filter((company) => company.status)
								?.map((company, i, arr) => {
									return (
										<div
											className={company.id === activeId ? 'bg-grey-150' : 'bg-white'}
											key={company.id}
											onClick={() => setActiveId(company.id)}
											style={{
												cursor: 'pointer',
											}}
										>
											<div className="p-16 flex justify-between">
												<div className="w-[310px]">
													<h5 className="text-[14px]">{company.name}</h5>
												</div>
												<div
													className={`rounded-full flex justify-center items-center p-4 border-[1px] border-pd-blue-light w-[24px] h-[24px] ${company.id === activeId ? 'text-pd-blue-light' : 'text-white'}`}
												>
													<i className="fas fa-check" />
												</div>
											</div>
											{i !== arr.length - 1 ? <hr style={{ borderColor: 'lightgray' }} /> : null}
										</div>
									);
								})}
						</>
					)}
					{fetchingMore ? (
						<div className="w-1/12 mx-auto">
							<CircularProgress size={35} />
						</div>
					) : null}
				</div>
			</InfiniteScroll>

			<div className="w-full flex justify-center">
				{isLoading ? (
					<div className="w-1/12 mx-auto mt-24">
						<CircularProgress size={35} />
					</div>
				) : (
					<div className="mt-[25px]">
						<CustomButton
							height={38}
							onClick={() => selectCompany(rows.filter((row) => row.id === activeId)[0])}
							type="button"
							width={156}
						>
							View Company
						</CustomButton>
					</div>
				)}
			</div>
		</div>
	);
};

export default CompanyListDialog;
