import React from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Box, Fade, Modal } from "@material-ui/core";

const modalStyle = {
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "100%",
  height: "80vh",
  width: { xs: "90vw", sm: 400 },
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  pt: 0,
  px: 0,
  pb: 0,
  padding: 0,
};

const ConfirmModalLayout = ({
  open,
  onClose,
  title = "Confirm",
  content,
  contentTitle,
  footer,
}) => {
  return (
    <Modal onClose={onClose} open={open}>
      <Fade in={open}>
        <Box sx={modalStyle}>
          <div className="h-full flex flex-col rounded-lg border relative overflow-hidden">
            {/* Header */}
            <div className="flex justify-between items-center p-[2.4rem] border-b bg-white">
              <XMarkIcon className="size-24" onClick={onClose} title="Close" />
              <span className="font-bold">{title}</span>
              <span />
            </div>

            {/* Content */}
            <div className="flex flex-1 overflow-hidden">
              {/* Left Column */}
              <div className="flex-1 p-16 gap-8 flex flex-col bg-[#F9FAFB]">
                <p className="font-bold text-center">{contentTitle}</p>
                <div className="flex-1 overflow-auto">{content}</div>
              </div>
            </div>

            {/* Footer */}
            {footer ? (
              <div className="flex px-[2.4rem] py-[1.4rem] border-t justify-between bg-white shadow-[0_-3px_6px_-1px_rgba(0,0,0,0.1)]">
                {footer}
              </div>
            ) : null}
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ConfirmModalLayout;
