import { Checkbox, withStyles } from '@material-ui/core';
import React from 'react';

const CheckboxWithStyles = withStyles(() => {
	return {
		root: {
			padding: '0',
		},
	};
})(Checkbox);

const StyledCheckbox = (props) => {
	return <CheckboxWithStyles {...props} disableRipple />;
};

export default StyledCheckbox;
