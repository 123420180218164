import React from "react";
const BulkActionButton = ({
  type = "button",
  onClick = () => {},
  children,
  disabled,
  isLoading = false,
  ...props
}) => {
  return (
    <button
      className={`${disabled ? "bg-gray-300 text-gray-500 rounded-lg px-16 py-10" : type === "cancel" ? "text-gray-900 px-16 py-10 rounded-lg bg-grey-400" : "bg-pd-blue-dark text-white px-16 py-10 rounded-lg"}`}
      disabled={disabled || isLoading}
      onClick={onClick}
      // eslint-disable-next-line react/button-has-type
      type={type}
      {...props}
    >
      {children}
    </button>
  );
};

export default BulkActionButton;
