import * as React from 'react';
const DownloadIcon = (props) => (
	<svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M19.008 20.085H6.49a.49.49 0 1 1 0-.98h12.517a.49.49 0 0 1 0 .98h.001ZM12.748 16.761a.49.49 0 0 1-.49-.49V4.642a.49.49 0 1 1 .98 0v11.63a.49.49 0 0 1-.49.49Z"
			fill="#fff"
		/>
		<path
			d="M12.747 17.009a.49.49 0 0 1-.318-.117l-5.185-4.43a.49.49 0 1 1 .636-.745l4.842 4.137 4.133-4.111a.49.49 0 1 1 .69.695l-4.453 4.43a.489.489 0 0 1-.346.143v-.002Z"
			fill="#fff"
		/>
	</svg>
);
export default DownloadIcon;
