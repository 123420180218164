const EVENT_TYPES = {
	SUBSCRIPTION_CREATED: 'subscription_created',
	SUBSCRIPTION_CANCELLED: 'subscription_cancelled',
	PAYMENT_SUCCEEDED: 'payment_succeeded',
	PAYMENT_FAILED: 'payment_failed',
	RENEWAL_STATUS_CHANGED: 'renewal_status_changed',
	SUBSCRIPTION_EXPIRED: 'subscription_expired',
	CANCELLATION_ERROR: 'cancellation_error',
	TRANSACTION_IGNORED: 'transaction_ignored',
};

const eventMappings = {
	android: {
		[EVENT_TYPES.SUBSCRIPTION_CREATED]: ['StripeSubscription::create', 'newSubscription', 'subscribeFreePackage'],
		[EVENT_TYPES.PAYMENT_SUCCEEDED]: ['invoice.payment_succeeded'],
		[EVENT_TYPES.PAYMENT_FAILED]: ['invoice.payment_failed'],
		[EVENT_TYPES.SUBSCRIPTION_CANCELLED]: ['cancelFreePackage', 'cancelStripePackages'],
		[EVENT_TYPES.SUBSCRIPTION_CANCELLED]: [
			'cancelFreePackage',
			'cancelStripePackages|returnTrue',
			'data|object|customer|cancelByPortal',
			'data|object|customer',
		],
		[EVENT_TYPES.CANCELLATION_ERROR]: ['cancelStripePackages|Exception|returnFalse'],
	},
	ios: {
		[EVENT_TYPES.SUBSCRIPTION_CREATED]: ['DidRenew', 'IAPController@payment'],
		[EVENT_TYPES.PAYMENT_FAILED]: ['DidFailToRenew'],
		[EVENT_TYPES.SUBSCRIPTION_EXPIRED]: ['Expired'],
		[EVENT_TYPES.TRANSACTION_IGNORED]: ['IAPController@TransactionIgnored', 'DidRenew Ignored'],
		[EVENT_TYPES.RENEWAL_STATUS_CHANGED]: ['DidChangeRenewalStatus'],
	},
};

const eventLabels = {
	[EVENT_TYPES.SUBSCRIPTION_CREATED]: 'Subscription Created',
	[EVENT_TYPES.SUBSCRIPTION_CANCELLED]: 'Subscription Cancelled',
	[EVENT_TYPES.PAYMENT_SUCCEEDED]: 'Payment Successful',
	[EVENT_TYPES.PAYMENT_FAILED]: 'Payment Failed',
	[EVENT_TYPES.RENEWAL_STATUS_CHANGED]: 'Renewal Status Changed',
	[EVENT_TYPES.SUBSCRIPTION_EXPIRED]: 'Subscription Expired',
	[EVENT_TYPES.CANCELLATION_ERROR]: 'Cancellation Error',
	[EVENT_TYPES.TRANSACTION_IGNORED]: 'Transaction Ignored',
};

const matchesEvent = (mappedEvent, event) => {
	// For events with parameters (separated by |), only compare the base event
	if (mappedEvent.includes('|')) {
		const [baseEvent] = mappedEvent.split('|');
		return event === baseEvent;
	}
	return event === mappedEvent;
};

const getEventType = (event, provider) => {
	// Normalize provider key
	const providerKey = provider?.toLowerCase();
	const mapping = eventMappings[providerKey];

	if (!mapping) {
		return event; // Return original event if provider not found
	}

	// Find matching event type
	for (const [type, events] of Object.entries(mapping)) {
		if (events.some((mappedEvent) => matchesEvent(mappedEvent, event))) {
			return type;
		}
	}

	return event; // Return original event if no match found
};

export const formatEventName = (event, provider) => {
	const eventType = getEventType(event, provider);
	return eventLabels[eventType] || event;
};
