import axios from '@shared/axios-shared';

const getSchools = (search, from, to, page, sort) => {
	return axios.get(`/api/v1/admin/school?search=${search}&from=${from}&to=${to}&page=${page}&sort=${sort}`);
};

const getSchool = (id) => {
	return axios.get(`/api/v1/admin/school/${id}`);
};

const getCompanySchools = (search, from, to, page, sort, isSchoolAdmin = false) => {
	return axios.get(
		`/api/v1/${
			isSchoolAdmin ? 'admin' : 'company'
		}/school?search=${search}&from=${from}&to=${to}&page=${page}&sort=${sort}`,
	);
};

const getMyCompanies = (search, page = 1) => {
	return axios.get('/api/v2/companies', { params: { name: search, page: page } });
};

const getMySchools = (search, page = 1, company_id = null) => {
	return axios.get('/api/v2/schools/list', { params: { name: search, page, company_id } });
};

export { getCompanySchools, getSchool, getSchools, getMyCompanies, getMySchools };
