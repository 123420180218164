import React from "react";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Popover,
  TextField,
  withStyles,
  InputAdornment,
} from "@material-ui/core";
import { ArrowDropDown, Error } from "@material-ui/icons";
import { forwardRef, useEffect, useRef, useState } from "react";
import SearchIcon from "../icons/SearchIcon";

const StyledFormControl = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#5E6368",
      borderColor: "#5E6368",
      transform: "translate(0px, -24px) ",
    },
    "& label.MuiFormLabel-filled": {
      transform: "translate(0px, -24px) ",
    },

    "& .MuiInputLabel-formControl": {
      transform: "translate(0px, -24px) ",
    },

    "& .MuiOutlinedInput-root": {
      background: "#F9F9F9",
      color: "#5E6368",
      cursor: "pointer",
      paddingBlock: "2.5px",
      "&.Mui-focused": {
        background: "#F1F5FE",
      },
      "&.Mui-focused fieldset ": {
        borderColor: "#3339FF",
        borderWidth: "1px",
      },
      borderColor: "#5E6368",
      borderRadius: "10px",
      "&.Mui-error": {
        background: "#FEF1F1",
        borderColor: "#EF2C2C",
      },

      "& fieldset": {
        top: 0,
      },

      "& legend": {
        display: "none",
      },
    },

    "& .MuiFormHelperText-root": {
      marginLeft: "0",
      color: "#EF2C2C",
      fontSize: "14px",
    },
    "& input": {
      caretColor: "#3339FF",
      color: "#5E6368",
      cursor: "pointer",
      "&::placeholder": {
        opacity: 1,
        color: "#9e9e9e",
        fontWeight: 400,
      },
    },
  },
})(FormControl);
const styles = {
  root: {
    border: "none",
  },
};
const textFieldStyles = {
  root: {
    "& label.Mui-focused": {
      color: "#5E6368",
      borderColor: "#5E6368",
      transform: "translate(0px, -24px) ",
    },
    "& label.MuiFormLabel-filled": {
      transform: "translate(0px, -24px) ",
    },

    "& .MuiInputLabel-formControl": {
      transform: "translate(0px, -24px) ",
    },

    "& .MuiOutlinedInput-root": {
      background: "#F9F9F9",
      color: "#5E6368",
      paddingBlock: "2.5px",
      "&.Mui-focused": {
        background: "#F1F5FE",
      },
      "&.Mui-focused fieldset ": {
        borderColor: "#3339FF",
        borderWidth: "1px",
      },
      borderColor: "#5E6368",
      borderRadius: "10px",
      "&.Mui-error": {
        background: "#FEF1F1",
        borderColor: "#EF2C2C",
      },

      "& fieldset": {
        top: 0,
      },
      "& legend": {
        display: "none",
      },
    },

    "& .MuiFormHelperText-root": {
      marginLeft: "0",
      color: "#EF2C2C",
      fontSize: "14px",
    },
    "& input": {
      caretColor: "#3339FF",
    },
  },
};
const CustomCombobox = withStyles(styles)(TextField);
const CustomTextField = withStyles(textFieldStyles)(TextField);

const StyledSearchSelect = forwardRef(
  (
    {
      label,
      options,
      helperText = "Required",
      error = false,
      showErrorIcon = true,
      placeholder,
      setControlledQuery,
      resultsLoading = false,
      multi = false,
      ...props
    },
    ref
  ) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const filteredOptions = options?.filter((option) =>
      option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const searchInputRef = useRef(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
      setSearchTerm("");
      setHighlightedIndex(-1);
    };

    const handleOptionSelect = (option) => {
      if (multi) {
        const currentSelections = Array.isArray(props.value) ? props.value : [];
        const isSelected = currentSelections.some(
          (item) => item.value === option.value
        );

        const newSelections = isSelected
          ? currentSelections.filter((item) => item.value !== option.value)
          : [...currentSelections, option];

        props.onChange(newSelections);
      } else {
        props.onChange({ target: { value: option.value } });
        handleClose();
      }
    };

    const handleKeyDown = (event) => {
      if (!open) return;

      switch (event.key) {
        case "ArrowDown":
          event.preventDefault();
          setHighlightedIndex((prevIndex) =>
            prevIndex < filteredOptions.length - 1 ? prevIndex + 1 : prevIndex
          );
          break;
        case "ArrowUp":
          event.preventDefault();
          setHighlightedIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : prevIndex
          );
          break;
        case "Enter":
          event.preventDefault();
          if (highlightedIndex !== -1) {
            handleOptionSelect(filteredOptions[highlightedIndex]);
          }
          break;
        case "Escape":
          event.preventDefault();
          handleClose();
          break;
        default:
          break;
      }
    };

    const open = Boolean(anchorEl);

    useEffect(() => {
      if (open) {
        setTimeout(() => {
          if (searchInputRef.current) {
            searchInputRef.current.focus();
          }
        }, 100);
      }
    }, [open]);

    useEffect(() => {
      setHighlightedIndex(-1);
    }, [searchTerm]);

    const renderValue = () => {
      if (!props.value) return "";

      if (multi) {
        const selections = Array.isArray(props.value) ? props.value : [];
        return selections.length ? `${selections.length} selected` : "";
      }

      return (
        options.find((option) => option.value === props.value)?.label || ""
      );
    };

    return (
      <StyledFormControl error={error} size="small" variant="outlined">
        <InputLabel shrink={true}>{label}</InputLabel>
        <CustomTextField
          {...props}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <ArrowDropDown />
              </InputAdornment>
            ),
          }}
          error={error}
          inputRef={ref}
          onClick={handleClick}
          placeholder={placeholder}
          size="small"
          value={renderValue()}
          variant="outlined"
        />
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          className="max-h-256"
          onClose={handleClose}
          open={Boolean(anchorEl)}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div className="flex items-center gap-8 px-8 sticky top-0 bg-white py-2 pt-8 z-10">
            <SearchIcon className="w-20 h-20 ml-2" />
            <CustomCombobox
              InputProps={{
                disableUnderline: true,
                className: "",
              }}
              inputRef={searchInputRef}
              onChange={(e) => {
                if (setControlledQuery) {
                  setControlledQuery(e.target.value);
                }
                setSearchTerm(e.target.value);
              }}
              onKeyDown={handleKeyDown}
              placeholder="search"
              size="medium"
              value={searchTerm}
              variant="standard"
            />
          </div>
          {filteredOptions.map((option, index) => (
            <MenuItem
              key={option.value}
              onClick={() => handleOptionSelect(option)}
              style={{
                backgroundColor:
                  index === highlightedIndex ? "#f0f0f0" : "transparent",
                paddingBlock: 1,
                paddingInline: 4,
              }}
              value={option.value}
            >
              {multi ? (
                <Checkbox
                  checked={
                    Array.isArray(props.value)
                      ? props.value.some((item) => item.value === option.value)
                      : null
                  }
                  color="primary"
                  size="small"
                />
              ) : null}
              {option.label}
            </MenuItem>
          ))}
          {resultsLoading ? <MenuItem>Loading...</MenuItem> : null}
        </Popover>
        {error ? (
          <FormHelperText>
            {showErrorIcon ? <Error className="w-20 mr-2" /> : null}
            {helperText}
          </FormHelperText>
        ) : null}
      </StyledFormControl>
    );
  }
);

StyledSearchSelect.displayName = "StyledSearchSelect";

export default StyledSearchSelect;
