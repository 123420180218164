import { createContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'qs';
import * as Actions from '@/app/store/actions';
import { getUsers } from '@/app/services/users/users';
import StyledTabs from '@shared/components/StyledTabs';
import { StyledTab } from '@shared/components/StyledTab';
import UserManagementList from '@shared/components/user-management/UserManagementList';
import { PlusIcon } from '@heroicons/react/24/outline';
import AddUserModal from '@shared/components/user-management/AddUserModal';
import { createUser, getSchools, getAllCompaniesAdmin } from '@shared/services/users';
import axios from '@shared/axios-shared';

export const UserManagementContext = createContext();

const UserManagament = () => {
	const [selectedTab, setSelectedTab] = useState(0);
	const dispatch = useDispatch();
	const [filters, setFilters] = useState({
		status: { label: 'Active', value: true },
		room: [],
		search: '',
		school_id: [],
		page: 1,
		group: null,
		role: [],
	});
	const [rows, setRows] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [firstLoad, setFirstLoad] = useState(true);
	const [refresh, setRefresh] = useState(false);
	const [totalCount, setTotalCount] = useState(0);
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [selectedUsersIds, setSelectedUsersIds] = useState([]);
	const [openAddUserModal, setOpenAddUserModal] = useState(false);
	const [schools, setSchools] = useState([]);
	const [schoolsLoading, setSchoolsLoading] = useState(false);
	const [companies, setCompanies] = useState([]);
	const [companiesLoading, setCompaniesLoading] = useState(false);
	const [allowedSchools, setAllowedSchools] = useState([]);
	const currentUser = useSelector(({ auth }) => auth.user);

	const handleSearch = (value) => {
		setFilters((prev) => ({
			...prev,
			searchQuery: value,
			page: 1,
		}));
	};

	const serializeFilters = (filters) => {
		return qs.stringify(filters, { arrayFormat: 'brackets' });
	};

	const generateQueryParams = (filters) => {
		return serializeFilters({
			page: filters?.page,
			search: filters?.searchQuery,
			school_id: filters?.school_id?.length > 0 ? filters?.school_id?.map((item) => item?.value) : null,
			status: filters?.status?.value !== undefined ? (filters.status.value ? 1 : 0) : null,
			room: filters?.room?.length > 0 ? filters?.room?.map((item) => item?.value) : null,
			group: filters?.group ? filters?.group.value : null,
		});
	};

	useEffect(() => {
		setSchoolsLoading(true);
		getSchools(axios)
			.then((res) => {
				setSchools(res.data.data);
			})
			.catch(() => {
				dispatch(
					Actions.showMessage({
						message: 'Failed to fetch schools',
						variant: 'error',
					})
				);
			})
			.finally(() => {
				setSchoolsLoading(false);
			});
	}, []);

	useEffect(() => {
		setCompaniesLoading(true);
		getAllCompaniesAdmin(axios)
			.then((res) => {
				const sortedCompanies = res.data.data.sort((a, b) => a.name.localeCompare(b.name));
				setCompanies(sortedCompanies);
				setAllowedSchools(res.data.allowed_schools);
			})
			.catch(() => {
				dispatch(
					Actions.showMessage({
						message: 'Failed to fetch companies',
						variant: 'error',
					})
				);
			})
			.finally(() => {
				setCompaniesLoading(false);
			});
	}, []);

	// Load students
	useEffect(() => {
		let controller;
		let isMounted = true;
		setIsLoading(true);
		const timeout = setTimeout(
			() => {
				controller = new AbortController();
				setRows([]);
				getUsers(generateQueryParams(filters), {
					signal: controller.signal,
				})
					.then((res) => {
						if (!isMounted) return;
						setFirstLoad(false);
						setRows(res.data.data || []);
						setTotalCount(res.data.meta.total);
						setIsLoading(false);
					})
					.catch((err) => {
						if (!isMounted) return;
						if (err.code === 'ERR_CANCELED') return;
						setRows([]);
						setTotalCount(0);
						dispatch(
							Actions.showMessage({
								message: 'Failed to fetch data, please refresh',
								variant: 'error',
							})
						);
						setIsLoading(false);
					});
			},
			firstLoad ? 0 : 1000
		);
		return () => {
			isMounted = false;
			clearTimeout(timeout);
			controller?.abort();
		};
	}, [refresh, filters]);

	const handleTabChange = (e, value) => {
		setSelectedTab(value);
	};

	const handleCreateUser = async (formData) => {
		try {
			await createUser(formData, axios);
			dispatch(
				Actions.showMessage({
					message: 'User created successfully',
					variant: 'success',
				})
			);
			setOpenAddUserModal(false);
			setRefresh((prev) => prev + 1);
		} catch (error) {
			console.error('Detailed error:', error);
			dispatch(
				Actions.showMessage({
					message: error.response?.data?.message || 'Error creating user',
					variant: 'error',
				})
			);
		}
	};
	return (
		<>
			<AddUserModal
				companies={companies}
				companiesLoading={companiesLoading}
				currentUser={currentUser}
				handleCreateUser={handleCreateUser}
				isOwner={true}
				open={openAddUserModal}
				schools={schools}
				schoolsLoading={schoolsLoading}
				setOpen={setOpenAddUserModal}
			/>
			<div className="bg-white">
				<div className=" w-full px-20 shadow-md bg-white relative z-10 justify-between">
					<StyledTabs className="" onChange={handleTabChange} value={selectedTab}>
						<StyledTab label="Users" />
						{/* <StyledTab label="User Role Manager" /> */}
					</StyledTabs>
					{/* TODO: Add upload users button */}
					<div className="flex items-center gap-10 bg-white shrink-0 absolute right-10 top-1/2 -translate-y-1/2">
						{/* <button className="flex items-center gap-2 px-12 py-10 rounded-md bg-[#F3F4F6]" type="button">
							<UploadIcon className="size-16" /> Upload Users
						</button> */}
						<button
							className="flex items-center gap-2 px-12 py-10 rounded-md !text-white bg-[#242424]"
							onClick={() => setOpenAddUserModal(true)}
							type="button"
						>
							<PlusIcon className="size-16" /> Add Users
						</button>
					</div>
				</div>

				<div className="relative z-0">
					{selectedTab === 0 ? (
						<UserManagementList
							actions={Actions}
							allowedSchools={allowedSchools}
							companies={companies}
							companiesLoading={companiesLoading}
							currentUser={currentUser}
							dispatch={dispatch}
							filters={filters}
							handleSearch={handleSearch}
							isLoading={isLoading}
							isOwner={true}
							refresh={refresh}
							rows={rows}
							schools={schools}
							schoolsLoading={schoolsLoading}
							selectedUsers={selectedUsers}
							selectedUsersIds={selectedUsersIds}
							setFilters={setFilters}
							setRefresh={setRefresh}
							setSelectedUsers={setSelectedUsers}
							setSelectedUsersIds={setSelectedUsersIds}
							totalCount={totalCount}
						/>
					) : null}
					{/* {selectedTab === 1 ? <UserRoleManager /> : null} */}
				</div>
			</div>
		</>
	);
};

export default UserManagament;
