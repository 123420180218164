import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  withStyles,
  FormHelperText,
} from "@material-ui/core";
import { Error } from "@material-ui/icons";
import React, { forwardRef } from "react";

const CssFormControl = withStyles({
  root: {
    "& .Mui-disabled": {
      cursor: "not-allowed",
    },
    "& label.Mui-focused": {
      color: "#5E6368",
      borderColor: "#5E6368",
      transform: "translate(0px, -24px) ",
    },
    "& label.MuiFormLabel-filled": {
      transform: "translate(0px, -24px) ",
    },

    "& .MuiInputLabel-formControl": {
      transform: "translate(0px, -24px) ",
    },

    "& .MuiOutlinedInput-root": {
      background: "#F9F9F9",
      color: "black",
      paddingBlock: "2.5px",
      "&.Mui-focused": {
        background: "#F1F5FE",
      },
      "&.Mui-focused fieldset ": {
        borderColor: "#3339FF",
        borderWidth: "1px",
      },
      borderColor: "#5E6368",
      borderRadius: "10px",
      "&.Mui-error": {
        background: "#FEF1F1",
        borderColor: "#EF2C2C",
      },

      "& fieldset": {
        top: 0,
      },
      "& legend": {
        display: "none",
      },
      "&.Mui-disabled": {
        background: "#e9e9e9",
        color: "#9e9e9e",
        cursor: "not-allowed",
      },
      "& .Mui-disabled ": {
        color: "#9e9e9e",
      },
    },

    "& .MuiFormHelperText-root": {
      marginLeft: "0",
      color: "#EF2C2C",
      fontSize: "14px",
    },
    "& input": {
      caretColor: "#3339FF",
      color: "black",
      "&::placeholder": {
        opacity: 1,
        color: "#9e9e9e",
        fontWeight: 400,
      },
    },
  },
})(FormControl);

const StyledSelect = forwardRef(
  (
    {
      label,
      options,
      helperText = "Required",
      error = false,
      showErrorIcon = true,
      placeholder,
      ...props
    },
    ref
  ) => {
    return (
      <CssFormControl error={error} size="small" variant="outlined">
        <InputLabel shrink={true}>{label}</InputLabel>
        <Select
          {...props}
          displayEmpty={true}
          inputProps={{ ref: ref, placeholder: placeholder }}
          label={label}
          renderValue={(val) => {
            return val !== "" ? (
              options.find((option) => option.value === val)?.label
            ) : (
              <span style={{ color: "#9e9e9e", fontWeight: "400" }}>
                {placeholder}
              </span>
            );
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {error ? (
          <FormHelperText>
            {showErrorIcon ? <Error className="w-20 mr-2" /> : null}
            {helperText}
          </FormHelperText>
        ) : null}
      </CssFormControl>
    );
  }
);

StyledSelect.displayName = "StyledSelect";
export default StyledSelect;
