import React from "react";
import BackArrow from "@shared/icons/BackArrow";
import ExportButton from "./ExportButton";
import PrintButton from "./PrintButton";

const ReportsHeader = ({
  onBack,
  title,
  subtitle,
  onPrint,
  onExport,
  printLoading,
  pdfLoading,
  onDownloadPDF,
  exportLoading,
  exportsDisabled,
  hideExport,
}) => {
  return (
    <div className="px-32 md:px-64 py-24 md:py-44 gap-16 flex justify-between items-start flex-col md:flex-row">
      <div className="flex items-start gap-32">
        <button
          className="w-32 align-baseline mt-8"
          onClick={onBack}
          type="button"
        >
          <BackArrow className="" />
        </button>
        <div className="flex flex-col gap-10">
          <h1 className="font-bold lg:text-20 xl:text-24 my-0">{title}</h1>
          <p className="lg:text-16 xl:text-20">{subtitle}</p>
        </div>
      </div>
      <div className="flex items-center gap-10 self-end">
        <PrintButton
          exportsDisabled={exportsDisabled}
          onClick={onPrint}
          printLoading={printLoading}
        />
        {!hideExport ? (
          <ExportButton
            exportLoading={exportLoading}
            exportsDisabled={exportsDisabled}
            onDownloadPDF={onDownloadPDF}
            onExport={onExport}
            pdfLoading={pdfLoading}
          />
        ) : null}
      </div>
    </div>
  );
};
export default ReportsHeader;
