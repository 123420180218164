import React from "react";
import { Tooltip, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  arrow: {
    color: "#0295FC",
  },
  tooltip: {
    backgroundColor: "#0295FC",
    fontSize: "13px",
    padding: "10px",
    whiteSpace: "pre-line",
  },
}));

const TableTooltip = ({ title, children }) => {
  const classes = useStyles();
  return (
    <Tooltip arrow classes={classes} interactive title={title || children}>
      {children}
    </Tooltip>
  );
};

export default TableTooltip;
