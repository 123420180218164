import { v4 as uuidv4 } from "uuid";

export const getImageUrl = (file) => {
  const file_Name = file.name;
  const fileExtension = file_Name.split(".").pop();
  const uuid = uuidv4();
  const newFileName = `${uuid}.${fileExtension}`;
  const filename = `web/public/profile_images/${newFileName}`;
  return filename;
};
