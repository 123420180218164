import * as React from 'react';
const BackArrow = (props) => (
	<svg fill="none" viewBox="0 0 33 25" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M12.29 0a1.4 1.4 0 0 0-.981.4L.409 11.338a1.382 1.382 0 0 0 0 1.962l10.9 10.938a1.452 1.452 0 0 0 1.962 0 1.382 1.382 0 0 0 0-1.962L4.695 13.7h26.454a1.381 1.381 0 0 0 0-2.762H4.695l8.539-8.576a1.383 1.383 0 0 0 0-1.962 1.261 1.261 0 0 0-.944-.4Z"
			fill="#000"
		/>
	</svg>
);
export default BackArrow;
