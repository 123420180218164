import FilterIcon from '@shared/icons/FilterIcon';
import { Divider } from '@material-ui/core';
import { useState } from 'react';
import PopoverInput from '@shared/Reports/PopoverInput';
import PopoverCombobox from '@shared/Reports/PopoverCombobox';
import PopoverDateRange from '@shared/Reports/PopoverDateRange';
import { getSubscribersList } from '@/app/services/reports/reports';
import { getMyCompanies, getMySchools } from '@/app/services/schools/schools';

const FinancialFiltersBar = ({ filters, setFilters }) => {
	const [companies, setCompanies] = useState([]);
	const [companiesLoading, setCompaniesLoading] = useState(false);
	const [schools, setSchools] = useState([]);
	const [schoolsLoading, setSchoolsLoading] = useState(false);
	const [subscribers, setSubscribers] = useState([]);
	const [subscribersLoading, setSubscribersLoading] = useState(false);
	const [searchTimeout, setSearchTimeout] = useState(null);

	const handleSearchCompanies = async (searchTerm) => {
		if (searchTimeout) {
			clearTimeout(searchTimeout);
		}

		const timeoutId = setTimeout(async () => {
			setCompaniesLoading(true);
			try {
				const res = await getMyCompanies(searchTerm);
				setCompanies(res.data.data.map((school) => ({ label: school.name, value: school.id })));
			} catch (error) {
				console.error('Failed to fetch companies', error);
			}
			setCompaniesLoading(false);
		}, 300);

		setSearchTimeout(timeoutId);
	};

	const handleSearchSchools = async (searchTerm) => {
		if (searchTimeout) {
			clearTimeout(searchTimeout);
		}

		const timeoutId = setTimeout(async () => {
			setSchoolsLoading(true);
			try {
				const res = await getMySchools(searchTerm);
				setSchools(res.data.data.map((school) => ({ label: school.name, value: school.id })));
			} catch (error) {
				console.error('Failed to fetch schools', error);
			}
			setSchoolsLoading(false);
		}, 300);

		setSearchTimeout(timeoutId);
	};

	const handleSearchParents = async (searchTerm) => {
		if (searchTimeout) {
			clearTimeout(searchTimeout);
		}

		const timeoutId = setTimeout(async () => {
			setSubscribersLoading(true);
			try {
				const res = await getSubscribersList({ search: searchTerm });
				setSubscribers(res.data.data.map((parent) => ({ label: parent.name, value: parent.id })));
			} catch (error) {
				console.error('Failed to fetch parents', error);
			}
			setSubscribersLoading(false);
		}, 300);

		setSearchTimeout(timeoutId);
	};

	return (
		<div
			className="text-white py-16 px-16 md:px-32 justify-between flex flex-wrap"
			style={{ background: 'rgb(0, 46,108)' }}
		>
			<div className="flex items-center gap-8 text-16">
				<FilterIcon /> <span>Filter:</span>
			</div>
			<div className="flex gap-8 md:gap-16 flex-wrap">
				<PopoverDateRange filters={filters} setFilters={setFilters} />
				<Divider className="bg-white" flexItem orientation="vertical" />
				<PopoverInput
					currentFilter="transaction_id"
					filters={filters}
					setFilter={setFilters}
					title="Transaction ID"
				/>
				<Divider className="bg-white" flexItem orientation="vertical" />
				<PopoverCombobox
					currentFilter="parent_id"
					filters={filters}
					loading={subscribersLoading}
					onSearch={handleSearchParents}
					options={subscribers}
					setFilter={setFilters}
					title="Parent"
				/>
				<Divider className="bg-white" flexItem orientation="vertical" />
				<PopoverCombobox
					currentFilter="company_id"
					filters={filters}
					loading={companiesLoading}
					onSearch={handleSearchCompanies}
					options={companies}
					setFilter={setFilters}
					title="Company"
				/>
				<Divider className="bg-white" flexItem orientation="vertical" />
				<PopoverCombobox
					currentFilter="school_id"
					filters={filters}
					loading={schoolsLoading}
					onSearch={handleSearchSchools}
					options={schools}
					setFilter={setFilters}
					title="School"
				/>
			</div>
		</div>
	);
};

export default FinancialFiltersBar;
