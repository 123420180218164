import { Close } from "@material-ui/icons";
import React from "react";

const FilterBubble = ({
  onClose,
  title,
  isPrint = false,
  isPdf = false,
  category = "",
}) => {
  return (
    <div
      className={` bg-white px-12 py-4 text-black rounded-full flex items-center gap-6 border border-gray-200 ${
        isPdf ? "transform translate-y-8" : ""
      }`}
    >
      <div
        className={`relative flex flex-col text-pd-sm px-8 ${isPdf ? "transform -translate-y-8" : ""}`}
      >
        <span className="text-pd-xs text-gray-700 leading-[8px]">
          {category}
        </span>
        <span className=" mt-2 font-semibold leading-[16px]">{title}</span>
      </div>
      {!isPrint && !isPdf ? (
        <button onClick={onClose} type="button">
          <Close className="text-gray-700 w-[16px]" />
        </button>
      ) : null}
    </div>
  );
};

export default FilterBubble;
