import qs from "qs";
import axios from "../axios-shared";

// When using shared services, pass in the axios instance to ensure axios defaults are updated with app-specific settings
export const getCompanies = async () => {
  return axios.get("/api/v2/owner/companies");
};

export const getSchools = async () => {
  return axios.get("/api/v2/owner/schools");
};

export const createUser = async (data) => {
  return axios.post("/api/v2/users", data);
};

export const getUsers = async (params) => {
  return axios.get(`/api/v2/users?${params}`);
};

/**
 * Fetches permissions for the current user
 * @param {?('admin'|'productowner')} app If provided, only permissions for the specified app will be returned
 * @param {?('sub_admin')} role If provided, only permissions for the specified role will be returned
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getPermissions = async (app = null, role = null) => {
  return axios.get("/api/v2/permissions", { params: { app, role } });
};

export const bulkActivateUsers = async (data) => {
  return axios.post("/api/v2/users/action/activate", data);
};

export const bulkDeactivateUsers = async (data) => {
  return axios.post("/api/v2/users/action/deactivate", data);
};

export const bulkDeleteUsers = async (data) => {
  const serializedParams = qs.stringify(data, { arrayFormat: "brackets" });
  return axios.delete(`/api/v2/users/action/delete?${serializedParams}`);
};

export const bulkResetPasswordUsers = async (data) => {
  return axios.post("/api/v2/users/action/password", data);
};

export const getUserById = async (id) => {
  return axios.get(`/api/v2/users/${id}`);
};

export const updateUser = async (id, data) => {
  return axios.patch(`/api/v2/users/${id}`, data);
};

export const addDocumentToUser = async (id, data) => {
  return axios.post(`/api/v2/users/${id}/document`, data);
};

export const deleteDocumentFromUser = async (id, documentId) => {
  return axios.delete(`/api/v2/users/${id}/document/${documentId}`);
};

export const getAllCompaniesAdmin = async () => {
  return axios.get(`/api/v2/schools`);
};
