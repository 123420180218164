import React from "react";
import DeleteRedGradIcon from "../../icons/DeleteRedGradIcon";
import EditYellowIcon from "../../icons/EditYellowIcon";
import { Avatar } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import SelectionBar from "./SelectionBar";
import UserFiltersBar from "./UserFiltersBar";
import UserAppliedFilters from "./UserAppliedFilters";
import UsersHeader from "./UserHeader";
import UsersTable from "./UsersTable";
import EditUserModal from "./EditUserModal";
import ConfirmUpdateOrDeleteModal from "./ConfirmUpdateOrDeleteModal";
import TableTooltip from "../TableTooltip";
import CustomSwitch from "@shared/components/CustomSwitch";

const UserManagementList = ({
  currentUser,
  actions,
  dispatch,
  filters,
  setFilters,
  setRefresh,
  handleSearch,
  rows,
  isLoading,
  allowedSchools,
  selectedUsers,
  setSelectedUsers,
  selectedUsersIds,
  setSelectedUsersIds,
  totalCount,
  companies,
  companiesLoading,
  schools,
  schoolsLoading,
  isOwner,
}) => {
  const [modalConfig, setModalConfig] = useState({
    open: false,
    user: null,
    action: "toggle",
  });
  const [singleUserToEdit, setSingleUserToEdit] = useState({});
  const [editUserOpen, setEditUserOpen] = useState(false);
  const headerRef = useRef(null);
  const [tableHeight, setTableHeight] = useState(0);

  const PAGINATION_HEIGHT = 50; // constant table pagination height
  const TABS_HEIGHT = 68; // constant navigation tabs height
  const HEADER_HEIGHT = 80.42; // constant header height

  useEffect(() => {
    const calculateTableHeight = () => {
      if (headerRef.current) {
        const tableHeaderHeight =
          headerRef.current.getBoundingClientRect().height;

        const windowHeight = window.innerHeight;

        // Subtract the spacing from the top of the viewport to get the available height
        const availableHeight =
          windowHeight -
          HEADER_HEIGHT -
          TABS_HEIGHT -
          tableHeaderHeight -
          PAGINATION_HEIGHT;
        setTableHeight(availableHeight);
      }
    };

    calculateTableHeight();
    window.addEventListener("resize", calculateTableHeight);

    return () => window.removeEventListener("resize", calculateTableHeight);
  }, []);

  const handleOpenStatusUpdatePrompt = (e, row) => {
    e.preventDefault();
    setModalConfig({
      open: true,
      user: row,
      action: "toggle",
    });
  };

  const handleOpenEditUser = (e, user) => {
    e.preventDefault();
    setSingleUserToEdit(user);
    setEditUserOpen(true);
  };

  const toggleSelectedStudent = (student) => {
    setSelectedUsers((prev) =>
      prev.some((s) => s.id === student.id)
        ? prev.filter((s) => s.id !== student.id)
        : [...prev, student]
    );
    setSelectedUsersIds((prev) =>
      prev.includes(student.id)
        ? prev.filter((id) => id !== student.id)
        : [...prev, student.id]
    );
  };

  const handleOpenDeletePrompt = (e, row) => {
    e.preventDefault();
    setModalConfig({
      open: true,
      user: row,
      action: "delete",
    });
  };

  const columns = [
    {
      id: "users",
      label: "Users",
      hasSelectAll: true,
      render: (row) => {
        const isSelected = selectedUsersIds.includes(row.id);
        return (
          <div className="flex items-center gap-8">
            <input
              checked={isSelected}
              onChange={() => toggleSelectedStudent(row)}
              type="checkbox"
            />
            <Avatar src={row?.thumb} />
            <TableTooltip>
              <span className="font-bold text-12">{`${row?.first_name} ${row?.last_name}`}</span>
            </TableTooltip>
          </div>
        );
      },

      widthClass: "min-w-256",
    },

    {
      id: "userGroup",
      label: "User Group",
      render: (row) => {
        const isSchoolSystem = row?.user_group === "School System";
        return (
          <div
            className={`
						inline-flex px-[1.4rem] py-1 rounded-full font-medium text-14
						${isSchoolSystem ? "bg-[#FFEEF1] text-[#FF2A54]" : "bg-[#EBF6FF] text-[#008FFF]"}
					`}
          >
            {row?.user_group}
          </div>
        );
      },
    },

    {
      id: "roles",
      label: "Roles",
      render: (row) => {
        const isSchoolSystem = row?.user_group === "School System";

        return (
          <span
            className={`text-14 ${isSchoolSystem ? "text-[#FF2A54]" : "text-[#008FFF]"}`}
          >
            {row?.user_role}
          </span>
        );
      },
    },

    {
      id: "schools",
      label: "Schools",
      render: (row) => {
        // list of school names
        const schoolNames = row.schools.map((school) => school.name) || [];

        // Determine display text based on number of schools
        const displayText =
          schoolNames.length > 1
            ? "Multi-site"
            : schoolNames[0] || "No schools";

        return (
          <div className="flex items-center gap-16 max-w-136">
            <TableTooltip title={schoolNames.join("\n")}>
              <span className="truncate">{displayText}</span>
            </TableTooltip>
          </div>
        );
      },
    },
    {
      id: "actions",
      label: "Actions",
      render: (row) => {
        const isPrimaryAdmin = row.is_primary;
        return (
          <div className="flex items-center gap-16">
            <button onClick={(e) => handleOpenEditUser(e, row)} type="button">
              <EditYellowIcon className="size-28" />
            </button>
            {!isPrimaryAdmin ? (
              <button
                onClick={(e) => handleOpenDeletePrompt(e, row)}
                type="button"
              >
                <DeleteRedGradIcon className="size-28" />
              </button>
            ) : null}
            {!isPrimaryAdmin ? (
              <CustomSwitch
                checked={row?.status}
                disabled={isPrimaryAdmin}
                label="Toggle status"
                onChange={(e) => handleOpenStatusUpdatePrompt(e, row)}
              />
            ) : null}
          </div>
        );
      },
    },
  ];

  const resetSelectedStudents = () => {
    setSelectedUsers([]);
    setSelectedUsersIds([]);
  };

  return (
    <div className="">
      <EditUserModal
        actions={actions}
        allowedSchools={allowedSchools}
        companies={companies}
        currentUser={currentUser}
        dispatch={dispatch}
        isOwner={isOwner}
        open={editUserOpen}
        setOpen={setEditUserOpen}
        setRefresh={setRefresh}
        user={singleUserToEdit}
      />
      <ConfirmUpdateOrDeleteModal
        action={modalConfig.action}
        open={modalConfig.open}
        setOpen={(isOpen) =>
          setModalConfig((prev) => ({ ...prev, open: isOpen }))
        }
        setRefresh={setRefresh}
        user={modalConfig.user}
      />
      <div ref={headerRef}>
        <UsersHeader filters={filters} handleSearch={handleSearch} />
        <UserFiltersBar
          companies={companies}
          companiesLoading={companiesLoading}
          filters={filters}
          isOwner={isOwner}
          schools={schools}
          schoolsLoading={schoolsLoading}
          setFilters={setFilters}
        />
        <UserAppliedFilters
          companies={companies}
          filters={filters}
          schools={schools}
          setFilters={setFilters}
        />
      </div>
      <UsersTable
        columns={columns}
        data={rows}
        filters={filters}
        isLoading={isLoading}
        onClearSelection={resetSelectedStudents}
        selectedUsersIds={selectedUsersIds}
        setFilters={setFilters}
        setSelectedUsers={setSelectedUsers}
        setSelectedUsersIds={setSelectedUsersIds}
        tableHeight={tableHeight}
        totalCount={totalCount}
      />
      <SelectionBar
        selectedUsers={selectedUsers}
        selectedUsersIds={selectedUsersIds}
        setRefresh={setRefresh}
        setSelectedUsers={setSelectedUsers}
        setSelectedUsersIds={setSelectedUsersIds}
      />
    </div>
  );
};

export default UserManagementList;
