import React, { useEffect, useState } from "react";

const NavbarTime = () => {
  const [hours, setHours] = useState(new Date().getHours());
  const [minutes, setMinutes] = useState(new Date().getMinutes());

  useEffect(() => {
    const id = setInterval(() => {
      setHours(new Date().getHours());
      setMinutes(new Date().getMinutes());
    }, 1000);

    return () => {
      clearInterval(id);
    };
  }, [minutes, hours]);

  return (
    <div className="text-white text-pd-base ml-[34px] mt-[30px] mb-[10px] leading-[22.4px]">
      <div className="inline-block">
        <span className="inline-block">
          {hours >= 6 && hours < 12 ? (
            <img alt="time-icon" src="assets/images/navbarIcons/Morning.png" />
          ) : hours >= 12 && hours < 17 ? (
            <img
              alt="time-icon"
              src="assets/images/navbarIcons/Afternoon.png"
            />
          ) : (
            <img alt="time-icon" src="assets/images/navbarIcons/Evening.png" />
          )}
        </span>
      </div>
      <div className="inline-block align-top ml-[8px]">
        {hours > 12
          ? (hours - 12).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })
          : hours.toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}
        :
        {minutes.toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}{" "}
        {hours >= 12 ? "PM" : "AM"}, <br />
        Good{" "}
        {hours >= 6 && hours < 12
          ? "Morning"
          : hours >= 12 && hours < 17
            ? "Afternoon"
            : "Evening"}
      </div>
    </div>
  );
};

export default NavbarTime;
