import * as React from 'react';
const CalendarDotsIcon = (props) => (
	<svg fill="none" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M9.334 2.333v3.5M18.666 2.333v3.5M4.084 10.605h19.833M24.5 9.916v9.917c0 3.5-1.75 5.833-5.833 5.833H9.333c-4.083 0-5.833-2.333-5.833-5.833V9.916c0-3.5 1.75-5.833 5.833-5.833h9.334c4.083 0 5.833 2.333 5.833 5.833Z"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeMiterlimit={10}
			strokeWidth={1.5}
		/>
		<path
			d="M18.311 15.984h.01M18.311 19.484h.01M13.995 15.984h.01M13.995 19.484h.01M9.677 15.984h.01M9.677 19.484h.01"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
		/>
	</svg>
);
export default CalendarDotsIcon;
