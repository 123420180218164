import { Tab, withStyles } from "@material-ui/core";
import React from "react";

export const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "black",
    fontWeight: 600,
    marginRight: theme.spacing(1),
    opacity: 1,
  },
}))((props) => {
  return (
    <Tab
      className={`${props.selected ? "text-pd-blue font-bold" : ""}`}
      disableRipple
      {...props}
    />
  );
});
