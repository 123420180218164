import React from "react";
import * as Actions from "@/app/store/actions";
import { useState } from "react";
import { useDispatch } from "react-redux";
// import './DisableConfirmDialog.css';
import ConfirmModal from "@shared/components/ConfirmModal/ConfirmModal";
import {
  bulkActivateUsers,
  bulkDeactivateUsers,
  bulkDeleteUsers,
} from "../../services/users";

const ConfirmUpdateOrDeleteModal = ({
  user,
  setRefresh,
  setOpen,
  action = "toggle",
  ...props
}) => {
  const [isRequesting, setIsRequesting] = useState(false);
  const dispatch = useDispatch();

  const handleAction = () => {
    setIsRequesting(true);
    let apiCall;
    let successMessage;

    if (action === "delete") {
      apiCall = bulkDeleteUsers;
      successMessage = `${user.first_name} ${user.last_name} has been deleted successfully`;
    } else {
      apiCall = user.status ? bulkDeactivateUsers : bulkActivateUsers;
      successMessage = `${user.first_name} ${user.last_name} is ${user.status ? "disabled" : "enabled"} successfully`;
    }

    const payload = {
      user_id: [parseInt(user?.id)],
    };

    apiCall(payload)
      .then((res) => {
        if (res.status === 200) {
          dispatch(
            Actions.showMessage({
              message: successMessage,
              autoHideDuration: 1500,
              variant: "success",
            })
          );
          setRefresh((prevState) => prevState + 1);
          dispatch(Actions.closeDialog());
        } else {
          throw new Error("Failed to update user");
        }
      })
      .catch((error) => {
        console.error("Delete error details:", {
          message: error.message,
          response: error.response?.data,
          status: error.response?.status,
        });
        dispatch(
          Actions.showMessage({
            message: `Failed to ${action === "delete" ? "Delete" : "Update"} User Status: ${error.response?.data?.message || error.message}`,
            variant: "error",
            autoHideDuration: 1500,
          })
        );
        dispatch(Actions.closeDialog());
      })
      .finally(() => {
        setIsRequesting(false);
        setOpen(false);
      });
  };

  const getModalTitle = () => {
    if (action === "delete") {
      return `Are you sure you want to delete ${user.first_name} ${user.last_name}?`;
    }
    return `Are you sure you want to ${user?.status ? "disable" : "enable"} ${user?.first_name} ${user?.last_name}?`;
  };

  return (
    <ConfirmModal
      isRequesting={isRequesting}
      onConfirm={handleAction}
      setOpen={setOpen}
      title={getModalTitle()}
      {...props}
    />
  );
};

export default ConfirmUpdateOrDeleteModal;
