import * as React from 'react';
const FilterIcon = ({ color = '#fff', ...props }) => (
	<svg fill="none" height={20} width={20} xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M4.5 1.75h11c.917 0 1.667.75 1.667 1.667V5.25c0 .667-.416 1.5-.833 1.917l-3.583 3.167c-.5.417-.834 1.25-.834 1.917v3.583c0 .5-.333 1.166-.75 1.416l-1.166.75c-1.084.667-2.584-.083-2.584-1.416v-4.417c0-.583-.333-1.333-.666-1.75L3.584 7.084c-.417-.417-.75-1.167-.75-1.667V3.5c0-1 .75-1.75 1.667-1.75ZM9.108 1.75 5 8.334"
			stroke={color}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeMiterlimit={10}
			strokeWidth={1.5}
		/>
	</svg>
);
export default FilterIcon;
