export const states = [
  {
    value: 4743,
    label: "Alabama",
    abbreviation: "AL",
  },
  {
    value: 4744,
    label: "Alaska",
    abbreviation: "AK",
  },

  {
    value: 4746,
    label: "Arizona",
    abbreviation: "AZ",
  },
  {
    value: 4747,
    label: "Arkansas",
    abbreviation: "AR",
  },

  {
    value: 4749,
    label: "California",
    abbreviation: "CA",
  },
  {
    value: 4750,
    label: "Colorado",
    abbreviation: "CO",
  },
  {
    value: 4751,
    label: "Connecticut",
    abbreviation: "CT",
  },
  {
    value: 4752,
    label: "Delaware",
    abbreviation: "DE",
  },
  {
    value: 4753,
    label: "District of Columbia",
    abbreviation: "DC",
  },
  {
    value: 4754,
    label: "Florida",
    abbreviation: "FL",
  },
  {
    value: 4755,
    label: "Georgia",
    abbreviation: "GA",
  },

  {
    value: 4757,
    label: "Hawaii",
    abbreviation: "HI",
  },

  {
    value: 4759,
    label: "Idaho",
    abbreviation: "ID",
  },
  {
    value: 4760,
    label: "Illinois",
    abbreviation: "IL",
  },
  {
    value: 4761,
    label: "Indiana",
    abbreviation: "IN",
  },
  {
    value: 4762,
    label: "Iowa",
    abbreviation: "IA",
  },
  {
    value: 4765,
    label: "Kansas",
    abbreviation: "KS",
  },
  {
    value: 4766,
    label: "Kentucky",
    abbreviation: "KY",
  },

  {
    value: 4768,
    label: "Louisiana",
    abbreviation: "LA",
  },
  {
    value: 4769,
    label: "Maine",
    abbreviation: "ME",
  },
  {
    value: 4770,
    label: "Maryland",
    abbreviation: "MD",
  },
  {
    value: 4771,
    label: "Massachusetts",
    abbreviation: "MA",
  },
  {
    value: 4772,
    label: "Michigan",
    abbreviation: "MI",
  },
  {
    value: 4774,
    label: "Minnesota",
    abbreviation: "MN",
  },
  {
    value: 4775,
    label: "Mississippi",
    abbreviation: "MS",
  },
  {
    value: 4776,
    label: "Missouri",
    abbreviation: "MO",
  },
  {
    value: 4777,
    label: "Montana",
    abbreviation: "MT",
  },
  {
    value: 4779,
    label: "Nebraska",
    abbreviation: "NE",
  },
  {
    value: 4780,
    label: "Nevada",
    abbreviation: "NV",
  },
  {
    value: 4781,
    label: "New Hampshire",
    abbreviation: "NH",
  },
  {
    value: 4782,
    label: "New Jersey",
    abbreviation: "NJ",
  },

  {
    value: 4783,
    label: "New Mexico",
    abbreviation: "NM",
  },
  {
    value: 4784,
    label: "New York",
    abbreviation: "NY",
  },
  {
    value: 4785,
    label: "North Carolina",
    abbreviation: "NC",
  },
  {
    value: 4786,
    label: "North Dakota",
    abbreviation: "ND",
  },
  {
    value: 4788,
    label: "Ohio",
    abbreviation: "OH",
  },
  {
    value: 4789,
    label: "Oklahoma",
    abbreviation: "OK",
  },
  {
    value: 4790,
    label: "Oregon",
    abbreviation: "OR",
  },
  {
    value: 4792,
    label: "Pennsylvania",
    abbreviation: "PA",
  },
  {
    value: 4793,
    label: "Puerto Rico",
    abbreviation: "PR",
  },
  {
    value: 4794,
    label: "Rhode Island",
    abbreviation: "RI",
  },
  {
    value: 4795,
    label: "South Carolina",
    abbreviation: "SC",
  },
  {
    value: 4796,
    label: "South Dakota",
    abbreviation: "SD",
  },
  {
    value: 4797,
    label: "Tennessee",
    abbreviation: "TN",
  },
  {
    value: 4798,
    label: "Texas",
    abbreviation: "TX",
  },
  {
    value: 4801,
    label: "Utah",
    abbreviation: "UT",
  },
  {
    value: 4802,
    label: "Vermont",
    abbreviation: "VT",
  },

  {
    value: 4803,
    label: "Virginia",
    abbreviation: "VA",
  },
  {
    value: 4805,
    label: "Washington",
    abbreviation: "WA",
  },
  {
    value: 4806,
    label: "West Virginia",
    abbreviation: "WV",
  },
  {
    value: 4807,
    label: "Wisconsin",
    abbreviation: "WI",
  },
  {
    value: 4808,
    label: "Wyoming",
    abbreviation: "WY",
  },
];
