import React from "react";
import ChevronDownIcon from "@shared/icons/ChevronDownIcon";
import SearchIcon from "@shared/icons/SearchIcon";
import CircleCheckIcon from "@shared/icons/CircleCheckIcon";
import { Popover, TextField, withStyles } from "@material-ui/core";
import { useState } from "react";

const styles = {
  root: {
    border: "none",
  },
};

const CustomSearchBox = withStyles(styles)(TextField);

const PopoverInput = ({
  title,
  filters,
  setFilter,
  currentFilter,
  compact = false,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState(filters[currentFilter] ?? "");

  const handleClick = (event) => {
    setValue(filters[currentFilter] ?? "");
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setValue(filters[currentFilter] ?? "");
  };

  const handleChange = () => {
    setFilter({ ...filters, [currentFilter]: value.length ? value : null });
  };

  return (
    <div>
      <button
        className={`flex items-center gap-8 md:text-16 border border-transparent rounded-md  p-4 ${
          compact ? "!text-14 text-[#374151] font-medium" : "text-16"
        }`}
        onClick={handleClick}
        type="button"
      >
        {title}
        {filters[currentFilter]?.length > 0 ? (
          <CircleCheckIcon className="w-20 h-20" />
        ) : (
          ""
        )}{" "}
        <ChevronDownIcon className="w-10" />
      </button>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        className="max-h-256"
        onClose={handleClose}
        open={Boolean(anchorEl)}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <div className="flex flex-col gap-0 my-0 p-8 pt-0 border-grey-200 rounded bg-white">
          <div className="flex items-center gap-8 px-8 sticky top-0 bg-white py-2 pt-8 z-10">
            <CustomSearchBox
              InputProps={{
                disableUnderline: true,
                className: "",
              }}
              onChange={(e) => setValue(e.target.value)}
              placeholder="search"
              size="medium"
              type="search"
              value={value}
              variant="standard"
            />
            <button onClick={handleChange} type="button">
              <SearchIcon className="w-20 h-20 ml-2" />
            </button>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default PopoverInput;
