import { Paper } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import React, { useEffect, useRef, useState } from "react";
import Dropzone from "react-dropzone";
import { Controller, useWatch } from "react-hook-form";
import { states } from "../../constants/states";
import CloudUploadGradient from "../../icons/CloudUploadGradient";
import EditPhotoGradientIcon from "../../icons/EditPhotoGradientIcon";
import UploadPhotoGradientIcon from "../../icons/UploadPhotoGradientIcon";
import { bulkResetPasswordUsers, getPermissions } from "../../services/users";
import GradientButton from "../GradientButton";
import HierarchicalSearchSelect from "../HierarchicalSearchSelect";
import StyledInput from "../StyledInput";
import StyledSearchSelect from "../StyledSearchSelect";
import StyledSelect from "../StyledSelect";
import PdfIcon from "../../icons/PdfIcon";

const UserForm = ({
  control,
  errors,
  isOwner,
  companies,
  userId,
  actions,
  dispatch,
  currentUser,
  isNew = false,
  onDocumentsToDelete,
  onFormChange,
  setValue,
  lockedSchools = [],
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [permissionsLoading, setPermissionsLoading] = useState(false);
  const [resettingPassword, setResettingPassword] = useState(false);
  const [documentsToDelete, setDocumentsToDelete] = useState([]);
  const inputRef = useRef(null);

  const isEditingSelf = currentUser.data.id === userId;
  const selectedUserGroup =
    useWatch({
      control,
      name: "user_group",
    }) || "";

  const selectedRole =
    useWatch({
      control,
      name: "role",
    }) || "";

  const schoolOptions = companies?.reduce((acc, company) => {
    if (company.schools) {
      company.schools.forEach((school) => {
        acc.push({
          value: school.id,
          label: school.name,
        });
      });
    }
    return acc;
  }, []);
  // Need to keep track of initial mount so the role and school(s) are not cleared when the form is mounted
  const isInitialMount = useRef(true);

  // Clear schools when role or user_group changes
  useEffect(() => {
    if (isInitialMount.current) {
      return;
    }
    setValue("schools", []);
  }, [selectedRole, selectedUserGroup, setValue]);

  // Clear role when user_group changes
  // Disabled for now as user_group has been removed from the form
  // TODO: add back in when custom roles are implemented
  // useEffect(() => {
  //   if (isInitialMount.current) {
  //     return;
  //   }
  //   setValue("role", "");
  // }, [selectedUserGroup, setValue]);

  // this has to be after the effects that clear the role and schools so they aren't cleared on mount
  useEffect(() => {
    isInitialMount.current = false;
  }, []);

  // Watch form values for changes
  const formValues = useWatch({ control });

  // Track form changes
  useEffect(() => {
    const hasFormChanges = Object.keys(formValues || {}).length > 0;
    const hasDocumentChanges = documentsToDelete.length > 0;
    const hasAnyChanges = hasFormChanges || hasDocumentChanges;

    if (onFormChange) {
      onFormChange(hasAnyChanges);
    }
  }, [formValues, documentsToDelete, onFormChange]);

  useEffect(() => {
    const fetchPermissions = async () => {
      setPermissionsLoading(true);
      try {
        const response = await getPermissions("admin", "sub_admin");
        // Filter permissions to only include those that currentUser has
        const filteredPermissions = response.data.filter((permission) => {
          const hasPermission = currentUser.data.permissions.includes(
            permission.name
          );
          return hasPermission;
        });

        const formattedPermissions = filteredPermissions.map((permission) => ({
          value: permission.id,
          label: permission.name.replace(/_/g, " "), // Replace underscores with spaces
        }));
        setPermissions(formattedPermissions);
      } catch (error) {
        console.error("Error fetching permissions:", error);
      } finally {
        setPermissionsLoading(false);
      }
    };

    fetchPermissions();
  }, [currentUser]);

  // for preventing role change if multiple locked schools
  const canSelectSchoolAdminRole = lockedSchools.length <= 1;

  const getRoleOptions = () => {
    const availableRoles = currentUser.data.role_management || [];

    const roleLabels = {
      product_owner: "Product Owner",
      service_agent: "Service Agent",
      super_admin: "Company Admin",
      school_admin: "School Admin",
      sub_admin: "Custom",
    };

    return availableRoles.map((role) => ({
      value: role,
      label: roleLabels[role] || role,
      disabled:
        (role === "school_admin" || role === "sub_admin") &&
        !canSelectSchoolAdminRole,
      tooltip:
        (role === "school_admin" || role === "sub_admin") &&
        !canSelectSchoolAdminRole
          ? "Cannot select this role when user has multiple locked schools"
          : undefined,
    }));
  };

  // Store the initial role when form loads
  const initialRole = useRef(
    useWatch({
      control,
      name: "role",
    })
  ).current;

  // if a role is selected that conflicts with locked schools (when mroe than one school is locked), revert to the initial role
  useEffect(() => {
    if (
      selectedRole &&
      (selectedRole === "school_admin" || selectedRole === "sub_admin")
    ) {
      if (lockedSchools.length > 1) {
        setValue("role", initialRole);
        dispatch(
          actions.showMessage({
            message:
              "Cannot change to this role when user has multiple locked schools",
            variant: "error",
          })
        );
      } else if (lockedSchools.length === 1) {
        // Ensure the locked school is selected
        setValue("schools", [lockedSchools[0]]);
      }
    }
  }, [selectedRole, lockedSchools, setValue, dispatch, actions, initialRole]);

  // Handle file preview
  useEffect(() => {
    if (!selectedFile) return;
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const handleResetPassword = async () => {
    setResettingPassword(true);
    try {
      await bulkResetPasswordUsers({ user_id: [userId] });
      dispatch(
        actions.showMessage({
          message: "Password reset successfully",
          variant: "success",
        })
      );
    } catch (error) {
      console.error("Error resetting password:", error);
      dispatch(
        actions.showMessage({
          message: "Error resetting password",
          variant: "error",
        })
      );
    } finally {
      setResettingPassword(false);
    }
  };

  // Update parent component with documents to delete
  useEffect(() => {
    if (onDocumentsToDelete) {
      onDocumentsToDelete(documentsToDelete);
    }
  }, [documentsToDelete, onDocumentsToDelete]);

  const schoolAdminSelected = selectedRole === "school_admin";
  const subAdminSelected = selectedRole === "sub_admin";

  // Modify the schools Controller to ensure locked schools remain selected
  return (
    <div className="overflow-auto p-20">
      <Controller
        control={control}
        name="photo"
        render={({ field }) => (
          <div className="grid grid-cols-3 justify-between mb-24 items-center">
            <span />
            <button
              className="flex items-center gap-8 flex-col relative justify-self-center"
              onClick={() => inputRef.current.click()}
              type="button"
            >
              {preview || field.value ? (
                <>
                  <Avatar className="w-96 h-96" src={preview || field.value} />
                  <EditPhotoGradientIcon className="absolute bottom-0 right-0 w-32 h-32" />
                </>
              ) : (
                <>
                  <div className="w-96 h-96 grid grid-cols-8 rounded-full overflow-hidden">
                    {Array.from({ length: 64 }).map((_, index) => (
                      <div
                        className={`w-full h-full ${
                          Math.floor(index / 8) % 2 === index % 2
                            ? "bg-gray-300"
                            : "bg-gray-200"
                        }`}
                        key={index}
                      />
                    ))}
                  </div>
                  <UploadPhotoGradientIcon className="absolute bottom-0 right-0 w-32 h-32" />
                </>
              )}
            </button>
            <input
              accept="image/*"
              onChange={(e) => {
                if (e.target.files && e.target.files.length > 0) {
                  const file = e.target.files[0];
                  setSelectedFile(file);
                  field.onChange(file);
                }
              }}
              ref={inputRef}
              style={{ display: "none" }}
              type="file"
            />
            {!isNew ? (
              <div className="flex justify-end gap-8">
                <GradientButton
                  disabled={resettingPassword}
                  onClick={handleResetPassword}
                  variant="light-blue"
                >
                  {resettingPassword ? "Resetting..." : "Reset Password"}
                </GradientButton>
              </div>
            ) : null}
          </div>
        )}
      />

      <div
        className="p-24 grid gap-y-60 gap-x-24 mb-24"
        style={{
          gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
        }}
      >
        <Controller
          control={control}
          name="first_name"
          render={({ field }) => (
            <StyledInput
              {...field}
              error={!!errors.first_name}
              helperText={errors.first_name?.message}
              label="First Name"
              placeholder="Enter first name"
            />
          )}
          rules={{ required: "First Name is required" }}
        />
        <Controller
          control={control}
          name="last_name"
          render={({ field }) => (
            <StyledInput
              {...field}
              error={!!errors.last_name}
              helperText={errors.last_name?.message}
              label="Last Name"
              placeholder="Enter last name"
            />
          )}
          rules={{ required: "Last Name is required" }}
        />
        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <StyledInput
              {...field}
              error={!!errors.email}
              helperText={errors.email?.message}
              label="Email"
              placeholder="Enter email"
              type="email"
            />
          )}
          rules={{
            required: "Email is required",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid email address",
            },
          }}
        />
        <Controller
          control={control}
          name="phone"
          render={({ field }) => (
            <StyledInput
              {...field}
              error={!!errors.phone}
              helperText={errors.phone?.message}
              label="Phone"
              placeholder="Enter phone number"
              type="phone"
            />
          )}
        />
        <Controller
          control={control}
          name="address"
          render={({ field }) => (
            <StyledInput
              {...field}
              error={!!errors.address}
              helperText={errors.address?.message}
              label="Address"
              placeholder="Enter street address"
            />
          )}
        />
        <Controller
          control={control}
          name="address2"
          render={({ field }) => (
            <StyledInput
              {...field}
              error={!!errors.address2}
              helperText={errors.address2?.message}
              label="Address Line 2"
              placeholder="Apt, Suite, etc."
            />
          )}
        />
        <Controller
          control={control}
          name="city"
          render={({ field }) => (
            <StyledInput
              {...field}
              error={!!errors.city}
              helperText={errors.city?.message}
              label="City"
              placeholder="Enter city"
            />
          )}
        />
        <Controller
          control={control}
          name="state_id"
          render={({ field: { onChange, value, ...field } }) => (
            <StyledSelect
              {...field}
              error={!!errors.state_id}
              helperText={errors.state_id?.message}
              label="State"
              onChange={(e) => onChange(e.target.value)}
              options={states}
              placeholder="Select state"
              value={value || ""}
            />
          )}
        />
        <Controller
          control={control}
          name="zip_code"
          render={({ field }) => (
            <StyledInput
              {...field}
              error={!!errors.zip_code}
              helperText={errors.zip_code?.message}
              label="ZIP Code"
              placeholder="Enter ZIP code"
            />
          )}
        />
        <Controller
          control={control}
          name="country_code"
          render={({ field: { onChange, value, ...field } }) => (
            <StyledSelect
              {...field}
              error={!!errors.country_code}
              helperText={errors.country_code?.message}
              label="Country"
              onChange={(e) => onChange(e.target.value)}
              options={[{ value: "USA", label: "United States" }]}
              placeholder="Select country"
              value={value || ""}
            />
          )}
        />
        {/* {isOwner ? (
          <Controller
            control={control}
            name="user_group"
            render={({ field: { onChange, value, ...field } }) => (
              <StyledSelect
                {...field}
                disabled={isEditingSelf}
                error={!!errors.user_group}
                helperText={errors.user_group?.message}
                label="User Group"
                onChange={(e) => onChange(e.target.value)}
                options={[
                  // { value: "Perfect Day Live", label: "Perfect Day Live" },
                  { value: "School System", label: "School System" },
                  { value: "System Level", label: "System Level" },
                ]}
                placeholder="Select user group"
                value={value || ""}
              />
            )}
            rules={{ required: "User Group is required" }}
          />
        ) : (
          <input
            type="hidden"
            {...control.register("user_group")}
            value="School System"
          />
        )} */}
        <Controller
          control={control}
          name="role"
          render={({ field: { onChange, value, ...field } }) => (
            <StyledSelect
              {...field}
              disabled={isEditingSelf}
              error={!!errors.role}
              helperText={errors.role?.message}
              label="Role"
              onChange={(e) => onChange(e.target.value)}
              options={getRoleOptions()}
              placeholder="Select role"
              value={value || ""}
            />
          )}
          rules={{ required: "Role is required" }}
        />
        {selectedRole === "sub_admin" ? (
          <Controller
            control={control}
            name="permissions"
            render={({ field: { onChange, value, ...field } }) => {
              return (
                <StyledSearchSelect
                  {...field}
                  disabled={isEditingSelf}
                  error={!!errors.permissions}
                  helperText={errors.permissions?.message}
                  label="Permissions"
                  multi
                  onChange={(selected) => {
                    const validPermissions = selected
                      .filter(Boolean)
                      .map((item) => item.value);
                    onChange(validPermissions);
                  }}
                  options={permissions}
                  placeholder="Select permissions"
                  resultsLoading={permissionsLoading}
                  value={permissions.filter((p) => value?.includes(p.value))}
                />
              );
            }}
            rules={{
              required: "Permissions are required for custom roles",
              validate: (value) =>
                Array.isArray(value) && value.length > 0
                  ? true
                  : "Please select at least one permission",
            }}
          />
        ) : null}
        {/* Only show company or school select if the user has a role */}
        {selectedRole ? (
          !["school_admin", "sub_admin"].includes(selectedRole) &&
          (isOwner || selectedRole === "super_admin") ? (
            // company/school select for product owner / super admin
            <Controller
              control={control}
              name="schools"
              render={({ field }) => {
                // Ensure locked schools are always included in the value
                const ensureLockedSchools = (newValue) => {
                  return [...new Set([...lockedSchools, ...newValue])];
                };

                return (
                  <HierarchicalSearchSelect
                    allowTopLevelSelect={
                      schoolAdminSelected || subAdminSelected ? false : true
                    }
                    companies={companies}
                    disabled={isEditingSelf}
                    error={!!errors.schools}
                    helperText={
                      errors.schools?.message ||
                      (lockedSchools.length > 0
                        ? `${lockedSchools.length} school(s) are locked and cannot be unselected`
                        : undefined)
                    }
                    label={
                      isOwner
                        ? schoolAdminSelected
                          ? "School"
                          : "Companies & Schools"
                        : "Schools"
                    }
                    lockedSchools={lockedSchools}
                    onChange={(newValue) => {
                      const finalValue = ensureLockedSchools(newValue);
                      field.onChange(finalValue);
                    }}
                    placeholder={
                      schoolAdminSelected
                        ? "Select School"
                        : "Select companies/schools"
                    }
                    singleCompanySelect={
                      schoolAdminSelected || subAdminSelected ? true : false
                    }
                    value={ensureLockedSchools(field.value || [])}
                  />
                );
              }}
              rules={{
                required:
                  selectedRole !== "product_owner"
                    ? "At least one company or school is required"
                    : undefined,
                validate: (value) => {
                  if (lockedSchools.length > 0) {
                    const hasAllLockedSchools = lockedSchools.every((id) =>
                      value.includes(id)
                    );
                    if (!hasAllLockedSchools) {
                      return "Cannot unselect locked schools";
                    }
                  }

                  if (
                    selectedRole === "school_admin" ||
                    selectedRole === "sub_admin"
                  ) {
                    if (value.length !== 1) {
                      return "Must select exactly one school for this role";
                    }
                    if (
                      lockedSchools.length === 1 &&
                      !value.includes(lockedSchools[0])
                    ) {
                      return "Must select the locked school";
                    }
                  }

                  return true;
                },
              }}
            />
          ) : (
            // single school select for school admin
            <Controller
              control={control}
              name="schools"
              render={({ field }) => {
                return (
                  <StyledSelect
                    disabled={
                      !selectedRole || isEditingSelf || lockedSchools.length > 0
                    }
                    error={!!errors.schools}
                    helperText={errors.schools?.message}
                    label="School"
                    onChange={(e) => field.onChange([e.target.value])}
                    options={schoolOptions || []}
                    placeholder="Select school"
                    value={field.value?.[0] || ""}
                  />
                );
              }}
              rules={{ required: "At least one school is required" }}
            />
          )
        ) : null}
      </div>

      <Paper className="p-24 mx-4 rounded-16" elevation={3}>
        <div className="flex flex-col mb-16">
          <span className="text-24 font-semibold">Attachments</span>
          <span className="text-14">
            Upload files related to this user, like documents or images.
          </span>
        </div>

        {documentsToDelete.length > 0 ? (
          <div className="mb-16 p-12 bg-red-50 rounded-md">
            <p className="text-red-700 font-bold">
              The following {documentsToDelete.length === 1 ? "file" : "files"}{" "}
              will be removed when you save:
            </p>
            <ul className="mt-8 list-disc list-inside ml-8">
              {documentsToDelete.map((fileId) => {
                const file = formValues?.documents?.find(
                  (f) => f.id === fileId
                );
                return (
                  <li
                    className="text-red-700  items-center gap-8 "
                    key={fileId}
                  >
                    <span>{file?.file_name}</span>
                    <button
                      className="text-blue-600 hover:text-blue-800 text-14 ml-10"
                      onClick={() => {
                        setDocumentsToDelete((prev) =>
                          prev.filter((id) => id !== fileId)
                        );
                      }}
                      type="button"
                    >
                      Undo
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : null}

        <Controller
          control={control}
          name="documents"
          render={({ field }) => (
            <>
              <Dropzone
                multiple
                onDrop={(acceptedFiles) => {
                  const newFiles = [...(field.value || []), ...acceptedFiles];
                  field.onChange(newFiles);
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                    {...getRootProps()}
                    className="rounded p-24 text-center cursor-pointer flex flex-col items-center justify-center gap-12"
                    style={{
                      border: "1px solid transparent",
                      borderRadius: "1rem",
                      borderImage:
                        "repeating-linear-gradient(45deg, #80bfff, #80bfff 4px, transparent 4px, transparent 8px) 1 round",
                    }}
                  >
                    <input {...getInputProps()} />
                    <CloudUploadGradient className="w-[60px]" />
                    <span>Drag & drop files here.</span>
                    <div className="flex items-center gap-8">
                      <div className="h-px w-64 bg-gray-200" />
                      <span className="text-12 text-gray-600">OR</span>
                      <div className="h-px w-64 bg-gray-200" />
                    </div>
                    <GradientButton variant="blurple">
                      Choose files
                    </GradientButton>
                  </div>
                )}
              </Dropzone>

              {field.value?.length > 0 ? (
                <div className="grid grid-cols-[repeat(auto-fill,minmax(150px,1fr))] gap-16 mt-24">
                  {field.value.map((file, index) => {
                    const isMarkedForDeletion = documentsToDelete.includes(
                      file.id
                    );
                    return (
                      <div
                        className={`relative group ${
                          isMarkedForDeletion ? "opacity-50" : ""
                        }`}
                        key={index}
                      >
                        <div className="aspect-square rounded-lg overflow-hidden border border-gray-200">
                          {!isNew && file.isExisting ? (
                            file.type === "application/pdf" ? (
                              <div className="w-full h-full flex items-center justify-center bg-gray-50">
                                <PdfIcon className="size-64" />
                              </div>
                            ) : (
                              <img
                                alt={file.name}
                                className="w-full h-full object-cover"
                                src={file.url}
                              />
                            )
                          ) : // New file preview
                          file.type.startsWith("image/") ? (
                            <img
                              alt={file.name}
                              className="w-full h-full object-cover"
                              src={URL.createObjectURL(file)}
                            />
                          ) : (
                            <div className="w-full h-full flex items-center justify-center bg-gray-50">
                              <PdfIcon className="size-64" />
                            </div>
                          )}
                        </div>
                        {!isMarkedForDeletion ? (
                          <button
                            className="absolute -top-2 -right-2 size-24 rounded-full bg-red-500 text-white flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (!isNew && file.isExisting) {
                                // Just mark for deletion, don't remove from form values
                                setDocumentsToDelete((prev) => [
                                  ...prev,
                                  file.id,
                                ]);
                              } else {
                                // For new files, remove from form values
                                const newFiles = field.value.filter(
                                  (_, i) => i !== index
                                );
                                field.onChange(newFiles);
                              }
                            }}
                            type="button"
                          >
                            ×
                          </button>
                        ) : null}
                        <div className="mt-2 text-center text-sm text-gray-600 truncate">
                          {file.name || file.file_name}
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </>
          )}
        />
      </Paper>
    </div>
  );
};

export default UserForm;
