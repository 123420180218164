import React from "react";
import DatePicker from '@shared/customComponents/CustomDatePicker/CustomDatePicker';
import ChevronDownIcon from '@shared/icons/ChevronDownIcon';
import { Popover } from '@material-ui/core';
import dayjs from 'dayjs';
import { useState } from 'react';

const PopoverDateRange = ({ filters, setFilters }) => {
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<button
				className="flex items-center gap-8 md:text-16 border border-transparent rounded-md  p-4 hover:shadow-xl"
				onClick={handleClick}
				type="button"
			>
				Date
				{filters.start_date !== null && filters.end_date !== null ? (
					<span className="text-10 text-black rounded bg-white h-16 w-16 flex items-center justify-center">1</span>
				) : (
					''
				)}
				<ChevronDownIcon className="w-10" />
			</button>
			<Popover
				anchorEl={anchorEl}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
				onClose={handleClose}
				open={Boolean(anchorEl)}
				transformOrigin={{ vertical: 'top', horizontal: 'left' }}
			>
				<div className="p-16 flex flex-col gap-16">
					<DatePicker
						clearable={false}
						label="Start Date"
						setValue={(date) => {
							if (filters.end_date !== null) {
								setAnchorEl(null);
							}
							setFilters({ ...filters, start_date: dayjs(date).format('YYYY-MM-DD') });
						}}
						value={filters.start_date}
						variant="outlined"
					/>
					<DatePicker
						clearable={false}
						label="End Date"
						setValue={(date) => {
							if (filters.start_date !== null) {
								setAnchorEl(null);
							}
							setFilters({ ...filters, end_date: dayjs(date).format('YYYY-MM-DD') });
						}}
						value={filters.end_date}
						variant="outlined"
					/>
				</div>
			</Popover>
		</>
	);
};

export default PopoverDateRange;
