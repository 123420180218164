import { Divider } from "@material-ui/core";
import React from "react";
import FilterBubble from "./FilterBubble";
import dayjs from "dayjs";

const AppliedFiltersBar = ({
  filters,
  setFilters,
  reportType = "",
  hideFilters = null,
  forceShow = false,
}) => {
  const handleRemoveFilter = (filter, value) => {
    if (Array.isArray(filters[filter])) {
      setFilters({
        ...filters,
        [filter]: filters[filter].filter((item) => item.value !== value),
      });
    } else {
      setFilters({ ...filters, [filter]: null });
    }
  };

  const handleRemoveDateRange = () => {
    setFilters({ ...filters, start_date: null, end_date: null });
  };

  const getFilterCategory = (filter) => {
    switch (filter) {
      case "room_id":
        return "Room";
      case "school_id":
        return "School";
      case "company_id":
        return "Company";
      case "parent_id":
        return "Parent";
      case "transaction_id":
        return "Transaction";
      case "student_id":
        return "Student";
      case "status":
        return "Status";
      case "privileges":
        return "Privileges";
      case "relation":
        return "Relation";
      case "contact_type":
        return "Contact Type";
      case "package_type":
        return "Plan Type";
      case "search_type":
        return "Search Type";
      case "activity_id":
        return "Activity";
      case "platform":
        return "Platform";
      case "media_type":
        return "Media";
      default:
        return "Other";
    }
  };

  const filtersToHide = hideFilters
    ? [
        "start_date",
        "end_date",
        "timezone",
        "sort",
        "dir",
        "page",
        ...hideFilters,
      ]
    : [
        "start_date",
        "end_date",
        "timezone",
        "sort",
        "dir",
        "page",
        "student_name",
        "school_id",
      ];

  return (
    <div
      className="px-32 flex items-center py-12 min-h-60 "
      style={{ background: "rgb(91,129,240)" }}
    >
      <div className="flex gap-12 items-center flex-wrap">
        <span className="text-white">Applied</span>
        <span className="text-white">
          <Divider
            className=" bg-white py-12"
            flexItem
            orientation="vertical"
          />
        </span>
        {filters.start_date && filters.end_date ? (
          <FilterBubble
            category={
              reportType === "subscription" ? "Subscription Date" : "Date Range"
            }
            key="date-range"
            onClose={handleRemoveDateRange}
            title={
              filters.start_date === filters.end_date
                ? dayjs(filters.start_date).format("MM/DD/YY")
                : `${dayjs(filters.start_date).format("MM/DD/YY")} ~ ${dayjs(filters.end_date).format("MM/DD/YY")}`
            }
          />
        ) : null}
        {Object.keys(filters)
          ?.filter(
            (filter) =>
              (forceShow && !filtersToHide.includes(filter)) ||
              (filters[filter] && !filtersToHide.includes(filter))
          )
          ?.map((filter) => {
            const filterValues = Array.isArray(filters[filter])
              ? filters[filter]
              : [filters[filter]];
            return filterValues.map((item) => {
              return (
                <FilterBubble
                  category={getFilterCategory(filter)}
                  key={`${filter}-${item.value}`}
                  onClose={() => handleRemoveFilter(filter, item.value)}
                  title={item.label || item.value || item}
                />
              );
            });
          })}
      </div>
    </div>
  );
};

export default AppliedFiltersBar;
