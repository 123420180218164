import * as React from 'react';
const AscendingSortIcon = (props) => (
	<svg fill="none" height={32} width={32} xmlns="http://www.w3.org/2000/svg" {...props}>
		<g opacity={0.25}>
			<path
				clipRule="evenodd"
				d="M10.897 9 9 14h1.07l.379-1h2.133l.338 1h1.056l-1.69-5h-1.39Zm1.347 3-.667-1.973L10.828 12h1.416Z"
				fill="#000"
				fillRule="evenodd"
			/>
			<path d="M9.5 18.5h4v.27L10 22.216v.284h4" stroke="#000" strokeMiterlimit={4.257} />
			<path d="M20.5 9v14m0 0-3-3m3 3 3-3" stroke="#000" />
		</g>
	</svg>
);
export default AscendingSortIcon;
