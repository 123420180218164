import React from "react";
import FuseAnimate from "@/@fuse/core/FuseAnimate";
import { useForm } from "@/@fuse/hooks";
import * as Actions from "@/app/store/actions";
import { CircularProgress } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import secureLocalStorage from "react-secure-storage";
import { forgetPassword, verifyOtp } from "../../services/auth";

import { useHistory } from "react-router-dom";
import GradientButton from "../GradientButton";

const OtpInput = ({ name, value, onChange, onKeyUp, tabIndex }) => (
  <input
    autoComplete="off"
    className="w-[45px] h-[44px] mx-[11px] border-t-0 border-l-0 border-r-0 border-b-2 border-black/20 p-0 text-black/70 text-[32px] text-center bg-transparent font-semibold leading-none rounded-none outline-none"
    maxLength="1"
    name={name}
    onChange={onChange}
    onKeyUp={onKeyUp}
    required
    tabIndex={tabIndex}
    type="tel"
    value={value}
  />
);

const useStyles = makeStyles((theme) => ({
  root: {
    background: "url(assets/images/banner/bg.png) no-repeat center",
    color: theme.palette.primary.contrastText,
  },
}));
const OtpEntry = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [username] = useState(history?.location?.state?.username);
  const classes = useStyles();
  const [isRequesting, setIsRequesting] = useState(false);
  const [time, setTime] = useState(secureLocalStorage.getItem("time"));
  const currentTime = new Date().getTime();
  const diff = time - currentTime;
  const [sec, setSec] = useState(
    diff > 0 ? Math.floor((diff % (1000 * 60)) / 1000) : 0
  );
  const [min, setMin] = useState(
    diff > 0 ? Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)) : 0
  );

  const { form, handleChange } = useForm({
    otp1: null,
    otp2: null,
    otp3: null,
    otp4: null,
  });
  useEffect(() => {
    const myInterval = setInterval(() => {
      const currentTime = new Date().getTime();
      const diff = time - currentTime;
      if (!(sec || min)) {
        // do nothing
      } else if (diff <= 0) {
        setSec(0);
        setMin(0);
      } else {
        setMin(Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)));
        setSec(Math.floor((diff % (1000 * 60)) / 1000));
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, [sec, min, time]);

  useEffect(() => {
    if (!username) {
      dispatch(
        Actions.showMessage({
          message: "Please enter Email to recieve OTP.",
          variant: "error",
          autoHideDuration: 3000,
        })
      );
      history.push("/forgot-password");
    }
  }, [dispatch, username]);

  const inputfocus = (elmnt) => {
    if (
      elmnt.key === "Backspace" ||
      elmnt.key === "ArrowLeft" ||
      elmnt.key === "ArrowDown"
    ) {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else if (elmnt.key === "Tab" || elmnt.key === "Shift") {
      // Do nothing, follow default behaviour
    } else {
      const next = elmnt.target.tabIndex;
      if (next < 4) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };

  function handleSubmit(ev) {
    ev.preventDefault();
    setIsRequesting(true);
    const data = {
      username,
      otp: `${form.otp1}${form.otp2}${form.otp3}${form.otp4}`,
    };
    if (Number.isNaN(Number(data.otp)) || data.otp.toString().length !== 4) {
      dispatch(
        Actions.showMessage({
          message:
            form.otp1 && form.otp2 && form.otp3 && form.otp4
              ? "Please enter correct OTP"
              : "Please enter OTP",
          variant: "error",
          autoHideDuration: 2000,
        })
      );
      setIsRequesting(false);
      return;
    }

    verifyOtp(data)
      .then(() => {
        dispatch(
          Actions.showMessage({
            message: "OTP verified. Redirecting...",
            variant: "success",
            autoHideDuration: 2000,
          })
        );
        setIsRequesting(false);
        setTimeout(() => {
          history.push({
            pathname: "/reset-password",
            state: {
              username,
            },
          });
        }, 2000);
      })
      .catch(() => {
        dispatch(
          Actions.showMessage({
            message: "Entered OTP is not correct",
            variant: "error",
          })
        );
      })
      .finally(() => {
        setIsRequesting(false);
      });
  }

  const handleResend = () => {
    setIsRequesting(true);
    forgetPassword({ username })
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            Actions.showMessage({
              message: `${response.data.message}.`,
              variant: "success",
              autoHideDuration: 2000,
            })
          );
          const newTime = new Date().getTime() + 180000;
          setTime(newTime);
          setMin(2);
          setSec(59);
          secureLocalStorage.setItem("time", newTime);
        }
      })
      .catch(() => {
        setIsRequesting(false);
        dispatch(
          Actions.showMessage({
            message: `Failed to resend OTP, please try again.`,
            variant: "error",
            autoHideDuration: 2000,
          })
        );
      });
  };

  return (
    <div
      className={clsx(
        classes.root,
        "flex flex-col flex-auto shrink-0 items-center justify-center"
      )}
    >
      <div className="flex flex-col items-center justify-center w-full">
        <FuseAnimate animation="transition.expandIn">
          <Card className="w-[90%] max-w-[401px] bg-white shadow-[0px_6px_50px_#0078de80] mx-auto rounded-3xl">
            <CardContent className="flex flex-col items-center justify-center p-32">
              <img
                alt="logo"
                className="mb-2 w-[150px]"
                src="assets/images/logos/login-logo.png"
              />
              <h1 className="text-18 text-black font-semibold mt-16 font-proxima relative text-center">
                Enter OTP
              </h1>
              <div className="bg-pd-blue-light w-1/4 h-1 rounded-full my-32" />
              <p className="text-16 text-grey-700 text-center font-semibold">
                We have sent a one-time password to your email address
                {min || sec ? (
                  <p className="mt-10 mb-20">
                    {min.toLocaleString("en-US", {
                      minimumIntegerDigits: 2,
                      useGrouping: false,
                    })}
                    :
                    {sec.toLocaleString("en-US", {
                      minimumIntegerDigits: 2,
                      useGrouping: false,
                    })}
                  </p>
                ) : null}
              </p>
              <form
                className="flex flex-col justify-center w-full"
                name="recoverForm"
                noValidate
                onSubmit={handleSubmit}
              >
                <div className="inline-block text-center mx-auto mb-[23px] whitespace-nowrap">
                  <OtpInput
                    name="otp1"
                    onChange={handleChange}
                    onKeyUp={inputfocus}
                    tabIndex="1"
                    value={form.otp1}
                  />
                  <OtpInput
                    name="otp2"
                    onChange={handleChange}
                    onKeyUp={inputfocus}
                    tabIndex="2"
                    value={form.otp2}
                  />
                  <OtpInput
                    name="otp3"
                    onChange={handleChange}
                    onKeyUp={inputfocus}
                    tabIndex="3"
                    value={form.otp3}
                  />
                  <OtpInput
                    name="otp4"
                    onChange={handleChange}
                    onKeyUp={inputfocus}
                    tabIndex="4"
                    value={form.otp4}
                  />
                </div>
                <div className="mx-auto">
                  {isRequesting ? (
                    <div className="pl-[41%]">
                      <CircularProgress size={35} />
                    </div>
                  ) : (
                    <div className="flex items-center justify-center gap-16 my-16">
                      <GradientButton
                        disabled={min || sec}
                        onClick={handleResend}
                        variant="secondary"
                      >
                        Resend OTP
                      </GradientButton>

                      <GradientButton height={41} type="submit" width={130}>
                        Verify OTP
                      </GradientButton>
                    </div>
                  )}
                </div>
              </form>
            </CardContent>
          </Card>
        </FuseAnimate>
      </div>
    </div>
  );
};
export default OtpEntry;
