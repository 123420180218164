import * as React from 'react';
const PdfIcon = (props) => (
	<svg fill="none" height={24} viewBox="0 0 24 24" width={24} xmlns="http://www.w3.org/2000/svg" {...props}>
		<g filter="url(#pdf-icon-a)">
			<path
				clipRule="evenodd"
				d="M5.234 2C4.497 2 3.9 2.597 3.9 3.333v17.334c0 .736.597 1.333 1.334 1.333h13.533c.736 0 1.333-.597 1.333-1.333V3.315 7l-5-5H5.234Z"
				fill="#FA4E4E"
				fillRule="evenodd"
			/>
		</g>
		<path
			d="M15.248 15.47a3.185 3.185 0 0 1-1.85-.753c-1.024.226-1.999.552-2.974.953-.774 1.378-1.5 2.08-2.124 2.08-.125 0-.275-.025-.375-.1a.739.739 0 0 1-.425-.677c0-.226.05-.852 2.424-1.88a17.86 17.86 0 0 0 1.325-3.107c-.3-.602-.95-2.08-.5-2.832a.795.795 0 0 1 .775-.401c.25 0 .5.125.65.325.325.452.3 1.404-.125 2.808.4.75.924 1.428 1.55 2.005.524-.1 1.05-.176 1.574-.176 1.175.025 1.35.576 1.325.902 0 .852-.825.852-1.25.852ZM8.25 17.022l.075-.025c.35-.125.625-.376.825-.702-.375.15-.675.401-.9.727Zm3.324-7.518h-.075c-.025 0-.075 0-.1.025-.1.426-.025.877.15 1.278.15-.426.15-.877.025-1.303Zm.175 3.634-.025.05-.025-.025a20.71 20.71 0 0 1-.75 1.704l.05-.025v.05a14.517 14.517 0 0 1 1.7-.501l-.025-.025h.075a7.674 7.674 0 0 1-1-1.228Zm3.4 1.328c-.226 0-.426 0-.65.05.25.125.5.175.75.2.174.025.35 0 .499-.05 0-.075-.1-.2-.6-.2Z"
			fill="#fff"
		/>
		<path d="M19.63 6.531h-4.062l4.532 4.532V7l-.47-.469Z" fill="url(#pdf-icon-b)" />
		<path d="M16.433 7H20.1l-5-5v3.667c0 .736.597 1.333 1.333 1.333Z" fill="#FDB8B8" />
		<defs>
			<linearGradient gradientUnits="userSpaceOnUse" id="pdf-icon-b" x1={16.584} x2={21.115} y1={5.516} y2={10.047}>
				<stop stopOpacity={0.2} />
				<stop offset={1} stopOpacity={0} />
			</linearGradient>
			<filter
				colorInterpolationFilters="sRGB"
				filterUnits="userSpaceOnUse"
				height={20.167}
				id="pdf-icon-a"
				width={16.199}
				x={3.9}
				y={1.917}
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
				<feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
				<feOffset dy={-0.167} />
				<feGaussianBlur stdDeviation={0.042} />
				<feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
				<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
				<feBlend in2="shape" result="effect1_innerShadow_3081_7057" />
				<feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
				<feOffset dy={0.167} />
				<feGaussianBlur stdDeviation={0.042} />
				<feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
				<feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.3 0" />
				<feBlend in2="effect1_innerShadow_3081_7057" result="effect2_innerShadow_3081_7057" />
			</filter>
		</defs>
	</svg>
);
export default PdfIcon;
