import { XMarkIcon } from "@heroicons/react/24/outline";
import { Fade, Modal } from "@material-ui/core";
import BulkActionButton from "../BulkActionButton";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { getImageUrl } from "../../helpers/utils";
import { uploadFile } from "../../services/imageUpload";
import UserForm from "./UserForm";

const AddUserModal = ({
  open,
  setOpen,
  handleCreateUser,
  isOwner,
  companies,
  currentUser,
  allowedSchools,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      // TODO: add user_group after custom roles are implemented
      photo: null,
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      role: "",
      permissions: [],
      address: "",
      address2: "",
      city: "",
      state_id: "",
      zip_code: "",
      country_code: "USA",
      schools: [],
      notify: true,
      documents: [],
      // user_group: isOwner ? "" : "School System", // only product owners can create users outside of the school system
    },
  });

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open, reset]);

  const onSubmit = handleSubmit(
    async (data) => {
      try {
        setSubmitting(true);
        // Handle photo upload
        let photoUrl = null;
        if (data.photo instanceof File) {
          const filename = getImageUrl(data.photo);
          const uploadedUrl = await uploadFile(data.photo, filename);
          photoUrl = `${import.meta.env.VITE_S3_BASE_URL}${uploadedUrl}`;
        }

        // Handle documents upload
        const documentPromises = data.documents?.map(async (file) => {
          const filename = getImageUrl(file);
          const uploadedUrl = await uploadFile(file, filename);
          return {
            url: `${import.meta.env.VITE_S3_BASE_URL}${uploadedUrl}`,
            file_name: file.name,
            type: file.type,
          };
        });
        const documents = await Promise.all(documentPromises || []);

        const payload = {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          phone: data.phone,
          role: data.role,
          permissions: data.permissions,
          start_date: data.start_date
            ? new Date(data.start_date).toISOString().split("T")[0]
            : null,
          address: data.address,
          address2: data.address2,
          city: data.city,
          state_id: data.state_id,
          zip_code: data.zip_code,
          country_code: data.country_code,
          schools: data.schools,
          documents,
          notify: data.notify,
        };

        if (photoUrl) {
          payload.photo = photoUrl;
          payload.thumb = photoUrl;
        }

        const response = await handleCreateUser(payload);
        if (response.status >= 200 && response.status < 300) {
          setOpen(false);
        } else {
          console.error("Validation errors:", response.data.errors);
        }
      } catch (error) {
        console.error("Error in form submission:", error);
        if (error.response?.data?.errors) {
          console.log("Validation errors:", error.response.data.errors);
        }
      } finally {
        setSubmitting(false);
      }
    },
    (errors) => {
      console.log("Form validation failed:", errors);
    }
  );

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  return (
    <Modal onClose={handleClose} open={open}>
      <div className="fixed inset-0 flex items-center justify-center p-4 overflow-auto">
        <Fade in={open}>
          <div className="w-full max-w-[80vw] bg-white rounded-[46px] shadow-lg">
            <form className="flex flex-col max-h-[80vh]" onSubmit={onSubmit}>
              <div className="border-b px-32 py-24 flex-shrink-0">
                <button
                  className="flex justify-between items-center w-full"
                  onClick={handleClose}
                  type="button"
                >
                  <XMarkIcon className="size-24" title="Close" />
                  <span className="text-24 font-bold">Add New User</span>
                  <span />
                </button>
              </div>

              <div className="flex-1 overflow-y-auto p-6">
                <UserForm
                  allowedSchools={allowedSchools}
                  companies={companies}
                  control={control}
                  currentUser={currentUser}
                  errors={errors}
                  isNew={true}
                  isOwner={isOwner}
                  setValue={setValue}
                />
              </div>

              <div className="border-t px-32 py-24 flex-shrink-0">
                <div className="flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
                  <div className="flex items-center gap-4">
                    <Controller
                      control={control}
                      name="notify"
                      render={({ field }) => (
                        <input
                          type="checkbox"
                          {...field}
                          checked={field.value}
                          className="accent-black"
                        />
                      )}
                    />
                    <span className="text-12 font-bold self-center">
                      Notify New User?{" "}
                      <span className="font-normal text-12">(recommended)</span>
                    </span>
                  </div>
                  <div className="flex gap-4 justify-end">
                    <BulkActionButton onClick={handleClose} type="button">
                      Cancel
                    </BulkActionButton>
                    <BulkActionButton
                      disabled={submitting}
                      type="submit"
                      variant="primary"
                    >
                      Submit
                    </BulkActionButton>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Fade>
      </div>
    </Modal>
  );
};

export default AddUserModal;
