import * as React from "react";
const SearchIcon = (props) => (
  <svg
    fill="none"
    viewBox="0 0 16 17"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M9.965 11.526a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
      fill="#6B7280"
      fillRule="evenodd"
    />
  </svg>
);
export default SearchIcon;
