import * as React from 'react';
const EditYellowIcon = (props) => (
	<svg fill="none" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path d="M18 36c9.941 0 18-8.059 18-18S27.941 0 18 0 0 8.059 0 18s8.059 18 18 18Z" fill="url(#edit-yellow-a)" />
		<path
			d="M25.943 9.591a2.362 2.362 0 0 0-1.726-.53 2.292 2.292 0 0 0-1.593.822l-6.321 7.6a3.526 3.526 0 0 0-.823 2.63 1.699 1.699 0 0 0 .61 1.2c.337.28.763.431 1.2.424h.213a3.655 3.655 0 0 0 2.443-1.248l6.294-7.569a2.38 2.38 0 0 0-.292-3.32l-.005-.009Zm-7.064 10.012a2.288 2.288 0 0 1-1.593.77c-.212 0-.372-.133-.4-.425a2.05 2.05 0 0 1 .5-1.54l4.3-5.152a.164.164 0 0 1 .186-.027l1.275 1.062a.165.165 0 0 1 .027.186l-4.295 5.126Zm6.294-7.569-.958 1.168a.164.164 0 0 1-.186.027l-1.275-1.062a.164.164 0 0 1-.027-.186l.956-1.169c.17-.195.407-.319.664-.345h.08a1 1 0 0 1 .61.212c.196.17.32.407.346.664a.972.972 0 0 1-.21.691Z"
			fill="#FFC300"
		/>
		<path
			d="M22.119 27.04h-9.613A3.5 3.5 0 0 1 9 23.532V13.92a3.5 3.5 0 0 1 3.506-3.506h5.179a.658.658 0 0 1 .664.664.741.741 0 0 1-.744.744h-5.1a2.085 2.085 0 0 0-2.1 2.1v9.614a2.085 2.085 0 0 0 2.1 2.1h9.614a2.085 2.085 0 0 0 2.1-2.1v-4.969a.658.658 0 0 1 .664-.664h.08a.658.658 0 0 1 .664.664v4.993a3.5 3.5 0 0 1-3.508 3.48Z"
			fill="#FFC300"
		/>
		<path
			d="M18 35.5c9.665 0 17.5-7.835 17.5-17.5S27.665.5 18 .5.5 8.335.5 18 8.335 35.5 18 35.5Z"
			stroke="#FFC300"
			strokeMiterlimit={10}
		/>
		<defs>
			<linearGradient gradientUnits="userSpaceOnUse" id="edit-yellow-a" x1={28.656} x2={-3.96} y1={3.816} y2={49.752}>
				<stop stopColor="#fff" />
				<stop offset={1} stopColor="#FFE696" />
			</linearGradient>
		</defs>
	</svg>
);
export default EditYellowIcon;
