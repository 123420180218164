import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	withStyles,
	Divider,
	CircularProgress,
	Popper,
	Fade,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { Check } from '@material-ui/icons';
import React, { useState, useEffect, useRef } from 'react';
import AscendingSortIcon from '@shared/icons/AscendingSortIcon';
import DescendingSortIcon from '@shared/icons/DescendingSortIcon';

const StyledSortLabel = withStyles(() => ({
	root: {
		width: '100%',
		justifyContent: 'space-between',
	},
}))(TableSortLabel);

const SubscriptionTransactionTableHead = ({
	columns,
	sort,
	order,
	onRequestSort,
	studentOrder,
	onRequestStudentNameOrderChange,
}) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [hoveredColumn, setHoveredColumn] = useState(null);

	const handlePopoverOpen = (event, columnId) => {
		setAnchorEl(event.currentTarget);
		setHoveredColumn(columnId);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
		setHoveredColumn(null);
	};

	const open = Boolean(anchorEl);

	const handleStudentNameOrderChange = (order, nameOrder) => {
		onRequestStudentNameOrderChange(order, nameOrder);
		handlePopoverClose();
	};

	const handleColumnOrderChange = (order, columnId) => {
		onRequestSort(order, columnId);
		handlePopoverClose();
	};

	return (
		<TableHead>
			<TableRow>
				{columns.map((headCell) => {
					const isStudentHeader = headCell.id === 'student';
					const isStudentSort = sort === 'student';
					const columnsWithSort = [
						'parent',
						'student',
						'room',
						'package_type',
						'subscription_date',
						'renewal_date',
					];

					return (
						<TableCell
							className={`font-bold text-pd-blue-dark ${headCell.widthClass}`}
							key={headCell.id}
							onMouseEnter={(event) => handlePopoverOpen(event, headCell.id)}
							onMouseLeave={handlePopoverClose}
							scope="col"
						>
							<StyledSortLabel
								active={sort === headCell.id}
								direction={sort === headCell.id ? order : 'asc'}
								hideSortIcon={!columnsWithSort.includes(headCell.id)}
							>
								<span className="text-md">{headCell.label}</span>
							</StyledSortLabel>
							{columnsWithSort.includes(headCell.id) ? (
								<Popper
									anchorEl={anchorEl}
									onClose={handlePopoverClose}
									open={open ? hoveredColumn === headCell.id : null}
									placement="bottom-start"
									transition
								>
									{({ TransitionProps }) => (
										<Fade {...TransitionProps}>
											<div className="flex flex-col p-4 min-w-216 bg-white shadow-xl">
												{isStudentHeader ? (
													<>
														<button
															className="flex items-center p-4 hover:bg-gray-100"
															onClick={() => handleStudentNameOrderChange('asc', 'last')}
															type="button"
														>
															<AscendingSortIcon />
															<span className="">Sort Ascending</span>
															<Check
																className={`absolute right-10 ${
																	isStudentSort &&
																	studentOrder === 'last' &&
																	order === 'asc'
																		? 'text-black'
																		: 'text-transparent'
																}`}
															/>
														</button>
														<Divider />
														<button
															className="flex items-center p-4 hover:bg-gray-100 active:bg-gray-200"
															onClick={() => handleStudentNameOrderChange('desc', 'last')}
															type="button"
														>
															<DescendingSortIcon />
															<span>Sort Descending</span>
															<Check
																className={`absolute right-10 ${
																	isStudentSort &&
																	studentOrder === 'last' &&
																	order === 'desc'
																		? 'text-black'
																		: 'text-transparent'
																}`}
															/>
														</button>
													</>
												) : (
													<>
														<button
															className="flex items-center p-4 hover:bg-gray-100"
															onClick={() => handleColumnOrderChange('asc', headCell.id)}
															type="button"
														>
															<AscendingSortIcon />
															<span className="">Sort Ascending</span>
															<Check
																className={`absolute right-10 ${
																	sort === headCell.id && order === 'asc'
																		? 'text-black'
																		: 'text-transparent'
																}`}
															/>
														</button>
														<Divider />
														<button
															className="flex items-center p-4 hover:bg-gray-100 active:bg-gray-200"
															onClick={() => handleColumnOrderChange('desc', headCell.id)}
															type="button"
														>
															<DescendingSortIcon />
															<span>Sort Descending</span>
															<Check
																className={`absolute right-10 ${
																	sort === headCell.id && order === 'desc'
																		? 'text-black'
																		: 'text-transparent'
																}`}
															/>
														</button>
													</>
												)}
											</div>
										</Fade>
									)}
								</Popper>
							) : null}
						</TableCell>
					);
				})}
			</TableRow>
		</TableHead>
	);
};

const SubscriptionTransactionTable = ({
	loading,
	data,
	page,
	setPage,
	columns,
	totalResults,
	filters,
	setFilters,
	hasFilterApplied,
}) => {
	const tableContainerRef = useRef(null);
	const handleRequestSort = (dir, property) => {
		setFilters({ ...filters, dir: dir, sort: property });
	};

	const handleChangePage = (_, newPage) => {
		setPage(newPage);
	};

	const onRequestStudentNameOrderChange = (order, nameOrder) => {
		setFilters({ ...filters, sort: 'student', student_name: nameOrder, dir: order });
	};
	useEffect(() => {
		if (tableContainerRef.current) {
			tableContainerRef.current.scrollTop = 0;
		}
	}, [filters]);

	return (
		<div className={`relative ${data?.length ? 'mb-96' : ''}`}>
			<TableContainer
				className="max-w-full flex-col w-full flex  whitespace-no-wrap relative min-h-512"
				ref={tableContainerRef}
			>
				<Table stickyHeader>
					<SubscriptionTransactionTableHead
						columns={columns}
						onRequestSort={handleRequestSort}
						onRequestStudentNameOrderChange={onRequestStudentNameOrderChange}
						order={filters.dir}
						sort={filters.sort}
						studentOrder={filters.student_name}
					/>
					<TableBody>
						{data?.map((row, index) => {
							return (
								<TableRow key={`${row.id}${row.created_at}${index}`}>
									{columns.map((column) => (
										<TableCell className="cursor-default" key={column.id}>
											{column.render(row)}
										</TableCell>
									))}
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
				{!hasFilterApplied ? (
					<div className="flex justify-center items-center h-full  z-99 absolute w-full">
						<p>Select a filter to see results</p>
					</div>
				) : !data?.length || loading ? (
					<div
						className={`${
							loading ? 'bg-black/25' : 'bg-white'
						} justify-center items-center h-full  z-99 absolute w-full`}
					>
						<div
							className="absolute"
							style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
						>
							{loading ? <CircularProgress /> : !loading && !data?.length ? <p>No data found</p> : null}
						</div>
					</div>
				) : null}
			</TableContainer>
			<div className="flex justify-center  sticky bottom-0 left-0 bg-white right-0">
				{data?.length ? (
					<Pagination
						color="primary"
						count={Math.ceil(totalResults / 20)}
						onChange={handleChangePage}
						page={page}
					/>
				) : null}
			</div>
		</div>
	);
};

export default SubscriptionTransactionTable;
