import React from "react";

const CustomSwitch = ({
  checked,
  disabled,
  onChange,
  className = "",
  label = "Toggle switch",
}) => {
  return (
    <label
      aria-label={label}
      className={`${className} ${disabled ? "disabled" : ""}`}
      style={{
        display: "inline-block",
        position: "relative",
        width: "36px",
        height: "20px",
        cursor: disabled ? "not-allowed" : "pointer",
        opacity: disabled ? 0.5 : 1,
      }}
    >
      <input
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        style={{ position: "absolute", width: 0, height: 0, opacity: 0 }}
        type="checkbox"
      />
      <div
        className="track"
        style={{
          position: "absolute",
          inset: 0,
          transition: "all 200ms",
          borderRadius: "20px",
          border: `1px solid ${checked ? "#04C01C" : "#9e9e9e"}`,
          backgroundColor: checked ? "#EFFDF0" : "#e0e0e0",
        }}
      />
      <div
        className="thumb"
        style={{
          position: "absolute",
          top: "2px",
          left: "2px",
          width: "16px",
          height: "16px",
          borderRadius: "50%",
          transition: "transform 200ms",
          transform: checked ? "translateX(16px)" : "translateX(0)",
          background: checked
            ? "linear-gradient(to bottom, #3CE852, #04C01C)"
            : "#757575",
          boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.2)",
        }}
      />
    </label>
  );
};

export default CustomSwitch;
