import axios from '@shared/axios-shared';

const getSchools = (
	name = '',
	type = '',
	schoolId = '',
	stateId = '',
	city = '',
	dateFrom = '',
	dateTo = '',
	page = '',
	isExport = false,
	forMap = 1
) => {
	return axios.get(
		`${
			import.meta.env.VITE_API_REPORTS_ENDPOINT
		}api/v1/schools?name=${name}&type=${type}&school_id=${schoolId}&state_id=${stateId}&city=${city}&date_from=${dateFrom}&date_to=${dateTo}&page=${page}&export=${isExport}&forMap=${forMap}&timeZoneOffset=${new Date().getTimezoneOffset()}`
	);
};

const getSchoolsList = () => {
	return axios.get(`/api/v1/owner/management/school-list`);
};

const getEnrolledParents = (export_id, name, school_id, state_id, city, dateFrom, dateTo, page) => {
	return axios.get(
		`${
			import.meta.env.VITE_API_REPORTS_ENDPOINT
		}api/v1/parent-enrollment?export=${export_id}&name=${name}&school_id=${school_id}&state=${state_id}&city=${city}&date_from=${dateFrom}&date_to=${dateTo}&page=${page}&timeZoneOffset=${new Date().getTimezoneOffset()}`
	);
};

const getCityList = (state_id) => {
	return axios.get(`/api/v1/cities?state_id=${state_id}`);
};

const getRevenueInfo = (export_id, name, school_id, state_id, city, dateFrom, dateTo, revenue_id, page) => {
	return axios.get(
		`${
			import.meta.env.VITE_API_REPORTS_ENDPOINT
		}api/v1/parent-subscription?export=${export_id}&name=${name}&school_id=${school_id}&state=${state_id}&city=${city}&date_from=${dateFrom}&date_to=${dateTo}&revenue=${revenue_id}&page=${page}&timeZoneOffset=${new Date().getTimezoneOffset()}`
	);
};

const exportCompanyData = (companyId) => {
	return axios.get(
		`${
			import.meta.env.VITE_API_REPORTS_ENDPOINT
		}api/v1/company-data?company_id=${companyId}&timeZoneOffset=${new Date().getTimezoneOffset()}`
	);
};

const exportSchoolData = (schoolId) => {
	return axios.get(
		`${
			import.meta.env.VITE_API_REPORTS_ENDPOINT
		}api/v1/company-data?school_id=${schoolId}&timeZoneOffset=${new Date().getTimezoneOffset()}`
	);
};

const getFinancialReportData = (filters, exportType) => {
	return axios.get(`/api/v2/owner/reports/financial`, {
		params: {
			page: filters.page || 1,
			start_date: filters.start_date ?? null,
			end_date: filters.end_date ?? null,
			timezone: filters.timezone ?? null,
			transaction_id: filters.transaction_id ?? null,
			school_id: filters.school_id ?? [],
			company_id: filters.company_id ?? [],
			parent_id: filters.parent_id ?? [],
			export: exportType ?? filters.export ?? null,
			sort: filters.sort ?? null,
			dir: filters.dir ?? null,
		},
	});
};

const getSubscribersList = (filters = {}) => {
	const params = {
		page: filters.page || 1,
		search: filters.search ?? '',
		school_id: filters.school_id ?? null,
		company_id: filters.company_id ?? null,
	};
	return axios.get('/api/v2/owner/reports/financial/subscribers', { params: params });
};

const getSubscriptionEvents = (params) => {
	return axios.get('/api/v2/owner/reports/subscription/events', {
		params,
	});
};

export {
	exportCompanyData,
	exportSchoolData,
	getCityList,
	getEnrolledParents,
	getRevenueInfo,
	getSchools,
	getSchoolsList,
	getFinancialReportData,
	getSubscribersList,
	getSubscriptionEvents,
};
