/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import history from '@history';
import {
	Table,
	TableHead,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Paper,
	CircularProgress,
	TextField,
	Button,
	Avatar,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';

import './SchoolRoyalties.css';
import FuseAnimate from '@fuse/core/FuseAnimate';
import { useDispatch } from 'react-redux';
import * as Actions from 'app/store/actions';
import InfiniteScroll from 'react-infinite-scroll-component';
import CustomDatePicker from '@shared/customComponents/CustomDatePicker/CustomDatePicker';
import { getSchoollistById, getroyalties } from 'app/services/paymentManagement/paymentManagement';
import { getCompany } from 'app/services/Company/companyService';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';

function CompanyDetail({ setActiveId, activeId }) {
	const dispatch = useDispatch();
	const [rows, setRows] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [hasMore, setHasMore] = useState(false);
	const [firstLoad, setFirstLoad] = useState(true);
	const [fetchingMore, setFetchingMore] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const [page, setPage] = useState(1);
	const [schools, setSchools] = useState([]);
	const [date_from, setDate_from] = useState('');
	const [date_to, setDate_to] = useState('');
	const [filters, setFilters] = useState({
		school_id: '',
	});
	const [Company, setCompany] = useState({});
	const [isSchoolloading, setIsSchoolloading] = useState(false);
	const [searchSchoolQuery, setSchoolSearchQuery] = useState();

	useEffect(() => {
		let isMounted = true;
		const timeout = setTimeout(
			() => {
				if (!isMounted) return;
				setIsSchoolloading(true);
				setSchools([]);
				if (!searchSchoolQuery) {
					getSchoollistById(activeId, '', 1)
						.then((res) => {
							if (!isMounted) return;
							setSchools(res.data.data);
						})
						.catch((err) => {
							if (!isMounted) return;
							dispatch(
								Actions.showMessage({
									message: 'Failed to get schools.',
									autoHideDuration: 1500,
									variant: 'error',
								}),
							);
						})
						.finally(() => {
							if (!isMounted) return;
							setIsSchoolloading(false);
						});
				} else {
					getSchoollistById(activeId, searchSchoolQuery, searchSchoolQuery ? undefined : 1)
						.then((res) => {
							if (!isMounted) return;
							setSchools(res.data.data);
						})
						.catch((err) => {
							if (!isMounted) return;
							dispatch(
								Actions.showMessage({
									message: 'Failed to get schools.',
									autoHideDuration: 1500,
									variant: 'error',
								}),
							);
						})
						.finally(() => {
							if (!isMounted) return;
							setIsSchoolloading(false);
						});
				}
			},
			firstLoad ? 0 : 1000,
		);
		return () => {
			isMounted = false;
			clearTimeout(timeout);
		};
	}, [dispatch, searchSchoolQuery, filters]);

	useEffect(() => {
		let isMounted = true;
		getCompany(activeId)
			.then((res) => {
				if (!isMounted) return;
				setCompany(res.data);
			})
			.catch((err) => {
				if (!isMounted) return;
				dispatch(
					Actions.showMessage({
						message: 'Failed to get schools.',
						autoHideDuration: 1500,
						variant: 'error',
					}),
				);
			});
		return () => {
			isMounted = false;
		};
	}, []);

	useEffect(() => {
		let isMounted = true;

		const timeout = setTimeout(
			() => {
				if (!isMounted) return;
				setIsLoading(true);
				getroyalties(filters.school_id, date_from, date_to, activeId)
					.then((res) => {
						if (!isMounted) return;
						setFirstLoad(false);
						setRows(res.data.data);
						if (res.data.current_page < res.data.last_page) {
							setPage(page + 1);
						}
					})
					.catch((err) => {
						if (!isMounted) return;
						dispatch(
							Actions.showMessage({
								message: 'Failed to fetch data, please refresh',
								variant: 'error',
							}),
						);
					})
					.finally(() => {
						if (!isMounted) return;
						setIsLoading(false);
					});
			},
			firstLoad ? 0 : 1000,
		);
		return () => {
			isMounted = false;
			clearTimeout(timeout);
		};
		// eslint-disable-next-line
	}, [refresh, filters, date_from, date_to]);

	const handleLoadMore = () => {
		setFetchingMore(true);
		getroyalties(filters.school_id, filters.date_from, filters.date_to, activeId)
			.then((res) => {
				if (res.data.last_page > res.data.current_page) {
					setHasMore(true);
				} else {
					setHasMore(false);
				}
				setPage(res.data.current_page + 1);
				setRows(rows.concat(res.data.data));
				setFirstLoad(false);
			})
			.catch((err) => {
				setFirstLoad(false);
				dispatch(
					Actions.showMessage({
						message: 'Failed to fetch data, please refresh',
						variant: 'error',
					}),
				);
			});
	};

	// const handleFilters = ev => {
	// 	if (ev.target.name === 'school_id') {
	// 		setFilters({ ...filters, school_id: ev.target.value });
	// 	}

	// 	if (date_from) {
	// 		setFilters({ ...filters, date_from: ev.target.value });
	// 	}
	// 	if (date_to) {
	// 		setFilters({ ...filters, date_to: ev.target.value });
	// 	}
	// };

	const handleFilters = (ev) => {
		const { name, value } = ev.target;
		setFilters({ ...filters, [name]: value });
	};

	return (
		<>
			<FuseAnimate animation="transition.slideLeftIn" duration={600}>
				<div className="mx-auto payment-cont">
					<div className="flex items-center flex-nowrap justify-between">
						<div className="flex gap-10">
							<Button
								onClick={() => {
									setActiveId(null);
								}}
							>
								<img
									alt="Go Back"
									className="cursor-pointer"
									src="assets/images/arrow-long.png"
									style={{ width: '25px' }}
								/>
							</Button>

							<Avatar className="mr-6" alt="student-face" src={Company?.logo} />
							<div className="flex items-center">
								<h3 className="mr-28" style={{ fontSize: '20px', fontWeight: '700' }}>
									{Company?.name}
								</h3>
							</div>
						</div>
						<div className="flex justify-between">
							<div className="flex">
								<div className="mx-8">
									{/* <FormControl>
										<InputLabel id="roomLabel">Filter By School</InputLabel>
										<Select
											name="school_id"
											onChange={handleFilters}
											value={filters.school_id}
											labelId="roomLabel"
											id="school_id"
											label="Filter by School"
											style={{ width: 200 }}
											endAdornment={
												filters.school_id ? (
													<IconButton size="small" className="mr-16">
														<Close
															onClick={() =>
																setFilters({
																	...filters,
																	school_id: ''
																})
															}
															fontSize="small"
														/>
													</IconButton>
												) : (
													''
												)
											}
										>
											{school.length ? (
												school.map(school => {
													return (
														<MenuItem key={school.id} value={school.id}>
															{school.name}
														</MenuItem>
													);
												})
											) : (
												<MenuItem disabled>Loading...</MenuItem>
											)}
										</Select>
									</FormControl> */}
									<Autocomplete
										id="state-autocomplete"
										className="mx-8"
										options={schools}
										renderOption={(option) => (
											<>
												<div className="flex" style={{ gap: 10 }}>
													<div>{option.name}</div>
												</div>
											</>
										)}
										getOptionLabel={(option) => option.name}
										autoComplete={false}
										clearOnBlur={false}
										disableClearable
										loading={isSchoolloading}
										loadingText="...Loading"
										sx={{ width: '100%' }}
										onChange={(_e, v) => {
											setFilters({ ...filters, school_id: v?.id || '' });
										}}
										onInputChange={(e, value) => {
											setSchoolSearchQuery(value);
											if (value === '') {
												setFilters({ ...filters, school_id: '' });
											}
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Filter By School"
												style={{ width: '200px' }}
												autoComplete="off"
											/>
										)}
									/>
								</div>
								<div style={{ width: '30%', marginRight: '20px' }}>
									<CustomDatePicker
										label="Date From"
										value={date_from}
										onChange={handleFilters}
										setValue={(date) => setDate_from(date?.format('YYYY-MM-DD') || '')}
										disableFuture
										maxDate={date_to || undefined}
									/>
								</div>
								<div style={{ width: '30%' }}>
									<CustomDatePicker
										value={date_to}
										setValue={(date) => setDate_to(date?.format('YYYY-MM-DD') || '')}
										label="Date to"
										onChange={handleFilters}
										disableFuture
										minDate={date_from || undefined}
									/>
								</div>
							</div>
						</div>
					</div>
					<TableContainer id="Scrollable-table" component={Paper} className="payment-table-cont">
						<Table stickyHeader className="payment-table" style={{ width: '100%' }}>
							<TableHead>
								<TableRow>
									<TableCell style={{ width: '20%' }} className="bg-white paymentTableHeader">
										School Name
									</TableCell>
									<TableCell style={{ width: '20%' }} className="bg-white companyTableHeader">
										Active Subscribers
									</TableCell>
									<TableCell style={{ width: '20%' }} className="bg-white companyTableHeader">
										Pending Amount
									</TableCell>
									<TableCell style={{ width: '15%' }} className="bg-white companyTableHeader">
										Last Month Amount{' '}
									</TableCell>
									<TableCell style={{ width: '15%' }} className="bg-white companyTableHeader">
										Total Amount
									</TableCell>
									<TableCell style={{ width: '10%' }} className="bg-white companyTableHeader">
										Date
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody className="">
								{isLoading ? (
									<TableRow>
										<TableCell align="center" colSpan={8}>
											<CircularProgress />
										</TableCell>
									</TableRow>
								) : !rows.length && !firstLoad ? (
									<TableRow>
										<TableCell align="center" colSpan={8}>
											No records found
										</TableCell>
									</TableRow>
								) : (
									rows?.map((row) => (
										<TableRow key={`school-${row.id}`}>
											<TableCell style={{ fontWeight: 700 }} component="th" scope="row">
												<div className="flex items-center">
													<Avatar className="mr-6" alt="student-face" src={row?.logo} />
													<div className="flex flex-col items-center">
														<div
															className="parent-name truncate add-width"
															style={{ fontSize: '12px' }}
														>
															{row.name}
														</div>
													</div>
												</div>
											</TableCell>

											<TableCell style={{ fontWeight: 700 }} component="th" scope="row">
												<div className="flex items-center">
													<div className="company-name truncate">
														{row.active_subscriptions}
													</div>
												</div>
											</TableCell>
											<TableCell style={{ fontWeight: 700 }} component="th" scope="row">
												<div className="flex items-center">
													<div className="company-name truncate">
														${row.pending_amount.toFixed(2)}
													</div>
												</div>
											</TableCell>
											<TableCell style={{ fontWeight: 700 }} component="th" scope="row">
												<div className="flex items-center">
													<div className="company-name truncate">
														${row.last_month_amount.toFixed(2)}
													</div>
												</div>
											</TableCell>
											<TableCell style={{ fontWeight: 700 }} component="th" scope="row">
												<div className="flex items-center">
													<div
														className="company-name truncate"
														style={{
															color: `${row?.earning_trend ? '#04C01C' : '#FF4B4B'}`,
														}}
													>
														${row.total_amount_for_school.toFixed(2)}
														<img
															className="width-image"
															src={
																row?.earning_trend
																	? 'assets/images/growth.png'
																	: 'assets/images/less.png'
															}
															alt={row?.earning_trend ? 'Trending Up' : 'Trending Down'}
														/>
													</div>
												</div>
											</TableCell>
											<TableCell style={{ fontWeight: 700 }} component="th" scope="row">
												{row.last_date ? (
													<div className="flex flex-col">
														<div
															style={{
																fontSize: '12px',
																fontWeight: '600',
																color: '#000',
															}}
														>
															{moment.utc(row?.last_date).local().format('L')}
														</div>
														<div className="subscribe-date">
															{moment.utc(row?.last_date).local().format('LT')}
														</div>
													</div>
												) : (
													'-'
												)}
											</TableCell>
										</TableRow>
									))
								)}
								{fetchingMore ? (
									<TableRow>
										<TableCell align="center" colSpan={8}>
											<CircularProgress />
										</TableCell>
									</TableRow>
								) : (
									<></>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<InfiniteScroll
						dataLength={rows.length}
						next={handleLoadMore}
						hasMore={hasMore}
						scrollableTarget="Scrollable-table"
					/>
				</div>
			</FuseAnimate>
		</>
	);
}
export default CompanyDetail;
