import React from 'react';

const ResetPasswordPageConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false,
				},
				toolbar: {
					display: false,
				},
				footer: {
					display: false,
				},
			},
		},
	},
	routes: [
		{
			path: '/password/reset',
			component: React.lazy(() => import('./ResetPasswordWithLinkPage')),
		},
	],
};

export default ResetPasswordPageConfig;
