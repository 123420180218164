import PrintIcon from '@shared/icons/PrintIcon';
import { CircularProgress } from '@material-ui/core';
import React from 'react';

const PrintButton = ({ onClick, printLoading, exportsDisabled }) => {
	return (
		<button
			className={`bg-primary-500 border-2 rounded-4 font-bold flex items-center border-pd-blue text-pd-blue gap-8 px-16 md:px-24 py-8 md:py-12 ${
				exportsDisabled ? 'opacity-50' : ''
			}`}
			disabled={printLoading || exportsDisabled}
			onClick={onClick}
			style={{ cursor: printLoading ? 'not-allowed' : 'pointer' }}
			type="button"
		>
			{printLoading ? (
				<CircularProgress className="mx-auto" size={24} />
			) : (
				<>
					<PrintIcon className="w-24" />
					Print
				</>
			)}
		</button>
	);
};

export default PrintButton;
