import { Divider } from "@material-ui/core";
import React from "react";
import FilterBubble from "../FilterBubble";

const UserAppliedFilters = ({ filters, setFilters }) => {
  const handleRemoveFilter = (filter, value) => {
    if (Array.isArray(filters[filter])) {
      setFilters({
        ...filters,
        [filter]: filters[filter].filter((item) => item.value !== value),
      });
    } else {
      setFilters({ ...filters, [filter]: undefined });
    }
  };

  const getFilterCategory = (filter, item) => {
    switch (filter) {
      case "status":
        return "Status";
      case "group":
        return "User Group";
      case "school_id":
        return item.type === "company" ? "Company" : "School";
      case "searchQuery":
        return "Search";
      default:
        return "Other";
    }
  };

  const filtersToHide = ["sort", "dir", "page"];

  return (
    <div
      className="px-24 md:px-32 flex items-center py-10 min-h-[56px]"
      style={{ background: "#F3F4F6" }}
    >
      <div className="flex gap-12 items-center flex-wrap">
        <span className="">Applied</span>
        <span className="">
          <Divider className="  py-12" flexItem orientation="vertical" />
        </span>
        {Object.keys(filters)
          ?.filter(
            (filter) => filters[filter] && !filtersToHide.includes(filter)
          )
          ?.map((filter) => {
            const filterValues = Array.isArray(filters[filter])
              ? filters[filter]
              : [filters[filter]];
            return filterValues.map((item) => {
              return (
                <FilterBubble
                  category={getFilterCategory(filter, item)}
                  key={`${filter}-${item.value}`}
                  onClose={() => handleRemoveFilter(filter, item.value)}
                  title={item.label || item.value || item}
                />
              );
            });
          })}
      </div>
    </div>
  );
};

export default UserAppliedFilters;
