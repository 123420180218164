import axios from '@shared/axios-shared';

export const getUsers = async (params) => {
	return axios.get(`/api/v2/users?${params}`);
};

export const getSingleUser = async (id) => {
	return axios.get(`/api/v2/users/${id}`);
};

export const bulkActivateUsers = async (data) => {
	return axios.post('/api/v2/users/action/activate', data);
};

export const bulkDeactivateUsers = async (data) => {
	return axios.post('/api/v2/users/action/deactivate', data);
};

export const bulkDeleteUsers = async (data) => {
	return axios.delete('/api/v2/users/action/delete', data);
};

export const bulkResetPasswordUsers = async (data) => {
	return axios.post('/api/v2/users/action/password', data);
};

export const getCompanies = async () => {
	return axios.get('/api/v2/owner/companies');
};

export const getSchools = async () => {
	return axios.get('/api/v2/owner/schools');
};