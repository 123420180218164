import ResetPassword from '@shared/components/auth/ResetPassword';
import * as Actions from '@/app/store/actions';
import { useDispatch } from 'react-redux';
import { resetPassword } from '../../../../services/resetPassword/resetPassword';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
const ProductOwnerResetPasswordPage = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [username] = useState(history?.location?.state?.username);

	useEffect(() => {
		if (!username) {
			history.push('/forgot-password');
		}
	}, [dispatch, username]);

	const handleSuccess = (message) => {
		dispatch(
			Actions.showMessage({
				message: `${message}. Redirecting...`,
				variant: 'success',
				autoHideDuration: 3000,
			})
		);
	};

	const handleError = (message) => {
		dispatch(
			Actions.showMessage({
				message,
				variant: 'error',
				autoHideDuration: 3000,
			})
		);
	};

	const handleResetPassword = async (data) => {
		return resetPassword({
			...data,
			role: 'product_owner',
			username,
		});
	};

	return (
		<div className="min-h-screen flex items-center justify-center p-4 bg-[url('/assets/images/banner/bg.png')] bg-no-repeat bg-center">
			<ResetPassword onError={handleError} onResetPassword={handleResetPassword} onSuccess={handleSuccess} />
		</div>
	);
};

export default ProductOwnerResetPasswordPage;
