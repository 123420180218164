// Internet Explorer 11 requires polyfills and partially supported by this project.
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import 'typeface-muli';
import './i18n';
import './styles/index.css';
import App from 'app/App';
import axios from '@shared/axios-shared';
import JwtService from 'app/services/jwtService';
import * as Sentry from '@sentry/react';
import * as serviceWorker from './serviceWorker';
import History from '@history/@history';

axios.defaults.baseURL = import.meta.env.VITE_API_ENDPOINT;
axios.interceptors.response.use(
	(response) => {
		return response;
	},
	(err) => {
		return new Promise((resolve, reject) => {
			if (err?.response?.status === 401 && err?.config && !err?.config?.__isRetryRequest) {
				// if you ever get an unauthorized response, logout the user
				JwtService.setSession(null);
				localStorage.clear();
				JwtService.emit('onAutoLogout', err?.response?.data?.message);
				History.push('/login');
			}
			throw err;
		});
	}
);

if (import.meta.env.VITE_SENTRY_DSN) {
	Sentry.init({
		dsn: import.meta.env.VITE_SENTRY_DSN,
		environment: `po-${import.meta.env.VITE_ENV}`,
		replaysSessionSampleRate: import.meta.env.VITE_SENTRY_TRACERATE,
		integrations: [
			new Sentry.Replay({
				maskAllText: true,
				blockAllMedia: true,
			}),
		],
		tracesSampleRate: import.meta.env.VITE_SENTRY_TRACERATE,
	});
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
