import React from "react";
import { FormControlLabel, Popover } from "@material-ui/core";
import { useState } from "react";
import StyledCheckbox from "./StyledCheckbox";
import ChevronDownIcon from "../icons/ChevronDownIcon";

const PopoverSelect = ({
  currentFilter,
  title,
  setFilter,
  filters,
  options,
  compact = false,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCheckboxChange = (option) => {
    const newFilter =
      filters[currentFilter]?.value === option.value ? null : option;
    setFilter({ ...filters, [currentFilter]: newFilter });
  };

  const open = Boolean(anchorEl);

  const hasSelected = filters?.[currentFilter] !== null;

  return (
    <div>
      <button
        className={`flex items-center gap-8 md:text-16 border border-transparent rounded-md  p-4 ${
          compact ? "!text-14 text-[#374151] font-medium" : "text-16"
        }`}
        onClick={handleClick}
        type="button"
      >
        {title}{" "}
        {hasSelected ? (
          <span
            className={`text-10 text-black rounded  h-16 w-16 flex items-center justify-center ${
              compact ? "bg-grey-300" : "bg-white"
            }`}
          >
            1
          </span>
        ) : (
          ""
        )}{" "}
        <ChevronDownIcon className="w-10" />
      </button>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={handleClose}
        open={open}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <div className="flex flex-col gap-4  my-0 p-8 border border-grey-200 rounded">
          {options?.map((option) => (
            <FormControlLabel
              className="gap-8 px-8  mx-0 hover:bg-grey-200 rounded-md w-full"
              control={
                <StyledCheckbox
                  checked={filters?.[currentFilter]?.value === option.value}
                  color="primary"
                  disableRipple
                  onChange={() => handleCheckboxChange(option)}
                />
              }
              key={option.value}
              label={option.label}
              labelPlacement="end"
            />
          ))}
        </div>
      </Popover>
    </div>
  );
};

export default PopoverSelect;
