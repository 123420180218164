import * as React from 'react';
const DescendingSortIcon = (props) => (
	<svg fill="none" height={32} width={32} xmlns="http://www.w3.org/2000/svg" {...props}>
		<g opacity={0.25}>
			<path
				clipRule="evenodd"
				d="m12.08 17.5 1.897 5h-1.07l-.38-1h-2.132l-.338 1H9l1.69-5h1.389Zm-1.347 3 .667-1.973.748 1.973h-1.415Z"
				fill="#000"
				fillRule="evenodd"
			/>
			<path d="M14 13h-4v-.27l3.5-3.446V9h-4" stroke="#000" strokeMiterlimit={4.257} />
			<path d="M20.5 9v14m0 0-3-3m3 3 3-3" stroke="#000" />
		</g>
	</svg>
);
export default DescendingSortIcon;
