import axios from '@shared/axios-shared';

const getPackages = (search) => {
	return axios.get(`/api/v1/owner/package/list?package[name]=${search}`);
};

const getPackageById = (id) => {
	return axios.get(`api/v1/owner/package/${id}`);
};

const updatePackage = (data) => {
	return axios.patch('/api/v1/owner/package', data);
};

export { getPackageById, getPackages, updatePackage };
