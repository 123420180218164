import React, { useState, useEffect, useRef } from 'react';
import history from '@history';
import {
	Table,
	TableHead,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Paper,
	CircularProgress,
	IconButton,
	Avatar,
	InputLabel,
	Select,
	MenuItem,
	FormControl,
	Typography,
	TextField,
	InputAdornment,
} from '@material-ui/core';
import FuseAnimate from '@fuse/core/FuseAnimate';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from 'app/store/actions';
import CustomButton from 'app/customComponents/CustomButton/CustomButton';
import './SchoolEnrollment.css';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import CustomDatePicker from '@shared/customComponents/CustomDatePicker/CustomDatePicker';
import { useReactToPrint } from 'react-to-print';
import { Close } from '@material-ui/icons';
import { getSchools, getSchoolsList } from 'app/services/reports/reports';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getStateListByCountry, getCityList } from 'app/services/Company/companyService';

export default function SchoolEnrollment() {
	const dispatch = useDispatch();
	const user = useSelector(({ auth }) => auth.user);
	const [rows, setRows] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [hasMore, setHasMore] = useState(false);
	const [firstLoad, setFirstLoad] = useState(true);
	const [fetchingMore, setFetchingMore] = useState(false);
	const checkInReportRef = useRef(null);
	const [cityList, setCityList] = React.useState([]);
	const [dateFrom, setDateFrom] = useState('');
	const [dateTo, setDateTo] = useState('');
	const [iisLoading, ssetIsLoading] = useState(false);
	const [page, setPage] = useState(1);
	const [filters, setFilters] = useState({
		name: '',
		type: '',
		schoolId: '',
		stateId: '',
		city: '',
		dateFrom: '',
		dateTo: '',
		isExport: '',
	});

	const [data, setData] = useState([]);
	const [isLoadingExport, setIsLoadingExport] = useState(false);
	const [stateList, setStateList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [companyPage, setCompanyPage] = useState(1);
	const [schools, setschool] = useState([]);
	const handlePrint = useReactToPrint({
		content: () => checkInReportRef.current,
	});
	const [states, setStates] = useState([]);

	useEffect(() => {
		let isMounted = true;
		if (filters.stateId) {
			getCityList(filters.stateId).then((res) => {
				if (!isMounted) return;
				setCityList(res.data);
			});
		} else {
			setCityList([]);
		}
		return () => {
			isMounted = false;
		};
	}, [filters.stateId]);

	useEffect(() => {
		let isMounted = true;
		getStateListByCountry('USA').then((res) => {
			if (!isMounted) return;
			setStateList(res.data);
		});
		return () => {
			isMounted = false;
		};
	}, []);

	// useEffect(() => {
	// 	if (filters.type === 'company') {
	// 		getCompanyList('', '', '', companyPage)
	// 			.then(res => {
	// 				setData(res.data.data || []);
	// 				if (res.data.current_page < res.data.last_page) {
	// 					setCompanyPage(companyPage + 1);
	// 				}
	// 			})
	// 			.catch(err => {
	// 				dispatch(
	// 					Actions.showMessage({
	// 						message: 'Failed to get company.',
	// 						autoHideDuration: 1500,
	// 						variant: 'error'
	// 					})
	// 				);
	// 			});
	// 	} else if (filters.type === 'school') {
	// 		getSchoolsList()
	// 			.then(res => {
	// 				setData(res.data);
	// 			})
	// 			.catch(err => {
	// 				dispatch(
	// 					Actions.showMessage({
	// 						message: 'Failed to get schools.',
	// 						autoHideDuration: 1500,
	// 						variant: 'error'
	// 					})
	// 				);
	// 			});
	// 	} else {
	// 		return;
	// 	}
	// }, [companyPage, filters.type, dispatch]);

	useEffect(() => {
		let isMounted = true;
		getSchoolsList()
			.then((res) => {
				if (!isMounted) return;
				setschool(res.data);
			})
			.catch((err) => {
				if (!isMounted) return;
				dispatch(
					Actions.showMessage({
						message: 'Failed to get schools.',
						autoHideDuration: 1500,
						variant: 'error',
					}),
				);
			});

		getStateListByCountry('USA')
			.then((res) => {
				if (!isMounted) return;
				setStates(res.data);
			})
			.catch((err) => {
				if (!isMounted) return;
				dispatch(
					Actions.showMessage({
						message: 'Failed to get states.',
						autoHideDuration: 1500,
						variant: 'error',
					}),
				);
			});
		return () => {
			isMounted = false;
		};
	}, [dispatch]);

	const handleLoadMore = () => {
		setFetchingMore(true);
		getSchools(
			filters.name,
			filters.type,
			filters.schoolId === 'All' ? '' : filters.schoolId,
			filters.stateId,
			filters.city,
			filters.dateFrom,
			filters.dateTo,
			page,
			filters.isExport,
			0,
		)
			.then((res) => {
				if (res.data.last_page > res.data.current_page) {
					setHasMore(true);
				} else {
					setHasMore(false);
				}
				setPage(res.data.current_page + 1);
				setRows(rows.concat(res.data.data));
				setFetchingMore(false);
			})
			.catch((err) => {
				setFetchingMore(false);
				dispatch(
					Actions.showMessage({
						message: 'Failed to fetch data, please refresh',
						variant: 'error',
					}),
				);
			});
	};
	const handleExport = () => {
		getSchools(
			filters.name,
			filters.type,
			filters.schoolId === 'All' ? '' : filters.schoolId,
			filters.stateId,
			filters.city,
			filters.dateFrom,
			filters.dateTo,
			'',
			1,
			0,
		)
			.then((res) => {
				const blob = new Blob([res.data], {
					type: 'text/csv',
				});
				const link = document.createElement('a');
				link.setAttribute('target', '_blank');
				link.href = window.URL.createObjectURL(blob);
				link.setAttribute('download', `Schools_Enrollment_${new Date().getTime()}.csv`);
				document.body.appendChild(link);
				link.click();
				// Clean up and remove the link
				link.parentNode.removeChild(link);
				setIsLoadingExport(false);
			})
			.catch((err) => {
				dispatch(
					Actions.showMessage({
						message: 'Failed to Export',
						variant: 'error',
					}),
				);
				setIsLoadingExport(false);
			})
			.finally(() => {
				setIsLoadingExport(false);
			});
	};

	const ApplyFilters = () => {
		setRows([]);
		setIsLoading(true);
		setFirstLoad(false);
		getSchools(
			filters.name,
			filters.type,
			filters.schoolId === 'All' ? '' : filters.schoolId,
			filters.stateId,
			filters.city,
			filters.dateFrom,
			filters.dateTo,
			1,
			filters.isExport,
			0,
		)
			.then((res) => {
				setFirstLoad(false);
				setRows(res.data.data || []);
				if (res.data.last_page > res.data.current_page) {
					setHasMore(true);
				} else {
					setHasMore(false);
				}
				setPage(res.data.current_page + 1);
				dispatch(
					Actions.showMessage({
						message: 'Report has been generated',
						variant: 'success',
					}),
				);
				// setPage(res.data.current_page + 1);
			})
			.catch((err) => {
				dispatch(
					Actions.showMessage({
						message: 'Failed to fetch data, please refresh',
						variant: 'error',
					}),
				);
			})
			.finally(() => {
				setIsLoading(false);
			});
		setPage(1);
	};

	const handleFilters = (ev) => {
		const { name, value } = ev.target;
		setFilters({ ...filters, [name]: value });
	};

	return (
		<FuseAnimate animation="transition.slideLeftIn" duration={600}>
			<div className="mx-auto allergy-cont">
				<div className="flex items-center flex-nowrap justify-between">
					<div className="reports-topDiv">
						<h1 className="">
							{' '}
							<span className="">
								<IconButton
									onClick={() => {
										history.push('/reports');
									}}
								>
									<img src="assets/images/arrow-long.png" alt="filter" width="24px" className="" />
								</IconButton>
							</span>{' '}
							<span className="text-pd-xl self-end font-bold mr-28">Schools Enrollment</span>
						</h1>

						<p>Detailed data of the information of schools/companies</p>
					</div>
					<div className="flex justify-between">
						<div className="flex">
							<div className="self-end btn-aller">
								<span>
									{!iisLoading ? (
										<CustomButton
											style={{
												marginRight: '17px',
											}}
											onClick={handleExport}
											variant="primary"
											height="40px"
											width="100px"
											marginRight="17px"
										>
											<span className="mr-4">
												<FontAwesomeIcon icon={faDownload} />
											</span>
											Export
										</CustomButton>
									) : (
										<div className="circle-bar">
											<CircularProgress size={35} />
										</div>
									)}
									<CustomButton
										onClick={() => handlePrint()}
										variant="secondary"
										height="40px"
										width="100px"
									>
										<i className="fa fa-print" aria-hidden="true" /> Print
									</CustomButton>
									{/* <div className="help-btn cursor-pointer">
										<img src="assets/images/CENTER.png" />
										Help Center
									</div> */}
								</span>
							</div>
						</div>
					</div>
				</div>

				<div className="flex items-center flex-nowrap justify-between">
					<span className="text-pd-2xl self-end font-extrabold mr-28" />
					<div className="flex justify-between">
						<div className="flex">
							<div className="mx-8">
								<TextField
									name="name"
									className="mx-8"
									style={{ width: 148 }}
									id="name"
									value={filters.name}
									onChange={handleFilters}
									label="Search By Name"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													onClick={() => {
														document.getElementById('name').focus();
													}}
												>
													<img
														alt="search-icon"
														src="assets/images/search-icon.svg"
														height="80%"
														width="80%"
													/>
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</div>

							{/* <div className="mx-8">
								<FormControl>
									<InputLabel id="typeLabel">Type</InputLabel>
									<Select
										name="type"
										onChange={handleFilters}
										value={filters.type}
										labelId="typeLabel"
										id="type"
										label="Type"
										style={{ width: 110 }}
										endAdornment={
											filters.type ? (
												<IconButton size="small" className="mr-16">
													<Close
														onClick={() =>
															setFilters({
																...filters,
																type: '',
																schoolId: ''
															})
														}
														fontSize="small"
													/>
												</IconButton>
											) : (
												''
											)
										}
									>
										<MenuItem value="school">School</MenuItem>
										<MenuItem value="company">Company</MenuItem>
									</Select>
								</FormControl>
							</div>
							<div className="mx-8">
								<FormControl>
									<InputLabel id="roomLabel">School/Company</InputLabel>
									<Select
										name="schoolId"
										onChange={handleFilters}
										value={filters.schoolId}
										labelId="roomLabel"
										id="schoolId"
										label="Filter by School"
										style={{ width: 130 }}
										endAdornment={
											filters.schoolId ? (
												<IconButton size="small" className="mr-16">
													<Close
														onClick={() =>
															setFilters({
																...filters,
																schoolId: ''
															})
														}
														fontSize="small"
													/>
												</IconButton>
											) : (
												''
											)
										}
									>
										<MenuItem value="All">All</MenuItem>
										{!filters.type ? (
											<MenuItem disabled value="">
												<span>Select Type</span>
											</MenuItem>
										) : data.length > 0 ? (
											data?.map(name => {
												return (
													<MenuItem key={name.id} value={name.name}>
														<span>{name.name}</span>
													</MenuItem>
												);
											})
										) : (
											<MenuItem disabled value="">
												<span>Loading...</span>
											</MenuItem>
										)}
									</Select>
								</FormControl>
							</div> */}

							<div className="mx-8">
								<FormControl>
									<InputLabel id="roomLabel">School</InputLabel>
									<Select
										name="schoolId"
										onChange={handleFilters}
										value={filters.schoolId}
										labelId="schoolId"
										id="schoolId"
										label="School"
										style={{ width: 110 }}
										endAdornment={
											filters.schoolId ? (
												<IconButton
													id="clear-room-filter"
													size="small"
													className="mr-16"
													onClick={() => {
														setFilters({
															...filters,
															schoolId: '',
														});
													}}
												>
													<Close fontSize="small" />
												</IconButton>
											) : (
												''
											)
										}
									>
										<MenuItem value="All">All</MenuItem>
										{schools.length ? (
											schools.map((school) => {
												return (
													<MenuItem key={school.id} value={school.id} id={school.id}>
														{school.name}
													</MenuItem>
												);
											})
										) : (
											<MenuItem disabled>Loading...</MenuItem>
										)}
									</Select>
								</FormControl>
							</div>

							<div className="mx-4">
								<FormControl>
									<InputLabel id="stateId">State</InputLabel>
									<Select
										name="stateId"
										onChange={handleFilters}
										labelId="stateId"
										id="stateId"
										label="States"
										value={filters.stateId}
										style={{ width: 100 }}
										endAdornment={
											filters.stateId ? (
												<IconButton
													size="small"
													className="mr-16"
													onClick={() => {
														setFilters({
															...filters,
															stateId: '',
															city: '',
														});
													}}
												>
													<Close fontSize="small" />
												</IconButton>
											) : (
												''
											)
										}
									>
										{!loading ? (
											stateList?.map((state) => {
												return (
													<MenuItem key={state.id} value={state.id}>
														<span>{state.name}</span>
													</MenuItem>
												);
											})
										) : (
											<MenuItem disabled>Loading...</MenuItem>
										)}
									</Select>
								</FormControl>
							</div>
							<div className="mx-4">
								<FormControl>
									<InputLabel id="city">City</InputLabel>
									<Select
										name="city"
										onChange={handleFilters}
										labelId="city"
										id="city"
										label="City"
										value={filters.city}
										style={{ width: 100 }}
										endAdornment={
											filters.city ? (
												<IconButton
													size="small"
													className="mr-16"
													onClick={() => {
														setFilters({
															...filters,
															city: '',
														});
													}}
												>
													<Close fontSize="small" />
												</IconButton>
											) : (
												''
											)
										}
									>
										{!filters.stateId ? (
											<MenuItem disabled value="">
												<span>Select State</span>
											</MenuItem>
										) : cityList.length > 0 ? (
											cityList?.map((city) => {
												return (
													<MenuItem key={city.id} value={city.name}>
														<span>{city.name}</span>
													</MenuItem>
												);
											})
										) : (
											<MenuItem disabled value="">
												<span>Loading...</span>
											</MenuItem>
										)}
									</Select>
								</FormControl>
							</div>

							<div className="mx-10 student-date-field" style={{ width: 135 }}>
								<CustomDatePicker
									id="date-from"
									label="Date From"
									value={filters.dateFrom}
									setValue={(Date) => {
										setFilters({ ...filters, dateFrom: Date?.format('YYYY-MM-DD') || '' });
									}}
									maxDate={filters.dateTo || undefined}
									disableFuture
								/>
							</div>
							<div className="mr-20 ml-10 student-date-field" style={{ width: 135 }}>
								<CustomDatePicker
									id="date-to"
									label="Date To"
									value={filters.dateTo}
									setValue={(Date) => {
										setFilters({ ...filters, dateTo: Date?.format('YYYY-MM-DD') || '' });
									}}
									minDate={filters.dateFrom || undefined}
									disableFuture
								/>
							</div>
						</div>
						<div className="self-end">
							<span>
								<span className="mx-4">
									<CustomButton
										variant="secondary"
										height="43"
										width="140px"
										fontSize="15px"
										disabled={
											!filters.name &&
											!filters.schoolId &&
											!filters.type &&
											!filters.stateId &&
											!filters.city &&
											!filters.dateFrom &&
											!filters.dateTo
										}
										onClick={() => {
											ApplyFilters();
										}}
									>
										Apply
									</CustomButton>
								</span>
							</span>
						</div>
					</div>
				</div>

				{/* table  */}
				<TableContainer id="Scrollable-table" component={Paper} className="allergy-table-cont">
					<div style={{ display: 'none' }}>
						<div ref={checkInReportRef} className="p-32">
							<div className="flex flex-row justify-between">
								<div>
									<img src="assets/images/logos/logo22.png" alt="" />
								</div>
								<div style={{ textAlign: 'right' }}>
									<Typography variant="subtitle1">
										<span style={{ display: 'block', marginBottom: '-0.7em', writingMode: '' }}>
											{user?.data?.school?.address}
										</span>{' '}
										{/* <span>Hom Lake, Orlando, FL 38637</span> */}
									</Typography>
									<Typography variant="subtitle1">{user?.data?.phone}</Typography>
									<Typography variant="subtitle1">{user?.data?.email}</Typography>
								</div>
							</div>
							<div style={{ marginBottom: '20px' }}>
								<span>
									<strong>Date: </strong>
								</span>
								<span>{moment(new Date()).format('dddd, DD MMMM YYYY')}</span>
							</div>
							<div className="pdf-heading">
								<h1 className="font-extrabold"> Schools Enrollment</h1>
							</div>

							<Table stickyHeader className="student-table" style={{ width: '100%' }}>
								<TableHead>
									<TableRow>
										<TableCell style={{ width: '30%' }} className="bg-white studentTableHeader">
											School Name
										</TableCell>
										{/* <TableCell style={{ width: '17%' }} className="bg-white studentTableHeader">
											Total Subscribers
										</TableCell> */}

										<TableCell style={{ width: '14%' }} className="bg-white studentTableHeader">
											State
										</TableCell>
										<TableCell style={{ width: '14%' }} className="bg-white studentTableHeader">
											City
										</TableCell>
										<TableCell style={{ width: '17%' }} className="bg-white studentTableHeader">
											Email
										</TableCell>
										<TableCell style={{ width: '15%' }} className="bg-white studentTableHeader">
											Contact Info
										</TableCell>
										<TableCell style={{ width: '10%' }} className="bg-white studentTableHeader">
											Date
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody className="">
									{firstLoad ? (
										<TableRow>
											<TableCell align="center" colSpan={8}>
												Generate your report
											</TableCell>
										</TableRow>
									) : isLoading ? (
										<TableRow>
											<TableCell align="center" colSpan={8}>
												<CircularProgress size={35} />
											</TableCell>
										</TableRow>
									) : !rows?.length && !isLoading ? (
										<TableRow>
											<TableCell align="center" colSpan={8}>
												No record found
											</TableCell>
										</TableRow>
									) : (
										rows?.map((row) => (
											<TableRow key={row.id}>
												<TableCell className="bg-white ">
													<div className="flex flex-col">
														<div className="flex">
															<Avatar className="mr-6" alt="staff-face" src={row?.logo} />
															<div className="flex  items-center justify-content: center ">
																<div className="report-staff">{row?.name}</div>
															</div>
														</div>
													</div>
												</TableCell>
												{/* <TableCell className="bg-white ">
													<div className="flex flex-col">
														<div className="flex">
															<div className="flex  items-center justify-content: center">
																<div className="report-staff ">0</div>
															</div>
														</div>
													</div>
												</TableCell> */}

												<TableCell className="bg-white ">
													<div className="flex flex-col">
														<div className="flex">
															<div className="flex  items-center justify-content: center">
																<div className="report-staff ">
																	{
																		stateList.filter(
																			(i) => i.id === row.state_id,
																		)[0]?.name
																	}
																</div>
															</div>
														</div>
													</div>
												</TableCell>
												<TableCell className="bg-white ">
													<div className="flex flex-col">
														<div className="flex">
															<div className="flex  items-center justify-content: center">
																<div className="report-staff ">{row?.city}</div>
															</div>
														</div>
													</div>
												</TableCell>
												<TableCell className="bg-white ">
													<div className="flex flex-col">
														<div className="flex">
															<div className="flex  items-center justify-content: center">
																<div
																	className="report-staff truncate"
																	style={{ textTransform: 'lowercase' }}
																>
																	{row?.admins[0].email}
																</div>
															</div>
														</div>
													</div>
												</TableCell>
												<TableCell className="bg-white ">
													<div className="flex flex-col">
														<div className="flex">
															<div className="flex  items-center justify-content: center">
																<div className="report-staff ">{row?.phone}</div>
															</div>
														</div>
													</div>
												</TableCell>
												<TableCell component="th" scope="row">
													<div className="flex flex-col">
														<div className="report-staff" style={{ fontSize: 12 }}>
															{moment(row?.created_at).format('MM/DD/YY')}
														</div>
														<div
															className="report-staff"
															style={{ color: 'gray', fontSize: 10 }}
														>
															{moment(row?.created_at).format('hh:mm A')}
														</div>
													</div>
												</TableCell>
											</TableRow>
										))
									)}
									{fetchingMore ? (
										<TableRow>
											<TableCell align="center" colSpan={8}>
												<CircularProgress size={35} />
											</TableCell>
										</TableRow>
									) : (
										<></>
									)}
								</TableBody>
							</Table>
						</div>
					</div>
					<Table stickyHeader className="student-table" style={{ width: '100%' }}>
						<TableHead>
							<TableRow>
								<TableCell style={{ width: '30%' }} className="bg-white studentTableHeader">
									School Name
								</TableCell>
								{/* <TableCell style={{ width: '17%' }} className="bg-white studentTableHeader">
									Total Subscribers
								</TableCell> */}

								<TableCell style={{ width: '14%' }} className="bg-white studentTableHeader">
									State
								</TableCell>
								<TableCell style={{ width: '14%' }} className="bg-white studentTableHeader">
									City
								</TableCell>
								<TableCell style={{ width: '17%' }} className="bg-white studentTableHeader">
									Email
								</TableCell>
								<TableCell style={{ width: '15%' }} className="bg-white studentTableHeader">
									Contact Info
								</TableCell>
								<TableCell style={{ width: '10%' }} className="bg-white studentTableHeader">
									Date
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody className="">
							{firstLoad ? (
								<TableRow>
									<TableCell align="center" colSpan={8}>
										Generate your report
									</TableCell>
								</TableRow>
							) : isLoading ? (
								<TableRow>
									<TableCell align="center" colSpan={8}>
										<CircularProgress size={35} />
									</TableCell>
								</TableRow>
							) : !rows?.length && !isLoading ? (
								<TableRow>
									<TableCell align="center" colSpan={8}>
										No record found
									</TableCell>
								</TableRow>
							) : (
								rows?.map((row) => (
									<TableRow key={row.id}>
										<TableCell className="bg-white ">
											<div className="flex flex-col">
												<div className="flex">
													<Avatar className="mr-6" alt="staff-face" src={row?.logo} />
													<div className="flex  items-center justify-content: center ">
														<div className="report-staff">{row?.name}</div>
													</div>
												</div>
											</div>
										</TableCell>

										{/* <TableCell className="bg-white ">
											<div className="flex flex-col">
												<div className="flex">
													<div className="flex  items-center justify-content: center">
														<div className="report-staff ">0</div>
													</div>
												</div>
											</div>
										</TableCell> */}

										<TableCell className="bg-white ">
											<div className="flex flex-col">
												<div className="flex">
													<div className="flex  items-center justify-content: center">
														<div className="report-staff ">
															{stateList.filter((i) => i.id === row.state_id)[0].name}
														</div>
													</div>
												</div>
											</div>
										</TableCell>
										<TableCell className="bg-white ">
											<div className="flex flex-col">
												<div className="flex">
													<div className="flex  items-center justify-content: center">
														<div className="report-staff ">{row?.city}</div>
													</div>
												</div>
											</div>
										</TableCell>
										<TableCell className="bg-white ">
											<div className="flex flex-col">
												<div className="flex">
													<div className="flex  items-center justify-content: center">
														<div
															className="report-staff truncate"
															style={{ textTransform: 'lowercase' }}
														>
															{row?.admins[0].email}
														</div>
													</div>
												</div>
											</div>
										</TableCell>
										<TableCell className="bg-white ">
											<div className="flex flex-col">
												<div className="flex">
													<div className="flex  items-center justify-content: center">
														<div className="report-staff ">{row?.phone}</div>
													</div>
												</div>
											</div>
										</TableCell>
										<TableCell component="th" scope="row">
											<div className="flex flex-col">
												<div className="report-staff" style={{ fontSize: 12 }}>
													{moment(row?.created_at).format('MM/DD/YY')}
												</div>
												<div className="report-staff" style={{ color: 'gray', fontSize: 10 }}>
													{moment(row?.created_at).format('hh:mm A')}
												</div>
											</div>
										</TableCell>
									</TableRow>
								))
							)}
							{fetchingMore ? (
								<TableRow>
									<TableCell align="center" colSpan={8}>
										<CircularProgress />
									</TableCell>
								</TableRow>
							) : (
								<></>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<InfiniteScroll
					dataLength={rows?.length}
					next={handleLoadMore}
					hasMore={hasMore}
					scrollableTarget="Scrollable-table"
				/>
			</div>
		</FuseAnimate>
	);
}
